import { action, thunk, actionOn } from 'easy-peasy';
import axios from "../services/axios";

const paramsInitialState = {
    apiRoutes: [], // urls shared with backend without any login
    sharedSlugs: [], // slugs shared with backend
    constants: [], // constants needed at frontend initialization
    isFetchNecessary: true
}

const params = {
    ...paramsInitialState,

    onGlobalResetCalled: actionOn(
        (actions, storeActions) => [
            storeActions.resetStore.performReset,
        ],
        (state, target) => {
            const [performReset] = target.resolvedTargets;

            if (target.type === performReset) {
                ({
                    apiRoutes: state.apiRoutes,
                    sharedSlugs: state.sharedSlugs,
                    isFetchNecessary: state.isFetchNecessary
                } = paramsInitialState);
            }
        }
    ),

    setApiRoutes: action((state, payload) => {
        state.apiRoutes = payload;
    }),

    setSharedSlugs: action((state, payload) => {
        state.sharedSlugs = payload;
    }),

    setConstants: action((state, payload) => {
        state.constants = payload;
    }),

    setIsFetchNessary: action((state, payload) => {
        state.isFetchNecessary = payload;
    }),

    fetchParams: thunk((actions, payload, helpers) => {
        const API_PARAMS = process.env.REACT_APP_API_V2_PARAMS;
        return axios.get(API_PARAMS)
            .then((response) => {
                actions.setApiRoutes(response.urls);
                actions.setSharedSlugs(response.slugs);
                actions.setConstants(response.constants);
                actions.setIsFetchNessary(false);
            })
    })
};

export default params;