import { action, actionOn, thunk } from 'easy-peasy';
import axios from '../services/axios'

const logsInitialState = {
    logsByEntity: [],
    logsByUser: [],
    isFetchingLogsByEntity: false,
    isFetchingLogsByUser: false,
}

const logs = {
    ...logsInitialState,

    onGlobalResetCalled: actionOn(
        (actions, storeActions) => [
            storeActions.resetStore.performReset,
        ],
        (state, target) => {
            const [performReset] = target.resolvedTargets;

            if (target.type === performReset) {
                ({
                    logsByEntity: state.logsByEntity,
                    isFetchingLogsByEntity: state.isFetchingLogsByEntity,
                } = logsInitialState);
            }
        }
    ),

    setLogsByEntity: action((state, payload) => {
        state.logsByEntity = payload;
    }),

    setIsFetchingLogsByEntity: action((state, payload) => {
        state.isFetchingLogsByEntity = payload;
    }),

    setLogsByUser: action((state, payload) => {
        state.logsByUser = payload;
    }),

    setIsFetchingLogsByUser: action((state, payload) => {
        state.isFetchingLogsByUser = payload;
    }),

    fetchLogsByEntity: thunk((actions, payload, helpers) => {
        const { isFetchingLogsByEntity } = helpers.getState();
        const apiData = helpers.getStoreState().actionSlugs.apiData;

        actions.setIsFetchingLogsByEntity(logsInitialState.isFetchingLogsByEntity);

        if (!payload?.entityName || !payload?.uniqueId) {
            actions.setLogsByEntity([]);
            return Promise.resolve(null);
        }
        const url = apiData?.ACTIONS?.[payload.entityName]?.logs?.url ?? null;
        if (!url) {
            actions.setLogsByEntity([]);
            return Promise.resolve(null);
        }

        const getUrl = url?.replace('uniqueId', payload.uniqueId)?.replace('page', payload.page ?? 1);

        if (!isFetchingLogsByEntity && getUrl) {
            actions.setIsFetchingLogsByEntity(true);
            return axios.get(getUrl)
                .then(data => {
                    actions.setLogsByEntity(data);
                    actions.setIsFetchingLogsByEntity(logsInitialState.isFetchingLogsByEntity);
                    return data;
                })
                .catch(error => typeof error === "string" ? Promise.reject(new Error(error)) : console.log(error));
        } else {
            return null;
        }
    }),

    fetchLogsByUser: thunk((actions, payload, helpers) => {
        const { isFetchingLogsByUser } = helpers.getState();
        const apiData = helpers.getStoreState().actionSlugs.apiData;

        actions.setIsFetchingLogsByUser(logsInitialState.isFetchingLogsByUser);

        if (!payload?.uniqueId) {
            actions.setLogsByUser([]);
            return Promise.resolve(null);
        }
        const url = apiData?.ACTIONS?.logs?.getUserLogs.url ?? null;
        if (!url) {
            actions.setLogsByUser([]);
            return Promise.resolve(null);
        }

        const getUrl = url?.replace('uniqueId', payload.uniqueId)?.replace('page', payload.page ?? 1);

        if (!isFetchingLogsByUser && getUrl) {
            actions.setIsFetchingLogsByUser(true);
            return axios.get(getUrl)
                .then(data => {
                    actions.setLogsByUser(data);
                    actions.setIsFetchingLogsByUser(logsInitialState.isFetchingLogsByUser);
                    return data;
                })
                .catch(error => typeof error === "string" ? Promise.reject(new Error(error)) : console.log(error));
        } else {
            return null;
        }
    })
};

export default logs;
