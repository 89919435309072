import React from 'react';
import {Row, Col, Button, Alert, Badge} from 'react-bootstrap';
import { useStoreState, useStoreActions} from 'easy-peasy'

import WhiteListFormComponent, { submitWhiteListForm } from '../whitelistForms/WhitelistFormComponent.jsx';
import WhiteListStoryFormComponent, { resetStoryform, submitStoryForm } from '../whitelistForms/whitelistStoryForm/WhiteListStoryFormComponent.jsx';
import useCustomGetStoreState from '../../../../hooks/useCustomGetStoreState.jsx';
import { useToast } from '../../../../hooks/useToast.jsx';


const ClientWhitelistTabComponent = ({setModalData, modalData, setModalShow, setIsSending}) => {
    const { clientById } = useStoreState(state => state.clients);
    const {allGroups} = useCustomGetStoreState("groups");
    const {allVideos} = useCustomGetStoreState("videos");
    const {allQuizzes} = useCustomGetStoreState("quizzes");
    const { apiData } = useStoreState(state => state.actionSlugs)
    const toast = useToast();

    const { updateWhitelistGroup, updateWhitelistVideo, updateWhitelistStory, updateWhitelistQuiz } = useStoreActions(actions => actions.clients);


    const createGroupWhitelistModal = () => {
        
        const validate = () => {
            setIsSending(true);
            const formId = `${apiData.ACTIONS['clients']['editProps']['editWhitelistGroup'].formName}-form`;
            submitWhiteListForm(formId, updateWhitelistGroup, clientById.uniqueId)
                .then(() => {
                    toast.success();
                    setModalShow(false);
                })
                .finally(() => setIsSending(false));
        };

        setModalData({
            ...modalData,
            header: 'Gestion de la whitelist de groupes',
            content: <WhiteListFormComponent client={clientById} list={allGroups?.filter(g => !g.isSpecial)} mediaType="group" actionName="editWhitelistGroup" />,
            cancelButton: 'Annuler',
            onValidate: validate,
            size: 'xl',
        });
        setModalShow(true);
    }

    const createVideoWhitelistModal = () => {
        
        const validate = () => {
            setIsSending(true);
            const formId = `${apiData.ACTIONS['clients']['editProps']['editWhitelistVideo'].formName}-form`;
            submitWhiteListForm(formId, updateWhitelistVideo, clientById.uniqueId)
                .then(() => {
                    toast.success();
                    setModalShow(false);
                })
                .finally(() => setIsSending(false));
        };

        setModalData({
            ...modalData,
            header: 'Gestion la whitelist d\'expériences immersives',
            content: <WhiteListFormComponent client={clientById} list={allVideos} mediaType="video" actionName="editWhitelistVideo"/>,
            cancelButton: 'Annuler',
            onValidate: validate,
            size: 'xl',
        });
        setModalShow(true);
    }

    const createQuizWhitelistModal = () => {
        const validate = () => {
            setIsSending(true);
            const formId = `${apiData.ACTIONS['clients']['editProps']['editWhitelistQuiz'].formName}-form`;
            submitWhiteListForm(formId, updateWhitelistQuiz, clientById.uniqueId)
                .then(() => {
                    toast.success();
                    setModalShow(false);
                })
                .finally(() => setIsSending(false));
        };

        setModalData({
            ...modalData,
            header: 'Gestion la whitelist de quiz',
            content: <WhiteListFormComponent client={clientById} list={allQuizzes} mediaType="quiz" actionName="editWhitelistQuiz"/>,
            cancelButton: 'Annuler',
            onValidate: validate,
            size: 'xl',
        });
        setModalShow(true);
    }

    const createStoryWhitelistModal = () => {
        const formName = apiData.ACTIONS['clients']['editProps']['editWhitelistStory'].formName
        const formId = `${formName}-form`;
        
        const validate = () => {
            setIsSending(true);
            submitStoryForm(formId, updateWhitelistStory, clientById.uniqueId, formName)
                .then(() => {
                    toast.success();
                    setModalShow(false);
                })
                .finally(() => setIsSending(false));
        }

        const reset = () => {
            setIsSending(true)
            resetStoryform(formId, updateWhitelistStory, clientById.uniqueId, formName)
                .then(() => {
                    toast.success();
                    setModalShow(false);
                })
                .finally(() => setIsSending(false));
        }

        setModalData({
            ...modalData,
            header: 'Gestion les whitelist des stories',
            content: <WhiteListStoryFormComponent client={clientById} actionName="editWhitelistStory"/>,
            cancelButton: 'Annuler',
            onValidate: validate,
            resetButton: "Supprimer la whitelist",
            onReset: reset,
            size: 'xl',
            id: 'story-whiteList-modal',
        });
        setModalShow(true);
    }

    const mediaWhiteListInfo = (media) => {
        if (clientById[`${media}List`].length) {
            return (
                <>
                    <Alert className="text-center my-3" variant="danger" disabled>Les {media}s ont une <strong>whitelist active</strong> 
                    <br />
                        <h4><Badge> {clientById[`${media}List`].length} </Badge></h4>
                        <span>{media}(s) dans la whitelist</span>
                    </Alert>
                </>
            )
        } else {
            return <><Alert className="text-center my-3" variant="success">Pas de whitelist active</Alert></>;
        }
    }

    const mediaBlackListInfo = () => {
        if (!clientById[`videoListIsRestrictive`]) {
            if(clientById[`videoBlackList`].length > 0) {
            return (
                <>
                    <Alert className="text-center my-3" size='sm' variant="danger" disabled>Les videos ont une <strong>blacklist active</strong>
                    <br />
                        <h4><Badge> {clientById[`videoBlackList`].length} </Badge></h4>
                        <span>expérience(s) immersive(s) dans la blacklist</span>
                    </Alert>
                </>
            )
            } else {
                return <><Alert className="text-center my-3" size='sm' variant="success">Pas de blacklist active</Alert></>;
            }

        }
    }

    return (
        <Row className="tab-info-container">
            <Col className="tab-info-card">
                <Button variant="secondary"
                    className="tab-info-card-btn"
                    onClick={createGroupWhitelistModal} 
                    disabled={allGroups.length === 0}>
                    <i className="fa fa-cog" /> Gérer les whiteList de groupes</Button>
                { mediaWhiteListInfo('group')}
                
            </Col>
            <Col className="tab-info-card">
                <Button 
                    variant="secondary" 
                    className="tab-info-card-btn" 
                    onClick={createVideoWhitelistModal}
                    disabled={allVideos.length === 0}>
                    <i className="fa fa-cog" /> Gérer les whiteList d'expériences immersives</Button>
                { mediaWhiteListInfo('video')}
                { mediaBlackListInfo()}
                
            </Col>
            <Col className="tab-info-card">
                <Button variant="secondary"
                    className="tab-info-card-btn" 
                    onClick={createQuizWhitelistModal}
                    disabled={allQuizzes.length === 0}>
                    <i className="fa fa-cog" /> Gérer les whiteList de quiz</Button>
                { mediaWhiteListInfo('quiz')}

            </Col>
            <Col className="tab-info-card">
                <Button variant="secondary" className="tab-info-card-btn" onClick={createStoryWhitelistModal}>
                    <i className="fa fa-cog" /> Gérer les whiteList de stories</Button>
                { mediaWhiteListInfo('story')}

            </Col>
        </Row>
    );
};

export default ClientWhitelistTabComponent;