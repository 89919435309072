import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Form, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { useStoreActions, useStoreState} from 'easy-peasy';

import useSearchWithParams from "../../../hooks/useSearchWithParams.jsx";
import useCustomGetStoreState from '../../../hooks/useCustomGetStoreState.jsx';
import PropTypes from 'prop-types';
import SelectItemsComponent from '../../widgets/selectItemsComponent/SelectItemsComponent.jsx';
import './OrdersFilters.scss';
import Loader from '../../widgets/Loader.jsx'
import HelperMetiers360 from "../../../services/HelpersMetiers360.js";

const OrdersFilters = ({ allOrders, setFilteredOrders, excludedStatus, allStatus }) => {
    const { searchInput, search } = useSearchWithParams({ placeholder: 'Rechercher par mots-clés (établissement, opé M360, contact M360...)', delay: 400 });

    const [searchParam, setSearchParam] = useSearchParams();

    const { fetchContactsM360 } = useStoreActions(actions => actions.clients);
    const { contactsM360 } = useStoreState(state => state.clients);
    const { allTagOperations } = useCustomGetStoreState("tagOperations");

    const [areFiltersApplied, setAreFiltersApplied] = useState(false);
    const [isFirstLoadFilters, setIsFirstLoadFilters] = useState(true);

    const [isFiltersOpen, setIsFiltersOpen] = useState(false);
    const [isApplyFilterNecessary, setIsApplyFilterNecessary] = useState(false);

    const minDateRef = useRef("");
    const maxDateRef = useRef("");
    const includeOrdersDoneRef = useRef(false);
    const idRef = useRef(null);
    const contactsM360SelectorRef = useRef(null);
    const tagOperationsSelectorRef = useRef(null);
    const statusSelectorRef = useRef(null);

    useEffect(() => {
        if (contactsM360?.length == 0) {
            fetchContactsM360()
                .then(() => {
                    setFiltersFromUrl();
                    setIsApplyFilterNecessary(true);
                    setIsFirstLoadFilters(false);
                })
        } else {
            setFiltersFromUrl();
            setIsFirstLoadFilters(false);
        } 
    }, []);

    const setFiltersFromUrl = () => {
        const newSearchParamMinDate = searchParam.get('minDate');
        const newSearchParamMaxDate = searchParam.get('maxDate');
        const newSearchParamIncludeOrdersDone = searchParam.get('includeOrdersDone');
        const newSearchParamContacts = searchParam.get('contactsM360');
        const newSearchParamTagOpe = searchParam.get('tagOpeSelected');
        const newSearchParamId = searchParam.get('id');
        const newSearchParamStatus = searchParam.get('statusSelected');

        if (newSearchParamMinDate && minDateRef?.current) {
            minDateRef.current.value = newSearchParamMinDate;
        }
        if (newSearchParamMaxDate && maxDateRef?.current) {
            maxDateRef.current.value = newSearchParamMaxDate;
        }
        if (newSearchParamIncludeOrdersDone && includeOrdersDoneRef?.current) {
            includeOrdersDoneRef.current.checked = JSON.parse(newSearchParamIncludeOrdersDone);
        }
        if (newSearchParamContacts && contactsM360SelectorRef?.current) {
            contactsM360SelectorRef.current.value = newSearchParamContacts;
        }
        if (newSearchParamTagOpe && tagOperationsSelectorRef?.current) {
            tagOperationsSelectorRef.current.value = newSearchParamTagOpe;
        }
        if (newSearchParamId && idRef?.current) {
            idRef.current.value = newSearchParamId;
        }
        if (newSearchParamStatus && statusSelectorRef?.current) {
            statusSelectorRef.current.value = newSearchParamStatus;
        }

        setIsFiltersOpen(( newSearchParamMinDate && newSearchParamMinDate !== "") 
            || (newSearchParamMaxDate && newSearchParamMaxDate !== "")
            || (newSearchParamIncludeOrdersDone && newSearchParamIncludeOrdersDone !== "false") 
            || (newSearchParamContacts && newSearchParamContacts !== "") 
            || (newSearchParamTagOpe && newSearchParamTagOpe !== "") 
            || (newSearchParamId && newSearchParamId !== "" )
            || (newSearchParamStatus && newSearchParamStatus !== ""));
    }

    const setUrlFromFilters = () => {
        const minDate = minDateRef.current
            ? minDateRef.current.value ?? null
            : searchParam.get('minDate');
        const maxDate = maxDateRef.current
            ? maxDateRef.current.value ?? null
            : searchParam.get('maxDate');
        const includeOrdersDone = includeOrdersDoneRef?.current.checked;
        const contactsRef = contactsM360SelectorRef.current
            ? contactsM360SelectorRef.current.value ?? null
            : searchParam.get('contactsM360');
        const tagRef = tagOperationsSelectorRef.current
            ? tagOperationsSelectorRef.current.value ?? null
            : searchParam.get('tagOpeSelected');
        const id = idRef.current
            ? idRef.current.value ?? null
            : searchParam.get('id');
        const statusRef = statusSelectorRef.current
            ? statusSelectorRef.current.value ?? null
            : searchParam.get('statusSelected');

        setParamsToUrl([
            ['search', search ?? ""],
            ['minDate', minDate ?? ""],
            ['maxDate', maxDate ?? ""],
            ['contactsM360', contactsRef ?? ""],
            ['tagOpeSelected', tagRef ?? ""],
            ['statusSelected', statusRef ?? ""],
            ['includeOrdersDone', includeOrdersDone],
            ['id', id ?? ""],
        ])
    }

    const applyFiltersOnTable = () => {
        const minDate = minDateRef.current
            ? minDateRef.current.value ?? null
            : searchParam.get('minDate');
        const maxDate = maxDateRef.current
            ? maxDateRef.current.value ?? null
            : searchParam.get('maxDate');
        let includeOrdersDone = includeOrdersDoneRef.current
            ? includeOrdersDoneRef.current.checked ?? false
            : searchParam.get('includeOrdersDone');
        const contactM360Id = contactsM360SelectorRef.current
            ? contactsM360SelectorRef.current.value ?? null
            : searchParam.get('contactsM360');
        const tagOperationUniqueId = tagOperationsSelectorRef.current
            ? tagOperationsSelectorRef.current.value ?? null
            : searchParam.get('tagOpeSelected');
        const idParam = idRef.current
            ? idRef.current.value ?? null
            : searchParam.get('id');
        const statusName = statusSelectorRef.current
            ? statusSelectorRef.current.value ?? null
            : searchParam.get('statusSelected');

        if (!includeOrdersDone && statusName === "Sent" || statusName === "Closed" || statusName === "closed") {
            includeOrdersDoneRef.current.checked = true;
            searchParam.set('includeOrdersDone', true);
            includeOrdersDone = true;
        }

        const ordersToDisplay = allOrders
            ?.filter(order => {
                const minDateCondition = !minDate || order?.deliveryDate >= minDate || order?.returnDueDate >= minDate;
                const maxDateCondition = !maxDate || order?.deliveryDate <= maxDate || order?.returnDueDate <= maxDate;
                const includeOrdersDoneCondition = includeOrdersDone 
                    || (order.statusValue && order.statusValue !== "Sent" && order.statusValue !== "Closed") 
                    || (order.returnOrderStatus && order.returnOrderStatus?.value !== "closed");
                const contactsRefCondition = !contactM360Id
                    || (order.contactM360Name?.split(', ')
                        ?.includes(contactsM360?.find(contact => contact.id == contactM360Id)?.name))
                    || (contactM360Id === "aucun" && order.contactM360Name === "");
                const tagCondition = !tagOperationUniqueId
                    || order.tagOperations?.some(tag => tag.uniqueId == tagOperationUniqueId)
                    || (tagOperationUniqueId === "aucun" && !order.tagOperations?.length);
                const idCondition = !idParam || order.id == idParam;
                const statusRefCondition = !statusName 
                    || order?.statusValue == statusName
                    || order?.returnOrderStatus?.value == statusName;

                return minDateCondition && maxDateCondition && includeOrdersDoneCondition && contactsRefCondition 
                    && tagCondition && idCondition && statusRefCondition;
            })
            ?.filter(order => 
                HelperMetiers360.isSearchInText(search, order.recipientSociety)
                || HelperMetiers360.isSearchInText(search, order.clientName)
                || HelperMetiers360.isSearchInText(search, order.recipientName)
                || HelperMetiers360.isSearchInText(search, order.contactFirstLastName)
                || HelperMetiers360.isSearchInText(search, order.postalCode)
                || HelperMetiers360.isSearchInText(search, order.city)
                || HelperMetiers360.isSearchInText(search, order.status)
                || HelperMetiers360.isSearchInText(search, order.provider?.name)
                || HelperMetiers360.isSearchInText(search, order.tagOperations?.join())
                || HelperMetiers360.isSearchInText(search, order.contactM360Name)
            );

        setFilteredOrders(ordersToDisplay);
        setAreFiltersApplied(search || minDate || maxDate || includeOrdersDone || contactM360Id || tagOperationUniqueId 
            || idParam || statusName);

        setIsApplyFilterNecessary(false);
    }

    const setParamsToUrl = (newParams) => {
        const paramsToSet = {}
        for (const [key, value] of searchParam.entries()) {
            paramsToSet[key] = value;
        }
        for (const [key, value] of newParams) {
            paramsToSet[key] = value;
        }
        setSearchParam(paramsToSet);
    }

    const resetFilters = () => {
        setParamsToUrl([
            ['search', ""],
            ['minDate', ""],
            ['maxDate', ""],
            ['includeOrdersDone', false],
            ['contactsM360', ""],
            ['tagOpeSelected', ""],
            ['id', ""],
            ['statusSelected', ""]
        ]);
        if(minDateRef.current?.value) minDateRef.current.value = "";
        if(maxDateRef.current?.value) maxDateRef.current.value = "";
        if(includeOrdersDoneRef.current) includeOrdersDoneRef.current.checked = false;
        if(contactsM360SelectorRef.current?.value) contactsM360SelectorRef.current.value = "";
        if(tagOperationsSelectorRef.current?.value) tagOperationsSelectorRef.current.value = "";
        if(idRef.current?.value) idRef.current.value = "";
        if(statusSelectorRef.current?.value) statusSelectorRef.current.value = "";

        setIsApplyFilterNecessary(true);
    }

    useEffect(() => {
        isApplyFilterNecessary && applyFiltersOnTable();
    }, [isApplyFilterNecessary]);

    useEffect(() => {
        !isApplyFilterNecessary && setIsApplyFilterNecessary(true);
    }, [search, allOrders]);

    const onFilterChange = () => {
        setUrlFromFilters();
        setIsApplyFilterNecessary(true);
    }

    return (<>
        <Row className={"d-flex align-items-center pe-0"}>
            <Col className="d-flex align-items-center">
                <Button id="btnFilters" className="cursor-pointer shadow"
                    onClick={() => setIsFiltersOpen(!isFiltersOpen)}
                    onKeyDown={() => setIsFiltersOpen(!isFiltersOpen)}>
                    <p className="me-3">Filtres</p>
                    { isFiltersOpen 
                        ? <i className="fa fa-caret-up"></i>
                        : <i className="fa fa-caret-down"></i>
                    }
                </Button>
                { areFiltersApplied && 
                    <OverlayTrigger placement="top"
                        overlay={ <Tooltip>Effacer les filtres</Tooltip> }>
                        <Button id="btnInitFilters" className="shadow" variant="danger" size="sm" onClick={() => { resetFilters()} }>
                            <i className="fas fa-redo"></i>
                        </Button>
                    </OverlayTrigger>
                }
                { isFirstLoadFilters && 
                    <div id="loader-first-loading-filter"><Loader/></div>
                }
            </Col>
            <Col className="pe-0">
                {searchInput}
            </Col>
        </Row>
        <div id="filtersZone" className={!isFiltersOpen ? "hide-filters" : undefined}> 
            <Row className="d-flex align-items-center justify-content-between">
                <Col className="m360-col3-start" md={6} lg={6} xxl={4}>
                    <Form.Check id={"includeOrdersDone" + excludedStatus} className="clickable"
                        label={"Inclure les bons " + excludedStatus} onChange={onFilterChange} ref={includeOrdersDoneRef}/>
                </Col>
                <Col className="m360-col3-center" md={6} lg={6} xxl={4}>
                    <div className="d-flex align-items-center">
                        <label className="label-select" htmlFor="tag-select">Opé M360&nbsp;: </label>
                        <SelectItemsComponent selectRef={tagOperationsSelectorRef} onChange={onFilterChange} id="tag-select"
                            items={allTagOperations} itemKey="uniqueId" itemValue="name" maxWidth={"28em"} labelNone={"opération"}/>
                    </div>
                </Col>
                <Col className="m360-col3-end" md={6} lg={6} xxl={4}>
                    <div className="d-flex align-items-center">
                        <label className="label-select" htmlFor="contact-select">Contact M360&nbsp;: </label>
                        <SelectItemsComponent selectRef={contactsM360SelectorRef} onChange={onFilterChange} id="contact-select"
                            items={contactsM360} itemKey="id" itemValue="name" maxWidth={"28em"} labelNone={"contact M360"} />
                    </div>
                </Col>
            </Row>
            <Row className="d-flex align-items-center justify-content-between mt-2">
                <Col className="m360-col3-start" md={6} lg={6} xxl={4}> 
                    <div className="d-flex align-items-center">
                        <label className="label-select" htmlFor="id-filter">Id&nbsp;: </label>
                        <Form.Control type="number" name="id" min="1" id="id-filter" ref={idRef} onChange={onFilterChange} >
                        </Form.Control>
                    </div>
                </Col>   
                <Col className="m360-col3-center" md={12} lg={6} xl={6} xxl={4}>    
                    <div className="d-flex align-items-center">
                        <label className="label-select" htmlFor="start">Date&nbsp;:</label>
                        <input className="m-1" type="date" id="start" name="date-start" max={maxDateRef.current?.value} 
                            ref={minDateRef} onChange={onFilterChange} />
                        <label htmlFor="end" className="m-0">au</label>
                        <input className="m-1" type="date" id="end" name="date-end" min={minDateRef.current?.value}
                            ref={maxDateRef} onChange={onFilterChange} />
                    </div>
                </Col>
                <Col className="m360-col3-end" md={6} lg={6} xxl={4}>
                    <div className="d-flex align-items-center">
                        <label className="label-select" htmlFor="status-select">Statut&nbsp;: </label>
                        <SelectItemsComponent selectRef={statusSelectorRef} onChange={onFilterChange} id="status-select"
                            items={allStatus} itemKey="value" itemValue="label" maxWidth={"28em"} />
                    </div>
                </Col>
            </Row>
        </div>
    </>)
}

OrdersFilters.propTypes = {
    allOrders: PropTypes.array.isRequired,
    setFilteredOrders: PropTypes.any.isRequired,
    excludedStatus: PropTypes.string.isRequired,
    allStatus: PropTypes.array.isRequired,
};

export default OrdersFilters