import React, { useState } from "react";
import { Button, Col, Modal } from "react-bootstrap";
import PropTypes from 'prop-types';

const FileModalComponent = ({ files, editFilesModal }) => {

    const [modalShow, setModalShow] = useState(false);
    const [modalData, setModalData] = useState({ header: "", content: "" });

    const openFileModal = () => {
        setModalData({
            header: <>{files?.length > 1 ? "Fichiers liés" : "Fichier lié"}</>,
            content: <>{filesModalContent}</>,
        });
        setModalShow(true);
    };

    const onDownloadFile = (url) => {
        window.open(url);
    };

    const filesModalContent = files?.length > 0 && (
        <div className="tableFiles">
            <table>
                <thead>
                    <tr>
                        <th className="name"></th>
                        <th className="downl"></th>
                    </tr>
                </thead>
                <tbody>
                    {files
                        .sort((a, b) => a.localName?.localeCompare(b.localName))
                        .map((file, index) => (
                            <tr key={`${file.localName}${index}`}>
                                <td>{file.localName}</td>
                                <td>
                                    <Button
                                        variant="secondary"
                                        className="p-2 m-2"
                                        size="sm"
                                        onClick={() => onDownloadFile(file.url)}
                                    >
                                        <i className="fas fa-download" />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );

    return (
        files ? <>
            {files?.length > 0
                ? <div className="d-flex align-items-center mb-4">
                    <strong>Fichiers : </strong>
                    <Col xs="auto">
                        <Button className="px-4 py-1 ms-2" onClick={openFileModal}>
                            {files.length}
                        </Button>
                    </Col>
                    <Col xs="auto">
                        <Button variant="secondary" size="sm" className="ms-2" onClick={editFilesModal}>
                            <i className="fas fa-edit"></i>
                        </Button>
                    </Col>
                </div>
                : <Button variant="success" onClick={editFilesModal} className="mb-4">
                    <i className="fas fa-plus"></i> &nbsp;Ajouter des fichiers
                </Button>
            }
            <Modal show={modalShow} onHide={() => setModalShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalData.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalData.content}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalShow(false)}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
        </> : null
    );
};
FileModalComponent.propTypes = {
    files: PropTypes.array.isRequired,
    editFilesModal: PropTypes.func.isRequired
}
export default FileModalComponent;
