import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import image from '../../images/go_further.jpg';

const GoFurtherPage = () => {
    return <Container>
        <h2 className='d-flex'>Pour aller plus loin</h2>
        <Row>
            <Col>
                <h4>Parlons de votre projet</h4>
                <p>
                    Vous souhaitez aller plus loin avec Métiers 360, nous sommes à votre écoute pour vous
                    accompagner dans votre projet avec des services complémentaires.
                </p>
                <p className="fw-bold mb-1 mt-4">Une formation pour votre équipe</p>
                <p>
                    Notre formateur peut se déplacer dans vos locaux et mettre en place une formation
                    pour faciliter le déploiement de votre pédagogie.
                </p>
                <p className="fw-bold mb-1 mt-4">Un scénario pédagogique spécifique</p>
                <p>
                    Réfléchissons ensemble à un scénario pédagogique qui soit spécifique à votre
                    accompagnement. Nos ingénieurs pédagogiques vous proposeront un scénario
                    détaillé et adapté à votre pratique.
                </p>
                <p className="fw-bold mb-1 mt-4">Un projet de co-production</p>
                <p>
                    Enrichissons le catalogue de nouvelles expériences en filmant les mondes
                    professionnels qui sont pertinents autour de vous. Un projet partenarial pas si
                    compliqué à mettre en œuvre !
                </p>
                <Button
                    className='m-4'
                    variant="primary"
                    href="https://webforms.pipedrive.com/f/72vZZGeahXoRGtph1wXUcg8YQo4ckEuXdOhOnLUpvXo7aTOwyPa8VmPb7cgtsmckyD"
                    target="blank">
                    Je souhaite être recontacté⋅e
                </Button>
            </Col>
            <Col xs={12} lg={6}>
                <img src={image} style={{ maxWidth: 'inherit', height: 'auto' }} />
            </Col>
        </Row>
    </Container>
}
export default GoFurtherPage;