import React, { useEffect, useState } from "react";
import { Form, Alert } from 'react-bootstrap';
import useCustomGetStoreState from "../../../hooks/useCustomGetStoreState";
import SelectMultipleItemsWithSearchBar from "../../widgets/selectMultipleItemsWithSearchBar/SelectMultipleItemsWithSearchBar";
import { useStoreState } from "easy-peasy";
import axios from '../../../services/axios';

const FormAssignExistingProsComponent = () => {
    const { allVideos } = useCustomGetStoreState("videos");
    const { apiData: { ACTIONS } } = useStoreState(state => state.actionSlugs);
    const fetchVideoUrl = ACTIONS?.videos?.readOne?.url;
    const token = ACTIONS?.videos?.assignPro?.csrfToken;

    const [selectedVideoUniqueIds, setSelectedVideoUniqueIds] = useState([]);
    const [allProsFromSelectedVideos, setAllProsFromSelectedVideos] = useState([]);
    const [selectedVideos, setSelectedVideos] = useState([]);
    const [selectedProUniqueIds, setSelectedProUniqueIds] = useState([]);

    useEffect(() => {
        async function fetchVideos() {
            let selectedVideosTemp = [...selectedVideos];
            await Promise.all(
                selectedVideoUniqueIds.map(selectedVideoUniqueId =>
                    selectedVideosTemp.some(video => video.uniqueId === selectedVideoUniqueId)
                        ? Promise.resolve()
                        : axios.get(fetchVideoUrl?.replace('uniqueId', selectedVideoUniqueId))
                            .then(response => {
                                selectedVideosTemp.push(response);
                            })
                )
            );
            const selectedVideosTempClean = selectedVideosTemp.filter(v => selectedVideoUniqueIds?.includes(v.uniqueId));
            setSelectedVideos(selectedVideosTempClean);
            const allProsFromSelectedVideo = selectedVideosTempClean?.flatMap(vid => vid.pros);
            const uniquePros = allProsFromSelectedVideo?.reduce((acc, pro) => {
                if (!acc.some(item => item.uniqueId === pro.uniqueId)) {
                    acc.push({
                        ...pro,
                        descriptionSelector: pro.mainJob?.label + " - " + pro.videos?.map(videoPro => videoPro.videoName).join(', '),
                    });
                }
                return acc;
            }, []);
            setAllProsFromSelectedVideos(uniquePros);
            setSelectedProUniqueIds(prev => prev.filter(uuid => uniquePros?.some(pro => pro.uniqueId === uuid)));
        }
        fetchVideos();
    }, [selectedVideoUniqueIds]);

    const proFieldsHidden = selectedProUniqueIds?.map((selectedProUniqueId, index) =>
        <Form.Control
            key={`pro_uniqueId_${index}`}
            name={`pro_uniqueId`}
            value={selectedProUniqueId} type="hidden" />
    );

    return <Form id="video_assign_pros_form">
        <Form.Control name="csrf_token" defaultValue={token} type="hidden" />
        {proFieldsHidden}
        <Form.Group className="formSection d-flex flex-column">
            <Form.Label>Expériences immersives</Form.Label>
            <SelectMultipleItemsWithSearchBar
                idSelector='select_videos'
                allItems={allVideos?.filter(video => video.prosCount > 0)}
                selectedItemKeys={selectedVideoUniqueIds}
                setSelectedItemKeys={setSelectedVideoUniqueIds}
                itemKey="uniqueId" itemValue="videoName"
            />
        </Form.Group>
        {allProsFromSelectedVideos?.length > 0
            ? <Form.Group className="formSection d-flex flex-column">
                <Form.Label>Professionnel⋅les</Form.Label>
                <SelectMultipleItemsWithSearchBar
                    idSelector='select_pros'
                    allItems={allProsFromSelectedVideos}
                    selectedItemKeys={selectedProUniqueIds}
                    setSelectedItemKeys={setSelectedProUniqueIds}
                    itemKey="uniqueId" itemValue="name"
                    itemValueDescription="descriptionSelector"
                />
            </Form.Group>
            : <Alert variant="warning">Veuillez sélectionner au moins une expérience immersive</Alert>}
    </Form>;
}

export const submitAssignProsVideoForm = ({ videoUniqueId, action }) => {
    const formData = new FormData(document.getElementById('video_assign_pros_form'));
    const csrfToken = formData.get('csrf_token');
    const actions = [];
    formData.getAll('pro_uniqueId')?.forEach(proUniqueId => {
        const formDataOnePro = new FormData();
        formDataOnePro.append('pro_uniqueId', proUniqueId);
        formDataOnePro.append('csrf_token', csrfToken);
        const payload = { uniqueId: videoUniqueId, formData: formDataOnePro };
        actions.push(action(payload));
    })
    return actions;

}

export default FormAssignExistingProsComponent;