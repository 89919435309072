import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';

/**
 * Compoment that renders a pagination bar. handlePageClick : Object { selected: [x] } // 0 <= x < totalPages
 */
const Pagination = ({ handlePageClick, totalPages }) => <div className='w-100 d-flex justify-content-center mt-5'>
    <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageCount={totalPages}
        previousLabel="<"
        renderOnZeroPageCount={null}
        className="pagination"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        activeClassName="active"
        disabledClassName="disabled"
    />
</div>;

Pagination.propTypes = {
    handlePageClick: PropTypes.func,
    totalPages: PropTypes.number.isRequired
}
export default Pagination;