import React, { useState } from 'react';
import { Alert, Row, Tab, Tabs } from 'react-bootstrap';
import './LogsComponent.scss';
import HelperMetiers360 from '../../services/HelpersMetiers360';
import Loader from '../widgets/Loader';
import PropTypes from 'prop-types';
import Pagination from '../widgets/pagination/Pagination';
import { useStoreState } from 'easy-peasy';

const LogsContentWindow = ({ logs, setIsDisplayingLogs, isUserLogs, logsByUser, onPaginate, totalPages, totalByUserPages }) => {
    const { isFetchingLogsByEntity, isFetchingLogsByUser } = useStoreState(state => state.logs);
    const [activeKey, setActiveKey] = useState("");
    const [tabKey, setTabKey] = useState("logs");
    const [isLoading, setIsLoading] = useState(false);

    const tagAction = (type, comment) => type
        ? <span className={`tag me-2 tag-action-${type.value}`}>{type.label}</span>
        : <span className='tag me-2 '>{comment}</span>;
    const tagResponseCode = (code) => code && <span className={`tag tag-response-code-${code.toString()?.substring(0, 1)}`}>{code}</span>;

    const itemLog = (log, isByUser = false) => <div key={log.id} className='d-flex flex-column align-items-start my-4'>
        <div className='cursor-pointer d-flex align-items-center'
            onClick={() => setActiveKey(activeKey == log?.id ? "" : log?.id)}>
            <i className={log?.id === activeKey ? "fa fa-caret-down arrow-accordion" : "fa fa-caret-right arrow-accordion"} aria-hidden="true" />
            {tagAction(log.controllerMainAction?.type ?? null, log.controllerComment)}
            <p className='text-left mb-0'>
                {isByUser && log.controllerMainAction?.entityName}
                {log.controllerMainAction?.entityName && log.controllerComment && ` (${log.controllerComment}) `}
                {!isByUser && log.controllerMainAction?.entityName && log.controllerComment && "- "}
                {!isByUser && log.requestingUserEmail}
                <span className='ms-2 log-grey'>
                    {(!isByUser || isByUser && log.controllerMainAction?.entityName) && "- "}
                    {HelperMetiers360.getdisplayDateType(log.createdAt)}
                </span>
            </p>
        </div>
        <div className={`log-content ${log?.id === activeKey ? '' : 'visually-hidden'}`}>
            <span className='mt-2 mb-2 d-flex align-items-center text-start'>
                {log.route}
                <span className='ms-2'>{tagResponseCode(log.responseCode)}</span>
            </span>
            <pre>
                <code>
                    {Object.entries(log).map(([key, value]) =>
                        <div key={key}>
                            <strong>{key}:</strong> {typeof value === 'object' ? JSON.stringify(value, null, 2) : value}
                        </div>
                    )}
                </code>
            </pre>
        </div>
    </div>;

    const handlePageClick = (event) => {
        setIsLoading(true);
        const newCurrentIndexPage = event.selected ?? 0;
        onPaginate(newCurrentIndexPage + 1, isUserLogs).then(() => setIsLoading(false));
    }

    const listLogs = logs?.length > 0
        ? <div className='d-flex flex-column justify-content-between' style={{ height: '90vh' }}>
            {isLoading
                ? <Loader />
                : <div>
                    {logs.sort((a, b) => {
                        return new Date(b?.createdAt) - new Date(a?.createdAt);
                    })?.map(log => itemLog(log))}
                </div>}
            {totalPages > 1 && <Pagination handlePageClick={handlePageClick} totalPages={totalPages} />}
        </div>
        : isFetchingLogsByEntity
            ? <Loader />
            : <Alert variant='warning'>Aucun log d'action enregistré</Alert>;

    const listLogsByUser = isUserLogs && (logsByUser?.length > 0
        ? <>
            {(isLoading || isFetchingLogsByUser)
                ? <Loader />
                : logsByUser.sort((a, b) => {
                    return new Date(b?.createdAt) - new Date(a?.createdAt);
                })?.map(log => itemLog(log, true))
            }
            {totalByUserPages > 1 && <Pagination handlePageClick={handlePageClick} totalPages={totalByUserPages} />}
        </>
        : isFetchingLogsByUser
            ? <Loader />
            : <Alert variant='warning'>Aucun log d'action enregistré</Alert>
    );

    const tabsLogs = <Tabs defaultActiveKey="logs" activeKey={tabKey} onSelect={(k) => setTabKey(k)}>
        <Tab eventKey="logs" title="Actions sur l'utilisateur" unmountOnExit={false}>
            {listLogs}
        </Tab>
        <Tab eventKey="logsByUser" title="Actions par l'utilisateur" unmountOnExit={false}>
            {listLogsByUser}
        </Tab>
    </Tabs>;

    const logsDisplay = isUserLogs ? tabsLogs : listLogs;

    return <div className='logs-shadow' onClick={() => setIsDisplayingLogs(false)}>
        <div className="logs-window" onClick={(e) => e.stopPropagation()}>
            <Row className='d-flex justify-content-between'>
                <h4 className='w-auto'>Historique d'actions</h4>
                <button type="button" className="btn-close" aria-label="Fermer"
                    onClick={() => setIsDisplayingLogs(false)}>
                </button>
            </Row>
            {logsDisplay}
        </div>
    </div>;
}

LogsContentWindow.propTypes = {
    logs: PropTypes.array,
    setIsDisplayingLogs: PropTypes.func.isRequired,
    isUserLogs: PropTypes.bool,
    logsByUser: PropTypes.array,
    onPaginate: PropTypes.func.isRequired,
    totalPages: PropTypes.number.isRequired,
    totalByUserPages: PropTypes.number.isRequired
}
export default LogsContentWindow;
