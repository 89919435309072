import React, { useEffect } from "react";

import { Route, Routes, useLocation } from "react-router-dom";
import {useStoreState, useStoreActions} from 'easy-peasy';


import "./Main.scss";
import useBreadCrumbs from "../../hooks/useBreadCrumbs";
import AuthorizationChecker from "../../services/AuthorizationChecker";
import Loader from "../../components/widgets/Loader";
import LogsComponent from "../../components/logs/LogsComponent";

const Main = (props) => {
    const { isMobile, isTablet, navIsOpen } = useStoreState(state => state.responsive);
	const {setNavIsOpen} = useStoreActions(actions => actions.responsive);
	const {pages} = useStoreState(state => state.actionSlugs);
    const { history } = useStoreState(state => state.navigation);
    const { setHistory } = useStoreActions(actions => actions.navigation);
	const {breadcrumbComponent} = useBreadCrumbs();
    const isAdmin = AuthorizationChecker.isAdmin();

    const errorPage = pages?.find((page) => page.id === 'error');

    const location = useLocation();

    useEffect(() => {
        if (history[history.length - 1] !== location.pathname) {
            const newHistory = [...history];

            // Add search params of previous page to history if we want to keep it
            if (location?.state?.olderSearchParams && history.length > 0) {
                newHistory[newHistory.length - 1] = newHistory[newHistory.length - 1] + location.state.olderSearchParams;
            }

            // Add current page to history if new one
            setHistory([...newHistory, location.pathname]);
        }
    }, [location]);

	return (
		<main id='main-wrapper'>
            {isAdmin && <LogsComponent />}
            {isAdmin && breadcrumbComponent}
            {(isMobile || isTablet) && navIsOpen && <div onClick={() => (setNavIsOpen(false))} className='backdrop'></div>}
		
            {pages?.length ?
                <Routes>
                    {
                        pages
                            .filter(pages => !pages.isSeparator)
                            .map((page) => (
                                <Route key={page.id} path={page.slug} element={page.content}></Route>
                            ))
                    }
                    <Route path="*" element={errorPage.content} />
                </Routes>
                : <Loader />}
		</main>
	);
};

export default Main;
