import React, { useState, useMemo, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { useStoreState } from "easy-peasy";
import Loader from '../widgets/Loader.jsx';

const PasswordFormGroup = ({ onValid, userExist }) => {

    const [password, setPassword] = useState('');
    const [checkPassword, setCheckPassword] = useState('');

    const { constants } = useStoreState(actions => actions.params);

    const passwordRegex = useMemo(() => {
        if (constants?.passwordRegex) {
            const pattern = constants.passwordRegex.slice(1, -2);
            const flags = constants.passwordRegex.slice(-1);
            return new RegExp(pattern, flags);
        }
        return null;
    }, [constants]);

    useEffect(() => {
        if (password!=='' && checkPassword !== '' 
            && passwordRegex?.test(password) 
            && checkPassword === password) {
            onValid(true, password);
        }
        else { onValid(false, ''); }
    }, [password, checkPassword, passwordRegex]);

    const formGroup = <>
        <Form.Group controlId="PWD1">
            <Form.Label> {userExist ?  'Nouveau mot de passe': 'Mot de passe'} </Form.Label>
            <Form.Control
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                type="password"
                placeholder="Nouveau mot de passe"
                isValid={password != '' && passwordRegex?.test(password)}
                isInvalid={!passwordRegex?.test(password)} />
            <Form.Control.Feedback type="valid">Mot de passe valable</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">{constants?.passwordMessage ?? "Mot de passe invalide"}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="PWD2">
            <Form.Label>Répéter le mot de passe </Form.Label>
            <Form.Control
                onChange={(e) => setCheckPassword(e.target.value)}
                value={checkPassword}
                type="password"
                placeholder="Répéter le mot de passe"
                isValid={checkPassword != '' && passwordRegex?.test(password) && checkPassword === password}
                isInvalid={!(checkPassword != '' && passwordRegex?.test(password) && checkPassword === password)}
                disabled={!passwordRegex?.test(password)} />
        </Form.Group>
    </>;


    return passwordRegex ? formGroup : <Loader />;
}

export default PasswordFormGroup;