import React, { useEffect, useState } from "react";
import { Container, Row, Button } from "react-bootstrap";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState.jsx";
import useBackButton from "../../hooks/useBackButton.jsx";
import DownloadRomeVideosPDF from "../../components/widgets/generatePDFs/romesVideosList.jsx";
import RomesPageContent from "./RomesPageContent.jsx";
import { useStoreActions } from "easy-peasy";
import AuthorizationChecker from "../../services/AuthorizationChecker.js";

const RomesPage = () => {
    const { allRomes, isFetchingAllRomes } = useCustomGetStoreState("romes");
    const { allVideos } = useCustomGetStoreState("videos");
    const { allStories } = useCustomGetStoreState("stories");
    const { fetchAllRomes } = useStoreActions(actions => actions.romes);
    const { backButtonComponent } = useBackButton();
    const btnPdfRomesLabel = "Pdf expériences immersives par codes ROME";
    const [generateJobs, setGenerateJobs] = useState(false);
    const [romesWithVideosAndStoriesCount, setRomesWithVideosAndStoriesCount] = useState([]);

    useEffect(() => {
        if (allRomes.length === 0 && !isFetchingAllRomes) {
            fetchAllRomes();
        }
    }, [allRomes, isFetchingAllRomes, fetchAllRomes]);

    useEffect(() => {
        if (allRomes?.length > 0 && allVideos?.length > 0 && allStories?.length > 0) {
            const updatedRomes = allRomes.map(rome => {
                const videoCount = allVideos.filter(video => video.romes.includes(rome.codeRome
                )).length;
                const storiesCount = allStories.filter(story => story.romes.includes(rome.codeRome)).length;
                return { ...rome, ...{ usageStories: storiesCount, usageVideos: videoCount } };
            });
            setRomesWithVideosAndStoriesCount(updatedRomes);
        }
    }, [allRomes, allVideos, allStories]);

    return (
        <Container fluid>
            <Row className="item-page">
                <h2 className="d-flex justify-content-between">
                    Codes ROME
                    {AuthorizationChecker.isPageAccesibleFromMenu('romesHome') && backButtonComponent}
                </h2>
            </Row>
            <Row className="mb-3">
                <div className="p-0">
                    {generateJobs
                        ? <DownloadRomeVideosPDF romes={romesWithVideosAndStoriesCount} videos={allVideos} btnPdfLabel={btnPdfRomesLabel} />
                        : <Button variant="primary" onClick={() => { setGenerateJobs(true) }}
                            disabled={allRomes?.length === 0 || allVideos?.length === 0}>
                            <i className="fas fa-download mr-1" />{btnPdfRomesLabel}
                        </Button>}
                </div>
            </Row>
            <RomesPageContent romes={romesWithVideosAndStoriesCount?.length > 0 ? romesWithVideosAndStoriesCount : allRomes} isFetching={isFetchingAllRomes} />
        </Container>
    )
}

export default RomesPage;