import React from "react";
import { Row } from "react-bootstrap";
import PropTypes from 'prop-types';
import { useStoreActions, useStoreState } from "easy-peasy";

import './ProfessionalComponent.scss'
import AuthorizationChecker from "../../services/AuthorizationChecker";
import { useModal } from "../../hooks/useModal";
import { useToast } from "../../hooks/useToast";
import { Link } from "react-router-dom";

const ProfessionalComponent = ({ pro, fromVideoUniqueId = null, showEditButtons = false, onlySummaryInfos = false, onEdit = null }) => {
    const { modalComponent, setModalShow, modalData, setModalData, setIsSending } = useModal();
    const { deleteProFromVideo } = useStoreActions(actions => actions.videos);
    const romesSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('romes'));
    const workingConditionsSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('workingConditions'));
    const areasOfInterestSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('areasOfInterest'));
    const skillsSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('skills'));

    const toast = useToast();

    const hasDeleteRight = AuthorizationChecker.hasDeleteRights('videos');
    const hasUpdateRight = AuthorizationChecker.hasUpdateRights('videos');

    const onDelete = (pro) => {
        if (hasDeleteRight) {
            setModalData({
                ...modalData,
                header: <>Suppression d'un⋅e professionnel⋅le</>,
                content: <>
                    <h5>Êtes-vous sûr⋅e de vouloir supprimer <b>{pro.name}</b>&nbsp;de cette expérience ?</h5>
                    <h6>Si le⋅a professionnel⋅le est rattachée à une autre expérience immersive, il⋅elle ne sera pas supprimé⋅e de cette dernière</h6>
                </>,
                resetButton: 'Supprimer',
                cancelButton: 'Annuler',
                onReset: fromVideoUniqueId ?
                    () => {
                        setIsSending(true);
                        deleteProFromVideo({ videoUniqueId: fromVideoUniqueId, proUniqueId: pro.uniqueId })
                            .then(() => {
                                toast.success();
                                setModalShow(false);
                            })
                            .finally(() => setIsSending(false));
                    }
                    : null,
            });
            setModalShow(true);
        }
    }

    const itemList = (item, slugs) => {
        const link = slugs?.readOne?.replace(':uniqueId', item.code);

        return link
            ? <Link
                key={pro.uniqueId + "_" + item.code}
                to={link}
                className="text-decoration-none">
                <li>
                    {item.label}
                    <i className="fa fa-link ms-2"></i>
                </li>
            </Link>
            : <li>{item.label}</li>;
    }

    return <Row className="card-pro mx-0 p-3 flex-column">
        <div className="d-flex w-100 justify-content-between p-0">
            {hasDeleteRight && showEditButtons &&
                <div className="btn-delete-pro" onClick={() => onDelete(pro)}>
                    <i className="fas fa-trash-alt"></i>
                </div>}
            {hasUpdateRight && showEditButtons &&
                <div className="btn-edit-pro" onClick={() => onEdit?.(pro)}>
                    <i className="fas fa-edit"></i>
                </div>}
        </div>
        <div className="card-pro-content">
            <div className="photo">
                <img src={pro.thumbnail} alt="profil du professionnel" />
            </div>
            <div className="w-100 p-0">
                <h5 className="text-center fw-bold mt-3">{pro.name}</h5>
                {pro.mainJob
                    && <h6 className="text-center mb-4">
                        {pro.mainJob?.label}&nbsp;
                        {!onlySummaryInfos &&
                            (romesSlugs?.readOne?.replace(':uniqueId', pro.mainJob?.rome)
                                ? <>(<Link to={romesSlugs?.readOne?.replace(':uniqueId', pro.mainJob?.rome)}>
                                    {pro.mainJob?.rome}
                                </Link>)</>
                                : <>{pro.mainJob?.rome}</>)}
                    </h6>
                }
                {!onlySummaryInfos && <div className="w-100">
                    {(pro.jobAppellations?.length > 0) && <div className="mb-3">
                        <p className="item-title">Autres expertises</p>
                        <ul>
                            {pro.jobAppellations.map(jobAppellation =>
                                <li key={pro.uniqueId + "_" + jobAppellation.code}>
                                    {jobAppellation.label}&nbsp;
                                    {romesSlugs?.readOne?.replace(':uniqueId', jobAppellation?.rome)
                                        ? <>(<Link to={romesSlugs?.readOne?.replace(':uniqueId', jobAppellation?.rome)}>
                                            {jobAppellation?.rome}
                                        </Link>)</>
                                        : <>(jobAppellation?.rome)</>}
                                </li>)
                            }
                        </ul>
                    </div>}
                    {pro.areasOfInterest?.length > 0 && <div className="mb-3">
                        <p className="item-title"><i className="fa fa-heart fa-lg me-2" />Centres d'intérêt</p>
                        <ul>
                            {pro.areasOfInterest.map(areaOfInterest => itemList(areaOfInterest, areasOfInterestSlugs))}
                        </ul>
                    </div>}
                    {pro.softSkills?.length > 0 && <div className="mb-3">
                        <p className="item-title"><i className="fa fa-user-cog fa-lg me-2" />Savoir-être professionnels</p>
                        <ul>
                            {pro.softSkills.map(softSkill => itemList(softSkill, skillsSlugs))}
                        </ul>
                    </div>}
                    {pro.workingConditions?.length > 0 && <div className="mb-3">
                        <p className="item-title"><i className="fa fa-briefcase fa-lg me-2" />Contextes de travail</p>
                        <ul>
                            {pro.workingConditions.map(workingCondition => itemList(workingCondition, workingConditionsSlugs))}
                        </ul>
                    </div>}
                </div>}
            </div>
        </div>
        {modalComponent}
    </Row>
}

ProfessionalComponent.propTypes = {
    pro: PropTypes.object.isRequired,
    fromVideoUniqueId: PropTypes.string,
    showEditButtons: PropTypes.bool,
    onlySummaryInfos: PropTypes.bool,
    onEdit: PropTypes.func
};

export default ProfessionalComponent;