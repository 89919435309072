import React, { useEffect, useMemo, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Button } from 'react-bootstrap';
import { useLocation } from "react-router-dom";
import './LogsComponent.scss';
import PropTypes from 'prop-types';
import LogsContentWindow from './LogsContentWindow';

const LogsComponent = ({ entityNameFromComponent, uniqueIdFromComponent, isUserLogs = false }) => {
    const { fetchLogsByEntity, fetchLogsByUser } = useStoreActions(actions => actions.logs);
    const { isFetchingLogsByEntity, isFetchingLogsByUser } = useStoreState(state => state.logs);
    const { apiData } = useStoreState(state => state.actionSlugs);
    const location = useLocation();

    const [isDisplayingLogs, setIsDisplayingLogs] = useState(false);
    const [logs, setLogs] = useState([]);
    const [logsByUser, setLogsByUser] = useState([]);
    const [entityName, uniqueId] = entityNameFromComponent && uniqueIdFromComponent
        ? [entityNameFromComponent, uniqueIdFromComponent]
        : location.pathname.split('/').filter(Boolean).slice(0, 2);
    const isFromComponent = entityNameFromComponent && uniqueIdFromComponent;
    const [totalPages, setTotalPages] = useState(1);
    const [totalByUserPages, setTotalByUserPages] = useState(1);

    useEffect(() => {
        isDisplayingLogs && setIsDisplayingLogs(false);
    }, [location])

    const validLogsEntity = useMemo(() => {
        if (isFromComponent) return true;
        // Check if url exists in order to not display in view where there is no update, create or delete action
        const url = apiData?.ACTIONS?.[entityName]?.logs?.url ?? null;
        return entityName && uniqueId && url;
    }, [entityName, uniqueId, apiData])

    useEffect(() => {
        let isMounted = true;
        setLogs();
        if (isMounted && validLogsEntity && isDisplayingLogs) {
            !isFetchingLogsByEntity && fetchLogsByEntity({ entityName, uniqueId })
                ?.then(result => {
                    result.data && setLogs(result.data);
                    result.totalPages && setTotalPages(result.totalPages)
                });
            isUserLogs && !isFetchingLogsByUser && fetchLogsByUser({ uniqueId })
                ?.then(result => {
                    result.data && setLogsByUser(result.data);
                    result.totalPages && setTotalByUserPages(result.totalPages)
                });
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
        }

        return () => {
            isMounted = false;
        }
    }, [isDisplayingLogs]);

    const onPaginate = (pageNumber, isByUser = false) => {
        if (isByUser) {
            return fetchLogsByUser({ uniqueId, page: pageNumber })
                ?.then(result => result.data && setLogsByUser(result.data));
        } else {
            return fetchLogsByEntity({ entityName, uniqueId, page: pageNumber })
                ?.then(result => result.data && setLogs(result.data));
        }
    }

    return validLogsEntity ? <>
        <Button
            variant="light"
            className={isFromComponent ? '' : 'position-absolute end-0 me-4 mt-1'}
            onClick={(e) => {e.stopPropagation();setIsDisplayingLogs(true)}}
        >
            <i className="fas fa-history"></i>
        </Button>
        {isDisplayingLogs &&
            <LogsContentWindow
            logs={logs}
            setIsDisplayingLogs={setIsDisplayingLogs}
                isUserLogs={isUserLogs}
                logsByUser={logsByUser}
            onPaginate={onPaginate}
            totalPages={totalPages}
            totalByUserPages={totalByUserPages}
            />}
    </> : <></>;
}

LogsComponent.propTypes = {
    entityNameFromComponent: PropTypes.string,
    uniqueIdFromComponent: PropTypes.string,
    isUserLogs: PropTypes.bool
}
export default LogsComponent;
