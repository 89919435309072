import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';

import { Button, Container, Form, Table } from 'react-bootstrap';
import ScrollToTopButton from '../scrollToTopButton/ScrollToTopButton.jsx';
import Loader from '../Loader.jsx';
import { useStoreActions, useStoreState } from 'easy-peasy';

import './DynamicTableInfinite.scss';
import useDynamicTableWithParams from '../../../hooks/useDynamicTableWithParams.jsx';
import CsvDownloadComponent from '../csvDownload/CsvDownloadComponent.jsx';
import HelperMetiers360 from '../../../services/HelpersMetiers360.js';

const TdLinkWrapper = ({getUrl, content, children, ...rest}) => {
    const pathname = getUrl ? getUrl(content) : false;

    if (pathname) {
        return <td style={{cursor: 'pointer'}} {...rest}><a href={pathname}>{children}</a> </td>
    }
    return <td {...rest}> {children} </td>;
}

/**
 * DynamicTableInfinite widget
 *
 * @version 1.0.0
 * @param {array} contentTable the data to display
 * @param {array} contentSort contains all necessary data for rendering :
 *      value : a slug for seleting data
 *      label : label for coulumn heading
 *      test : test of existence of the data
 *      method : method of sorting the column
 *      display : how to render the data
 * @param {string} index name for indexing data
 * @param {function} handleClick callback function to handle click on a particular row
 */
const DynamicTableInfinite = ({
    contentTable,
    contentSort,
    index,
    handleClick,
    className,
    activeRowId,
    valueInitSort,
    fetchData,
    hasMore,
    setSortedContent,
    sortedContent,
    withParams,
    sortState: [shouldSort, setShouldSort],
    downloadable = true,
    isManuallyTriggered=false,
    isLoading = false,
    enableToControlVisibleColumns = true,
    tableName = null,
    ...other
}) => {
    const noSortCallback = useCallback(() => 1, []);

    const [sort, setSort] = useState(valueInitSort ? contentSort.find(c => c.value === valueInitSort) : {method: noSortCallback});
    const [revert, setRevert] = useState(false);
    const [isOptionsTableOpen, setIsOptionsTableOpen] = useState(false);
    const tablesColumnsVisiblePreference = useStoreState(state => state.preferences.tablesColumnsVisible);
    const {updateTableColumnsVisible, addTableColumnsVisible} = useStoreActions(actions => actions.preferences);

    const [downloadCSV, setDownloadCSV] = useState(false);

    useDynamicTableWithParams({withParams, sort, setSort, revert, setRevert, contentSort,
        initSort: (valueInitSort ? contentSort.find(c => c.value === valueInitSort) : {method: noSortCallback})})
  
    useEffect(() => {
        enableToControlVisibleColumns && tablesColumnsVisiblePreference[tableName] === undefined
            && addTableColumnsVisible({
                tableName,
                tableColumnsVisible: Object.fromEntries(contentSort
                    .filter(content => content.test)
                    .map(({value}) => [value, true])
                )
            })
    }, []);

    useEffect(() => {
       if(tablesColumnsVisiblePreference[tableName]) {
            const columnPreferences = Object.keys(tablesColumnsVisiblePreference[tableName]);
            contentSort.filter(content => content.test)
                .map(content => content.value)
                .forEach(column => {
                    if(!columnPreferences.includes(column)) {
                        updateTableColumnsVisible({
                            tableName,
                            tableColumnsVisible: {[column]: true}
                        })
                    }
                })
        }
    }, [contentSort])

    const onChoiceSort = (choice) => {
        const targetSort = contentSort.filter(type => type.value === choice)[0];
        if(sort.value === targetSort.value && !revert) {
            setRevert(true);
        }
        else {
            setSort(targetSort);
            setRevert(false);
        }
    };

    const hiddenRef = useRef();
    
    useEffect(() => {
        // if first fetch done and all content is in viewport -> fetch again till content not in viewport
        if(contentTable?.length && window.pageYOffset + window.innerHeight >= hiddenRef.current.offsetTop && hasMore) {
            fetchData();
        }
    })

    // apply sorting on sort change
    useEffect(() => {
        if (noSortCallback === sort.method)
            return

        sortedContent?.length && setSortedContent([...sortedContent.sort(sort.method)])

    }, [sort, noSortCallback]);
    
    useEffect(() => {
       
        sortedContent?.length && revert && setSortedContent([...sortedContent.sort(sort.method).reverse()])

    }, [revert, sort]);



    //apply sorting on search change
    useEffect(() => {
        if (!shouldSort)
            return;
    
        if (!revert) {
            setSortedContent([...sortedContent.sort(sort.method)])
            setShouldSort(false);
        }
        else {
          
            setSortedContent([...sortedContent.sort(sort.method).reverse()]);
            setShouldSort(false);
        }
    }, [sortedContent])
    
    let compt = 0;

    const tableRows = useMemo(() => contentTable
        .map((content) => {
            compt++;
            return <tr 
                key={content[index]}  
                style={content?.background ? { background: content?.background } : null}
                className={(activeRowId && activeRowId === content[index] ? 'active ' : '')
                    + (handleClick ? 'clickable' : null)}
                onClick={handleClick ? (event) => {
                    handleClick(content[index], event)
                } : null}
                >
                    <td>{compt}</td>
                    {contentSort
                        .map(type => (
                            type.test 
                                && (!enableToControlVisibleColumns || (tablesColumnsVisiblePreference[tableName]?.[type.value]))
                                && <TdLinkWrapper key={type.value.concat('-',content[index])}  content={content} getUrl={type.getRedirectUrl}>{type.display(content)}</TdLinkWrapper>
                            )
                        )
                    }
                </tr>
            }
    ), [tablesColumnsVisiblePreference, contentSort]);
    
    const rowMore = <tr>
        <td className='more' colSpan={contentSort.length + 1} onClick={() => {hasMore && fetchData()}}>
            <i className="fas fa-caret-down" /> Plus <i className="fas fa-caret-down" />
        </td></tr>

    const table = useMemo(() => <InfiniteScroll
            dataLength={tableRows.length} //This is important field to render the next data
            next={fetchData}
            hasMore={hasMore}
            loader={<Loader />}
            scrollThreshold={0.99}
            hasChildren={tableRows.length}
            style={{overflow: "visible"}}>                                
            <Table striped bordered hover size="sm" className={className ?? 'table-activity'}>
                    <thead id="table-header" className='text-center'>
                        <tr>
                            <th className='bg-white'>#</th>
                            {contentSort.map(type => type.test
                                && (!enableToControlVisibleColumns || (tablesColumnsVisiblePreference[tableName]?.[type.value]))
                                && <th
                                    key={type.value}
                                    style={type.method ? { cursor: 'pointer' }:null}
                                    onClick={type.method ? () => onChoiceSort(type.value):null}
                                    className={sort.value == type.value ? 'bg-primary text-white' : 'bg-white'}>
                                    {type.label}
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {tableRows}
                        {hasMore && isManuallyTriggered && rowMore}
                    </tbody>
            </Table>
            <div ref = {hiddenRef} />
        </InfiniteScroll>
    , [tablesColumnsVisiblePreference, contentSort]);

    const resetTableColumnsVisible = () => {
        let newTablesColumnsVisiblePreference = tablesColumnsVisiblePreference[tableName];

        Object.keys(newTablesColumnsVisiblePreference).forEach(function(key) {
            newTablesColumnsVisiblePreference[key] = true;
        });

        updateTableColumnsVisible({
            tableName, 
            tableColumnsVisible: newTablesColumnsVisiblePreference
        });

        document.getElementById('check-columns-visible').querySelectorAll('input[type=checkbox]')
            .forEach(el => el.checked = true);
    }

    const visibleColumnsUncheckedCount = useMemo(() => 
        tablesColumnsVisiblePreference[tableName]
            ? Object.values(tablesColumnsVisiblePreference[tableName])?.reduce((acc, current) => { 
                return !current ? acc + 1 : acc; 
            }, 0)
            : 0
    , [tablesColumnsVisiblePreference]);

    const optionsTableButton = enableToControlVisibleColumns
        && <Button variant="none" 
                className={'btn-options-table ' + (isOptionsTableOpen ? 'btn-options-table-open' : '')} 
                onClick={() => setIsOptionsTableOpen(!isOptionsTableOpen)}>
            Options du tableau
            { visibleColumnsUncheckedCount > 0 && " (" + visibleColumnsUncheckedCount + ")" }
            { isOptionsTableOpen 
                ? <i className="fa fa-caret-up ms-3"></i>
                : <i className="fa fa-caret-down ms-3"></i>
            }
        </Button>;

    const optionsTableContent = isOptionsTableOpen && tablesColumnsVisiblePreference[tableName]
        && <div className='options-table-content d-flex flex-wrap' id='check-columns-visible'>
            <b className='me-3'>Colonnes à afficher :</b>
            { contentSort.map(type => type.test 
                && <Form.Check
                    type="checkbox"
                    className="me-3"
                    onChange={(e) => updateTableColumnsVisible({
                        tableName, 
                        tableColumnsVisible: {...tablesColumnsVisiblePreference[tableName], [type.value]: e.target.checked }}
                    )}
                    defaultChecked={tablesColumnsVisiblePreference[tableName][type.value]}
                    label={type.label}
                    key={type.value}
                    id={type.value}
                />)
            }
            { visibleColumnsUncheckedCount > 0 
                && <Button className="btn-link" size="sm" onClick={() => resetTableColumnsVisible()}>Afficher toutes les colonnes</Button>
            }
        </div>;

    return (
        <Container fluid>
            <div className={'d-flex infinite-table w-100' + (downloadable ? ' justify-content-between' : ' justify-content-end')}>
                { downloadable ?
                downloadCSV ?
                    <CsvDownloadComponent contentTable={sortedContent} contentSort={contentSort} setDownloadCSV={setDownloadCSV} {...other} />
                    : <div type="button" role="button" onClick={()=>setDownloadCSV(true)}><i className="fas fa-download mr-2"/><span className='text-decoration-underline'>Télécharger en csv</span></div>
                :  null}
                { isLoading
                    ? <Loader/>
                    : optionsTableButton
                }
                <span>{ sortedContent.length } élément{ sortedContent.length > 1 && 's'}</span>
            </div>
            { optionsTableContent }
            { table }
            <ScrollToTopButton />
        </Container>
    );
}

DynamicTableInfinite.propTypes = {
    contentTable: PropTypes.array.isRequired,
    contentSort: PropTypes.array.isRequired,
    index: PropTypes.string.isRequired,
    handleClick: PropTypes.any,
    className: PropTypes.string,
    activeRowId: PropTypes.string,
    fetchData: PropTypes.any,
    setSortedContent: PropTypes.any,
    sortedContent: PropTypes.array,
    hasMore: PropTypes.bool,
    withParams: PropTypes.bool,
    sortState: PropTypes.any,
    downloadable: PropTypes.bool,
    isManuallyTriggered: PropTypes.bool,
    isLoading: PropTypes.bool,
    enableToControlVisibleColumns: PropTypes.bool,
    valueInitSort: PropTypes.string,
    tableName: PropTypes.string
};

export default DynamicTableInfinite;