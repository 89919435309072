import React, { useEffect, useState, useRef, useMemo } from "react";
import { Form, Row, Col, Alert, Container, Button, Card, Tabs, Tab } from 'react-bootstrap';
import Loader from "../../components/widgets/Loader";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState";
import { useStoreActions, useStoreState } from 'easy-peasy';


import DatePicker, { registerLocale } from "react-datepicker";
import { fr } from 'date-fns/locale/fr';

import "react-datepicker/dist/react-datepicker.css";
import "./ProducerStatsPage.scss";
import SelectMediaModuleComponent from "../../components/widgets/SelectMediaModule/SelectMediaModuleComponent.jsx";
import GraphVideosComponent from "../../components/producerStats/GraphVideosComponent.jsx";
import AuthorizationChecker from "../../services/AuthorizationChecker.js";

registerLocale('fr', fr);

const ProducerStatsPage = () => {

    const { allVideos, isFetchingAllVideos } = useCustomGetStoreState('videos');
    const videosStatisticsUuidSelected = useStoreState(state => state.preferences.videosStatisticsUuidSelected);
    const setVideosStatisticsUuidSelected = useStoreActions(actions => actions.preferences.setVideosStatisticsUuidSelected);

    const [minDate, setMinDate] = useState();
    const [maxDate, setMaxDate] = useState();
    const [isExpand, setIsExpand] = useState(false);

    const videosRef = useRef([]);
    const [selectedVideos, setSelectedVideos] = useState([]);
    const [infoDisplayed, setInfoDisplayed] = useState('video_views');
    const [key, setKey] = useState('chronogram');

    const DEBOUNCE_DELAY = 1000;

    const videos = useMemo(() => {
        if (AuthorizationChecker.isAdmin()) {
            return allVideos;
        } else {
            return allVideos?.filter(video => video?.isProducedByClient);
        }
    }, [allVideos]);

    useEffect(() => {
        if (!isFetchingAllVideos) {
            videosStatisticsUuidSelected.length === 0 &&
                setVideosStatisticsUuidSelected(videos.map(video => video.uniqueId));
            videosRef.current = videosStatisticsUuidSelected.length === 0
                ? videos
                : videos.filter(video => videosStatisticsUuidSelected.includes(video.uniqueId));
        }
    }, [isFetchingAllVideos, videos])

    useEffect(() => {
        let timeoutId;

        const fetchData = () => {
            setVideosStatisticsUuidSelected(selectedVideos.map(video => video.uniqueId));
        };

        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(fetchData, DEBOUNCE_DELAY);

        return () => clearTimeout(timeoutId);
    }, [selectedVideos])

    const toggleMeanViewsDuration = [
        { label: "Pourcentage", value: "percent", graphType: "meanViewsDurationPercent", defaultChecked: true },
        { label: "Nombre", value: "number", graphType: "meanViewsDuration", defaultChecked: false }
    ];

    const toggleGeoMap = [
        { label: "Régions", value: "regions", mapType: "regions", defaultChecked: true },
        { label: "Départements", value: "departments", mapType: "departments", defaultChecked: false }
    ];

    const dateFilter = <div className="d-flex align-items-center">
        <label className="label-select w-auto">Filtrer sur une période&nbsp;:</label>
        <div className="d-flex w-auto">
            <DatePicker
                showIcon
                locale='fr'
                id='start'
                dateFormat="dd/MM/yyyy"
                selected={minDate}
                onChange={(date) => setMinDate(date)}
                placeholderText="jj/mm/aaaa"
                maxDate={maxDate}
            />
            <label htmlFor="end" className="m-2">&nbsp;au</label>
            <DatePicker
                showIcon
                locale='fr'
                id='end'
                dateFormat="dd/MM/yyyy"
                selected={maxDate}
                onChange={(date) => setMaxDate(date)}
                placeholderText="jj/mm/aaaa"
                minDate={minDate}
            />
            <Button id="btnInitFilters" className="shadow" variant="danger" size="sm" onClick={() => { setMinDate(null); setMaxDate(null) }}>
                <i className="fas fa-redo"></i>
            </Button>
        </div>
    </div>;

    const typeFilter = <div className="d-flex align-items-center">
        <label className="label-select w-auto">Filtrer selon le type d'information :</label>
        <Form.Select name="info-displayed" className="w-auto" onChange={(e) => setInfoDisplayed(e.target.value)}>
            <option value="video_views">Vues des expériences immersives</option>
            <option value="unique_clients_total">Clients ayant lu les expériences immersives</option>
            <option value="unique_devices_total">Casques ayant lu les expériences immersives</option>
        </Form.Select>
    </div>;

    return (
        <Container fluid>
            <Row>
                <Col>
                    <h2 className='d-flex'>
                        Mesure d'impact
                    </h2>
                </Col>
            </Row>

            {isFetchingAllVideos ?
                <Loader />
                : <>
                    <Row className="audience-select-videos">
                        <Card className="contents_details_card">
                            <Card.Title className="contents_details_title d-flex justify-content-between mx-4 cursor-pointer"
                                onClick={() => setIsExpand(prev => !prev)}>
                                {selectedVideos.length +
                                    (selectedVideos.length > 1
                                        ? " expériences immersives sélectionnées"
                                        : " expérience immersive sélectionnée")
                                    + " pour analyse"}
                            </Card.Title>
                            <Card.Body className={"contents_details_body" + (isExpand ? "" : " visually-hidden")}>
                                {isFetchingAllVideos
                                    ? <Loader />
                                    : <SelectMediaModuleComponent allMedias={videos} mediaType="video" mediaResultRef={videosRef}
                                        draggable={false} setRealValue={setSelectedVideos} onlySummaryInfos={true} />}
                            </Card.Body>
                        </Card>
                    </Row>
                    <div className="expand-btn cursor-pointer" onClick={() => setIsExpand(prev => !prev)}>
                        {isExpand ? <i className="fa fa-angle-up fa-lg" /> : <i className="fa fa-angle-down fa-lg" />}
                    </div>

                    {selectedVideos.length > 0 ?
                        <Tabs defaultActiveKey="chronogram" id="tabs-graphs" activeKey={key} onSelect={(k) => setKey(k)}>
                            <Tab eventKey="chronogram" title="Nombre de vues hebdomadaires" mountOnEnter={true} unmountOnExit={false}>
                                <Row className="graph-container mt-4">
                                    <GraphVideosComponent graphType="chronogram" videosUuid={videosStatisticsUuidSelected} aggregatable={true} filterableByDates={false} />
                                </Row>
                            </Tab>
                            {AuthorizationChecker.isAdmin() &&
                                <Tab eventKey="meanViewsDurationPercent" title="Durées de lecture" mountOnEnter={true} unmountOnExit={false}>
                                    {dateFilter}
                                    <Row className="graph-container mt-4">
                                        <GraphVideosComponent graphType="meanViewsDurationPercent" videosUuid={videosStatisticsUuidSelected} minDate={minDate}
                                            maxDate={maxDate} aggregatable={true} toggleData={toggleMeanViewsDuration} />
                                    </Row>
                                </Tab>
                            }
                            <Tab eventKey="clientCategories" title="Catégories d'organisation" mountOnEnter={true} unmountOnExit={false}>
                                <div className="d-flex justify-content-between my-4">
                                    {dateFilter}
                                    {typeFilter}
                                </div>
                                <Row className="graph-container mt-4">
                                    <GraphVideosComponent graphType="clientCategories" videosUuid={videosStatisticsUuidSelected} minDate={minDate} maxDate={maxDate}
                                        infoDisplayed={infoDisplayed} height="80vh" />
                                </Row>
                            </Tab>
                            <Tab eventKey="geoMap" title="Répartition géographique" mountOnEnter={true} unmountOnExit={false}>
                                <div className="d-flex justify-content-between my-4">
                                    {dateFilter}
                                    {typeFilter}
                                </div>
                                <Row className="graph-container mt-4">
                                    <GraphVideosComponent graphType="geoMap" videosUuid={videosStatisticsUuidSelected} minDate={minDate} maxDate={maxDate}
                                        infoDisplayed={infoDisplayed} toggleData={toggleGeoMap} height="80vh" />
                                </Row>
                            </Tab>
                        </Tabs>
                        : <Alert variant="info" className="mt-4">Veuillez sélectionner au moins une expérience immersive</Alert>
                    }
                </>
            }
        </Container >
    );
}

export default ProducerStatsPage;