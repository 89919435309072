import PropTypes from 'prop-types';
import { Button, ListGroup, ListGroupItem, Spinner } from 'react-bootstrap';
import { useState } from 'react';
import SelectMultipleClientsComponent from '../../../widgets/selectClient/SelectMultipleClientsComponent';
import { useStoreActions, useStoreState } from 'easy-peasy';
import SubscriptionFormComponent from '../../../subscription/uploadForm/SubscriptionFormComponent';
import { useToast } from '../../../../hooks/useToast';

/**
 * @param {array} clientUniqueIdsSelected unique id array of child clients to select by default
 * @param {array} selectedUniqueIdParentClient unique id of parent client to select by default
 * @example <MultipleSubscriptionCreationComponent clientUniqueIdsSelected={[]} selectedUniqueIdParentClient={""} />
 */
const MultipleSubscriptionCreationComponent = ({ clientUniqueIdsSelected, selectedUniqueIdParentClient }) => {
    const toast = useToast();

    const { allClientsCRM } = useStoreState(state => state.clients);
    const { updateClientCRM } = useStoreActions(actions => actions.clients);

    const [requestAnswersWindow, setRequestAnswersWindow] = useState(false)
    const [clientUniqueIds, setClientUniqueIds] = useState(clientUniqueIdsSelected)
    const [currentSending, setCurrentSending] = useState(null);
    const [logSent, setLogSent] = useState([]);

    const updateClients = (formDataSubscription) => {
        setRequestAnswersWindow(true);
        const clientToSend = {};

        let subscription = {
            subscriptionName: formDataSubscription.get(`subscriptionName`),
            headsetNumberPredicted: formDataSubscription.get(`headsetNumberPredicted`),
            subscriptionEndDate: formDataSubscription.get(`subscriptionEndDate`),
            subscriptionCsrfToken: formDataSubscription.get(`subscriptionCsrfToken`)
        };
        clientToSend["subscription"] = subscription;

        const doNextPromise = (key) => {
            const currentClient = allClientsCRM.find((client) => client.uniqueId === clientUniqueIds[key]);

            if (currentClient) {
                setCurrentSending(currentClient.name);

                const payload = {
                    data: clientToSend,
                    uniqueId: currentClient.uniqueId,
                }

                updateClientCRM(payload)
                    ?.then(() => {
                        logSent.push({
                            key,
                            name: currentClient.name,
                            isOK: true,
                            msg: 'Enregistré sur le serveur'
                        });
                    })
                    ?.catch(err => {
                        logSent.push({
                            key,
                            name: currentClient.name,
                            isFailed: true,
                            msg: err
                        });
                    })
                    ?.finally(() => {
                        setLogSent(logSent);
                        setCurrentSending(null);
                        key++;
                        if (key < clientUniqueIds.length) doNextPromise(key)
                    });
            } else {
                logSent.push({
                    key,
                    name: "Inconnu - " + clientUniqueIds[key],
                    isFailed: true,
                    msg: "Client non trouvé"
                });
                setLogSent(logSent);
            }
        };

        doNextPromise(0);
    }

    const onValidate = () => {
        const formDataSubscription = new FormData(document.getElementById('subscription-form'));
        const subscriptionEndDate = formDataSubscription.get(`subscriptionEndDate`);
        const headsetNumberPredicted = formDataSubscription.get(`headsetNumberPredicted`);
        const subscriptionName = formDataSubscription.get(`subscriptionName`);
        let message = "";

        if (subscriptionName == "") {
            message = message + 'Veuillez indiquer le nom de l\'abonnement. ';
        }
        if (!subscriptionEndDate) {
            message = message + 'Veuillez indiquer une date de fin de l\'abonnement. '
        }
        if (!headsetNumberPredicted) {
            message = message + 'Veuillez saisir le nombre de casque prévu pour l\'abonnement.'
        }

        if (message !== "") {
            toast.open({ message, variant: "danger" });
        } else {
            updateClients(formDataSubscription);
        }
    }

    const logSentDisplay = logSent.map(log => <ListGroupItem key={log.key}>
        Envoi des informations de {log.name}&nbsp;
        {log.isOK && <span className="text-success icon-checkmark"> &nbsp;{log.msg}</span>}
        {log.isFailed && <span className="text-danger icon-cross"> &nbsp;{log.msg}</span>}
    </ListGroupItem>);
    const currentSendingDisplay = currentSending &&
        <ListGroupItem>Envoi en cours des informations de {currentSending}&nbsp;<Spinner size="sm" animation="border" /></ListGroupItem>;

    return !requestAnswersWindow
        ? <>
            <SelectMultipleClientsComponent
                selectedClients={clientUniqueIds}
                setSelectedClients={setClientUniqueIds}
                selectedUniqueIdParentClientDefault={selectedUniqueIdParentClient}
            />
            <SubscriptionFormComponent action='create' />
            <Button variant="success" size="sm" className="align-right mt-4 me-4"
                // disabled={selectedTagOpeIds.length < 1}
                onClick={onValidate}>
                <span className="icon-checkmark"></span> C'est parti !
            </Button>
        </>
        : <>
            {logSent.length > 0 && <ListGroup>{logSentDisplay}</ListGroup>}
            {currentSending != null && <ListGroup>{currentSendingDisplay}</ListGroup>}
        </>;
}

MultipleSubscriptionCreationComponent.propTypes = {
    clientUniqueIdsSelected: PropTypes.array,
    selectedUniqueIdParentClient: PropTypes.string,
}

export default MultipleSubscriptionCreationComponent;