import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

import { useStoreState, useStoreActions } from 'easy-peasy';
import DownloadRomeVideosPDF from "../../components/widgets/generatePDFs/romesVideosList.jsx";
import DownloadVideosListPDF from "../../components/widgets/generatePDFs/videosList.jsx";

import useCustomGetStoreState from "../../hooks/useCustomGetStoreState.jsx";
import DownloadJobsVideosPDF from "../../components/widgets/generatePDFs/jobsVideoTable.jsx";

import "./VideosPage.scss";
import TooltipInfo from "../../components/widgets/tooltipInfo/TooltipInfo.jsx";
import VideosPageContent from "./VideosPageContent.jsx";
import AuthorizationChecker from "../../services/AuthorizationChecker.js";
import HelperMetiers360 from "../../services/HelpersMetiers360.js";


const VideosPage = () => {

    const { allVideos, isFetchingAllVideos, isGradualFetchingAllVideos } = useCustomGetStoreState("videos");
    const { fetchAllPropsForAllVideos } = useStoreActions(actions => actions.videos);
    const { allRomes } = useCustomGetStoreState("romes");
    const { allJobAppellations, isFetchingAllJobAppellations } = useStoreState(state => state.romes);
    const { fetchAllJobAppellations } = useStoreActions(actions => actions.romes);

    const [generateJobs, setGenerateJobs] = useState(false);
    const [generateUniverse, setGenerateUniverse] = useState(false);
    const [formattedJobAppellations, setFormattedJobAppellations] = useState([]);
    const [generateAppellationJobs, setGenerateAppellationJobs] = useState(false);

    const btnPdfRomesLabel = "Par code ROME";

    useEffect(() => {
        AuthorizationChecker.hasGetPropsForAllRights('videos') && fetchAllPropsForAllVideos();
    }, []);

    useEffect(() => {
        if (!allJobAppellations?.length && !isFetchingAllJobAppellations) {
            fetchAllJobAppellations();
        }
    }, [allJobAppellations, isFetchingAllJobAppellations])

    useEffect(() => {
        if (!isFetchingAllJobAppellations && !isFetchingAllVideos
            && allJobAppellations.length !== 0 && allVideos.length !== 0
        ) {
            HelperMetiers360.isArrayContainsValue(allJobAppellations, "pros") && setFormattedJobAppellations(
                allJobAppellations.map(job => {
                    const videoUniqueIds = job.pros?.flatMap(pro => pro.videos);

                    return {
                        ...job, 
                        videos: allVideos?.filter(video => videoUniqueIds?.includes(video.uniqueId))
                    }
                })
            );
        }
    }, [allJobAppellations, allVideos, isFetchingAllJobAppellations, isFetchingAllVideos])

    return <Container fluid>
            <Row>
            <h2 className="d-flex">
                Notre catalogue d'expériences immersives
                <TooltipInfo description="Explorez notre catalogue d'expériences immersives, découvrez les différents univers professionnels 
                    et les métiers qui y sont associés" />
            </h2>
            </Row>
            <Row className="mt-2 mb-4 d-flex justify-content-between align-items-center">
            <Col className="col-auto">
                {generateUniverse
                    ? <DownloadVideosListPDF videos={allVideos?.sort((a, b) => a.videoName.localeCompare(b.videoName))} />
                    : <Button variant="primary" onClick={() => { setGenerateUniverse(true) }}
                        disabled={allVideos?.length === 0}>
                        <i className="fas fa-download mr-1" />Catalogue
                    </Button>}
            </Col>
            <Col className="col-auto">
                {generateAppellationJobs
                    ? <DownloadJobsVideosPDF title="Par métier" buttonText="Par métier"
                        fileName="tableau-experiences-par-metier"
                        jobs={formattedJobAppellations?.sort((a, b) => a.label.localeCompare(b.label))} />
                    : <Button variant="primary" onClick={() => { setGenerateAppellationJobs(true) }}
                        disabled={formattedJobAppellations?.length === 0 || allVideos?.length === 0}>
                        <i className="fas fa-download mr-1" />Par métier
                    </Button>}
            </Col >
            <Col className="col-auto">
                {generateJobs
                    ? <DownloadRomeVideosPDF romes={allRomes} videos={allVideos} btnPdfLabel={btnPdfRomesLabel} />
                    : <Button variant="primary" onClick={() => { setGenerateJobs(true) }}
                        disabled={allRomes?.length === 0 || allVideos?.length === 0}>
                        <i className="fas fa-download mr-1" />{btnPdfRomesLabel}
                    </Button>}
            </Col >
            </Row>
        <VideosPageContent videos={allVideos} isVideosLoading={isFetchingAllVideos || isGradualFetchingAllVideos} displayCreationBtn={true} />
        </Container>;
};

export default VideosPage;