import _escape from "lodash/escape";
import { v4 as uuidv4 } from 'uuid';
export default class HelperMetiers360 {

    /**
     * return formated date to be displayed.
     * @method displayDate
     * @param {String} date The date to format.
     * @return {String} Returns the formated date.
     */
    static getdisplayDateType(date, type='long') {
        if(!date) return "";
        const formatedDate= new Date(date);
        const optionDate= type==='day' ? 
            {year: "numeric", month: "numeric", day: "numeric"} 
            : {dateStyle : 'short', timeStyle: 'short'};
        return formatedDate.toLocaleString("fr-FR", optionDate)
    }

    /**
     * Converts a date timestamp into a string readable format (dd/mm/yyyy hh:mm), according to the UTC time zone
     * @method timestampToDisplayDate
     * @param {number} timestamp timestamp date to convert
     * @return {string} Returns string UTC Date readable
     */
    static timestampToDisplayDate = (timestamp) => {        
        return this.getdisplayDateType(new Date(timestamp * 1000)) ?? "";
    }

    /**
         * Converts a date timestamp into a datetime for Form control
         * @method timestampToFormControlDateTime
         * @param {number} timestamp timestamp date to convert
         * @return {string} Returns string Datetime for form control
         */
    static timestampToFormControlDateTime = (timestamp) => { 
        const date = new Date(timestamp * 1000);
        return this.formatDateForFormControl(date, 'date') + 'T' + this.formatDateForFormControl(date, 'time');
    }

    /**
     * Converts a date string format (dd/mm/yyyy hh:mm) into timestamp, according to the UTC time zone
     * @method displayDateTotimestamp
     * @param {strung} dateString string UTC Date readable
     * @return {number} Returns timestamp date
     */
    static displayDateTotimestamp = (dateString) => {        
        return dateString !== "" 
            ? Date.parse(new Date(dateString)) / 1000
            : null;
    }

    static formatDateForFormControl(date, type='date'){
        const formatedDate= new Date(date);
        if(type == 'time') {
            const hh = ('0'+formatedDate.getHours()).slice(-2);
            const ii = ('0'+formatedDate.getMinutes()).slice(-2);
            return `${hh}:${ii}`;
        }
        const yyyy = formatedDate.getFullYear();
        const mm = ('0'+(formatedDate.getMonth()+1)).slice(-2);
        const dd = ('0'+formatedDate.getDate()).slice(-2);formatedDate.getDate();
        return `${yyyy}-${mm}-${dd}`;

    }

    /**
     * Generates unique id.
     * @method generateUniqueId
     * @return {Number} Returns generated id.
     */
    static generateUniqueId() {
        return uuidv4();
    }

    /**
     * return regex expression for newline characters
     */

      static get newLineRegExp(){
        return /\\n/g;
    }

    /**
     * return truncated text on last whitespace.
     * @method textTruncate
     * @param {String} str text to be truncated.
     * @param {Number} length length of truncated text.
     * @param {String} ending ending of truncated text.
     * @return {string} Returns truncated text.
     */
    static textTruncate(str, length=100, ending='...') {

        if (str == null) {
            return '';
        }

        if (str.length > length) {
            let truncTxt = str.substring(0, length - ending.length);
            const regexp = /\s/g;
            let lastWhiteSpaceIdx = 0;
            let match = []
            while ((match = regexp.exec(truncTxt)) != null) {
                lastWhiteSpaceIdx=match.index;
                }
            return truncTxt.substring(0, lastWhiteSpaceIdx) + ending;
        } else {
            return str;
        }
    }
    /**
     * return size in multiple of bytes
     * @param  {Integer} bytes the size to format
     * @param  {String} decimals=2 number of decimal to keep
     * @param  {String} lang='fr' language display
     */
    static formatBytes(bytes, decimals = 2, lang='fr') {
        if (bytes === 0) return lang==='fr' ? '0 octet':'0 Bytes';
    
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = lang === 'fr' ?
            ['octet', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo']
            :['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            ;
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    /**
     * decode html entities
     * @param  {String} str the string to decode
     * @return {String}     the decoded string
     * @see https://www.geeksforgeeks.org/difference-between-unescape-and-escape-functions-in-javascript/
     */
    static decodeHtmlEntities(str) {
        let decoded = _escape(str);
        decoded = decoded.replace(/&#39;/g, "'");
        decoded = decoded.replace(/&quot;/g, '"');
        decoded = decoded.replace(/&amp;/g, '&');
        decoded = decoded.replace(/&lt;/g, '<');
        decoded = decoded.replace(/&gt;/g, '>');
        decoded = decoded.replace(/&nbsp;/g, ' ');
        decoded = decoded.replace(/&#x27;/g, "'");
        decoded = decoded.replace(/&#x2F;/g, '/');
        decoded = decoded.replace(/&#x3D;/g, '=');
        decoded = decoded.replace(/&#x3A;/g, ':');
        decoded = decoded.replace(/&#x3B;/g, ';');
        decoded = decoded.replace(/&#x3C;/g, '<');
        decoded = decoded.replace(/&#x3E;/g, '>');
        decoded = decoded.replace(/&#x3F;/g, '?');
        decoded = decoded.replace(/&#x5B;/g, '[');
        decoded = decoded.replace(/&#x5D;/g, ']');
        decoded = decoded.replace(/&#x5C;/g, '\\');
        decoded = decoded.replace(/&#x7B;/g, '{');
        decoded = decoded.replace(/&#x7D;/g, '}');
        decoded = decoded.replace(/&#x60;/g, '`');
        decoded = decoded.replace(/&#x7E;/g, '~');
        decoded = decoded.replace(/&#x5E;/g, '^');
        decoded = decoded.replace(/&#x7C;/g, '|');
        decoded = decoded.replace(/&eacute;/g, 'é');
        decoded = decoded.replace(/&egrave;/g, 'è');
        decoded = decoded.replace(/&ecirc;/g, 'ê');
        decoded = decoded.replace(/&euml;/g, 'ë');
        decoded = decoded.replace(/&agrave;/g, 'à');
        decoded = decoded.replace(/&acirc;/g, 'â');
        decoded = decoded.replace(/&auml;/g, 'ä');
        decoded = decoded.replace(/&ccedil;/g, 'ç');
        decoded = decoded.replace(/&ocirc;/g, 'ô');
        decoded = decoded.replace(/&ugrave;/g, 'ù');
        decoded = decoded.replace(/&ucirc;/g, 'û');
        decoded = decoded.replace(/&uuml;/g, 'ü');
        decoded = decoded.replace(/&aring;/g, 'å');
        decoded = decoded.replace(/&aelig;/g, 'æ');
        decoded = decoded.replace(/&Agrave;/g, 'À');
        decoded = decoded.replace(/&Acirc;/g, 'Â');
        decoded = decoded.replace(/&Auml;/g, 'Ä');
        decoded = decoded.replace(/&Ccedil;/g, 'Ç');
        decoded = decoded.replace(/&Egrave;/g, 'È');
        decoded = decoded.replace(/&Eacute;/g, 'É');
        decoded = decoded.replace(/&Ecirc;/g, 'Ê');
        decoded = decoded.replace(/&Euml;/g, 'Ë');
        decoded = decoded.replace(/&Igrave;/g, 'Ì');
        decoded = decoded.replace(/&Iacute;/g, 'Í');
        decoded = decoded.replace(/&Icirc;/g, 'Î');
        decoded = decoded.replace(/&Iuml;/g, 'Ï');
        decoded = decoded.replace(/&Ograve;/g, 'Ò');
        decoded = decoded.replace(/&Oacute;/g, 'Ó');
        decoded = decoded.replace(/&Ocirc;/g, 'Ô');
        decoded = decoded.replace(/&Otilde;/g, 'Õ');
        decoded = decoded.replace(/&Ouml;/g, 'Ö');
        decoded = decoded.replace(/&Ugrave;/g, 'Ù');
        decoded = decoded.replace(/&Uacute;/g, 'Ú');
        decoded = decoded.replace(/&Ucirc;/g, 'Û');
        decoded = decoded.replace(/&Uuml;/g, 'Ü');
        decoded = decoded.replace(/&Yacute;/g, 'Ý');
        decoded = decoded.replace(/&Yuml;/g, 'Ÿ');

        return decoded;
    }

/**
 * The `formatDuration` function formats a duration in either seconds or minutes into a string
 * representation of hours, minutes, and seconds.
 * @param duration - The duration parameter represents the duration of time in seconds.
 * @param [type=min] - The `type` parameter is used to specify the format in which the duration should
 * be displayed. It has a default value of `'min'`, which means the duration will be formatted in
 * minutes. If `type` is set to `'s'`, the duration will be formatted in hours, minutes,
 * @returns The function `formatDuration` returns a formatted duration string based on the input
 * duration and type.
 */
    static formatDuration(duration, type='min') {
        if(typeof duration != 'number') { return duration;}
        if(type == 's') {
            const hours = Math.floor(duration / 3600);
            const minutes = Math.floor((duration % 3600) / 60);
            const remainingSeconds = Math.floor(duration % 60);

            const formattedHours = hours !=0 ? String(hours).padStart(2, '0') +':':'';
            const formattedMinutes = minutes !==0 ? String(minutes).padStart(2, '0'):'00';
            const formattedSeconds = String(remainingSeconds).padStart(2, '0');

            const formattedDuration = formattedHours + formattedMinutes + ':' + formattedSeconds;

            return formattedDuration;

        } else {
            return duration/60 <1 ? 
                    duration.toString().concat("min")
                    : Math.floor(duration/60).toString().concat("h",('00'+(duration%60)).slice(-2))
        }
    }

    static calculateDurationInMins(start, end) {
        const startDate= new Date(start);
        const endDate= new Date(end);
        const msInMinute = 60 * 1000;
        return Math.round(
          Math.abs(endDate - startDate) / msInMinute
        );
    }


    /* A function that returns true if the user has a lastname or firstname and no invite token. */
    static isUserConfirmed = (user => {
        return (user.lastname || user.firstName) && !user.invite_token;
    })

    static convertAppVersionForSort = (appVersion) => {
        const CONV = {'N/A':-1, 'NaN':-2, 'null':-999};
        if (appVersion) {
            if (appVersion === 'N/A') return CONV['N/A'];
            if(Number(appVersion) != appVersion) return CONV['NaN'];
            else return appVersion;
        }
        else return CONV['null'];
    }

    /**
     * asynchronous wait
     * 
     * @param {time} time in ms
     * 
     * usage: await HelperMetiers360.wait(3000)
     */
    static wait = (time) => {
        return new Promise(resolve => {
         setTimeout(() => resolve(), time)
        })
     }

    /**
    * Sorting the text after testing the existence of the key.
    * @param {Object} a
    * @param {Object} b 
    * @param {String} key
    */
    static sortTextAfterTestExistence = (a,b,key) => {
        if (!a[key] && !b[key])
            return 0
        else if (!a[key])
            return 1
        else if (!b[key])
            return -1
        return a[key].localeCompare(b[key])

    }
    /* The `isObjectEmpty` method is a static method of the `HelperMetiers360` class that takes an
    object as an argument and checks if it is empty or not. It returns a boolean value indicating
    whether the object is empty or not. It does this by checking if the object is truthy, has no
    keys, and has a constructor of `Object`. If all of these conditions are true, then the method
    returns `true`, indicating that the object is empty. Otherwise, it returns `false`. */
    static isObjectEmpty = (objectName) => {
        return (
          objectName &&
          Object.keys(objectName).length === 0 &&
          objectName.constructor === Object
        );
      };
/* The `checkVideoFormatSupport` method is a static method of the `HelperMetiers360` class. It takes a
`videoFormat` parameter as input. */
    static checkVideoFormatSupport = (videoFormat) => {
        const video = document.createElement('video');
        const canPlayWebM = video.canPlayType('video/'+videoFormat);
        
        if (canPlayWebM === 'probably' || canPlayWebM === 'maybe') {
            // WebM is supported
            return true;
        } else {
            // WebM is not supported
            return false;
        }
    }

    /**
     * return number indicating whether the 1st concat strings of array is before, after or the same as the second
     * @method localeCompareWithNullable
     * @param {string} a first string to compare.
     * @param {string} b second string to compare.
     * @return {number} Returns -1 if a is before b. 1 if it's after and 0 if there are the same.
     */
    static localeCompareWithNullable = (a, b) => {
        if(a !== null && a !== undefined && (b === null || b === undefined)) {
            return -1;
        } else if((a === null || a === undefined) && b !== null && b !== undefined) {
            return 1;
        } else if ((a === null || a === undefined) && (b === null || b === undefined)) {
            return 0;
        } else {
            return a.localeCompare(b);
        }
    }

    /**
     * compare two int or float even if one of them is null or undefined
     * @method compareWithNullable
     * @param {int|float} a first number to compare.
     * @param {int|float} b second number to compare.
     * @return {number} Returns -1 if the a is before b. 1 if it's after and 0 if there are the same.
     */
    static compareWithNullable = (a, b) => {
        if(a !== null && a !== undefined && (b === null || b === undefined)) {
            return -1;
        } else if((a === null || a === undefined) && b !== null && b !== undefined) {
            return 1;
        } else if ((a === null || a === undefined) && (b === null || b === undefined)) {
            return 0;
        } else {
            return b - a;
        }
    }

    /**
     * return number indicating whether the 1st concat strings of array is before, after or the same as the second
     * @method sortStringArray
     * @param {Array<string>} arrayA first array of strings to compare.
     * @param {Array<string>} arrayB second array of strings to compare.
     * @return {number} Returns -1 if the result string of arrayA is before that of arrayB. 1 if it's after and 0 if there are the same.
     */
    static sortStringArray = (arrayA, arrayB) => {
        const a = arrayA?.length > 0 ? arrayA?.join(", ") : '';
        const b = arrayB?.length > 0 ? arrayB?.join(", ") : '';
        return this.localeCompareWithNullable(a, b);
    }

    /**
     * return boolean indicating if textSearch appears in textTest
     * @method isSearchInText
     * @param {string} textSearch research text
     * @param {string} textTest text to be tested
     * @return {boolean} Returns true if textSearch appears in textTest, false if not
     */
    static isSearchInText = (textSearch, textTest) => {
        if(textSearch === "") {
            return true;
        } else if(textSearch !== null && textSearch !== undefined && textTest !== null && textTest !== undefined
            && typeof textSearch === 'string' && typeof textTest === 'string') {
            return textTest?.toLowerCase().includes(textSearch?.toLowerCase())
                // To ignore language accents
                || textTest?.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(textSearch?.toLowerCase())
        } else {
            return false;
        }
    }

    /**
     * return boolean indicating if arrayFieldName of array contains value not null and not undefined
     * @method isArrayContainsValue
     * @param {Array} array array of objects
     * @param {string} arrayFieldName name of field to test
     * @return {boolean} Returns true if arrayFieldName of array contains value not null and not undefined, false if contains null or undefined
     */
    static isArrayContainsValue = (array, arrayFieldName) => {
        return array?.some(arrayItem => arrayItem[arrayFieldName] !== undefined 
            && arrayItem[arrayFieldName] !== null
            && (!Array.isArray(arrayItem[arrayFieldName]) || arrayItem[arrayFieldName].length > 0));
    }
 
    /**
     * return string capitalized
     * @method capitalize
     * @param {string} text text to capitalize
     * @return {string} Returns text capitalized
     */
    static capitalize = (text) => {
        return text 
            ? text[0]?.toUpperCase() + text?.slice(1)
            : "";
    }

    /**
     * return string with first letter capitalized and other letters lower case
     * @method capitalizeFirstLetter
     * @param {string} text text to capitalize first letter
     * @return {string} Returns text capitalized on first letter
     */
    static capitalizeFirstLetter = (text) => {
        return text 
            ? text[0]?.toUpperCase() + text?.slice(1).toLowerCase()
            : "";
    }

    /**
     * return string camelcase
     * @method toCamelcase
     * @param {string} text text to camelcase
     * @return {string} Returns text with camelcase
     */
    static toCamelcase = (text) => {
        if (text) {
            const texts = text.split(' ');
            return texts.map(text => this.capitalizeFirstLetter(text)).join(' ')
        }
        return "";
    }

    /**
     * format input value to uppercase
     * @method toInputUppercase
     * @param {Event} event event from input
     */
    static toInputUppercase = (event) => {
        event.target.value = event.target.value.toUpperCase();
    }
    /**
     * format input value to camelcase
     * @method toInputCamelcase
     * @param {Event} event event from input
     */
    static toInputCamelcase = (event) => {
        event.target.value = this.toCamelcase(event.target.value);
    }
    /**
     * format input value to lowercase
     * @method toInputLowercase
     * @param {Event} event event from input
     */
    static toInputLowercase = (event) => {
        event.target.value = event.target.value.toLowerCase();
    }

    /**
     * return string corresponding to RIASEC letter
     * @method riasecLabel
     * @param {string} letter letter of RIASEC
     * @return {string} Returns text corresponding
     */
    static riasecLabel = (letter) => {
        switch (letter) {
            case 'R':
                return 'Réaliste';
            case 'I':
                return 'Investigateur';
            case 'A':
                return 'Artiste';
            case 'S':
                return 'Social';
            case 'E':
                return 'Entrepreneur';
            case 'C':
                return 'Conventionnel';
            default:
                return 'Non défini';
        }
    };

    /**
     * return string corresponding to category of working conditions
     * @param {string} category category of working conditions
     * @return {string} Returns text corresponding
     */
    static categoryWorkingConditionsLabel = (category) => {
        switch (category) {
            case 'CONDITIONS_TRAVAIL':
                return "Conditions de travail et risques professionnels";
            case 'HORAIRE_ET_DUREE_TRAVAIL':
                return "Horaires et durée du travail";
            case 'TYPE_STRUCTURE_ACCUEIL':
                return "Types de structures";
            case 'TYPE_BENEFICIAIRE':
                return "Publics spécifiques";
            case 'LIEU_ET_DEPLACEMENT':
                return "Lieux et déplacements";
            case 'STATUT_EMPLOI':
                return "Statuts d'emploi";
            default:
                return category;
        }
    }


    /**
     * return string corresponding to transversal prop
     * @param {string} transversalProp transversalProp label in DB
     * @return {string} Returns text corresponding
     */
    static transversalPropLabel = (transversalProp) => {
        switch (transversalProp) {
            case 'areasOfInterest':
                return "Centre d'intérêt";
            case 'workingConditions':
                return 'Contexte de travail';
            case 'skills':
                return 'Savoir-être professionnel';
            case 'largeProfessionalFields':
                return 'Domaine professionnel';
            default:
                return 'Non défini';
        }
    };
}