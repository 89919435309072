import React from 'react';
import { useStoreState } from 'easy-peasy';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { OverlayTrigger, Tooltip, ListGroup, Button } from "react-bootstrap";

import DynamicTable from '../widgets/dynamicTable/DynamicTable';

import HelperMetiers360 from '../../services/HelpersMetiers360';
import useCustomGetStoreState from '../../hooks/useCustomGetStoreState';

/**
 * 
 * @param {Array} Playlists array of all playlists to be rendered
 * @param {String} readAll  readAll slug to access the playlist list page
 
 * @example
 *  <PlaylistTableComponent playlists={playlists} readAll={readAllSlug} create={createSlug} {...}/>
 * 
 */

const PlaylistsTableComponent = ({ playlists, withParams }) => {

    const playlistsToDisplay = playlists ? playlists : [];

    const {allVideos} = useCustomGetStoreState("videos");
    const {allQuizzes} = useCustomGetStoreState("quizzes");
    const {allHeadsets} =useCustomGetStoreState("headsets")

    const videoSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('videos'));
    const quizSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('quizzes'));
    const clientSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('clients'));
    const playlistSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('playlists'));

    const getHeadsetslistSize = (playlist) => playlist.headsets ? playlist.headsets.length : 0;
    const navigate = useNavigate();
    const location = useLocation();

    if (playlists.length === 0) { return <></>; }


    const sortType = [
        {
            value: 'name', label: 'Nom',
            test: playlistsToDisplay[0].name !== undefined,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.name, b.name),
            display: (playlist) => playlist.name
        },
        {
            value: 'client', label: 'Établissement',
            test: playlistsToDisplay[0].client !== undefined,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.client.name, b.client.name),
            display: (playlist) => {
                if (!playlist.client.name) {
                    return '-';
                }
                return clientSlugs && clientSlugs.readOne ?
                    <Link to={clientSlugs.readOne.replace(':uniqueId', playlist.client.uniqueId)}
                        onClick={(e) => e.stopPropagation()}
                        key={'client-' + playlist.client.uniqueId}>
                            {playlist.client.name}</Link>
                    : <>{playlist.client.name}</>
            },
            flatDisplay : (playlist) => playlist?.client?.name ? playlist.client.name:'-'
        },
        {
            value: 'owner', label: 'Créée par',
            test: playlistsToDisplay[0].owner !== undefined,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.owner.username, b.owner.username),
            display: (playlist) =>  playlist.owner.username
        },
        {
            value: 'headsets', label: 'Casques',
            test: playlistsToDisplay[0].headsets !== undefined,
            method: (a, b) => getHeadsetslistSize(b) - getHeadsetslistSize(a),
            display: (playlist) => {
                if (!playlist.headsets || !playlist.headsets.length) {
                    return '-'
                }
                const headsetList = allHeadsets
                    .filter(headset => playlist.headsets.includes(headset.uniqueId))
                    .map((headset) => <ListGroup.Item key={'headset-' + headset.uniqueId}>{headset.deviceId ?? headset.deviceToken}</ListGroup.Item>);

                return (
                    <OverlayTrigger
                        placement='left'
                        overlay={
                            <Tooltip>
                                <ListGroup className='text-primary text-left'>{headsetList}</ListGroup>
                            </Tooltip>
                        }
                    >
                        <Button variant='link' className='text-primary'>{playlist.headsets.length}</Button>
                    </OverlayTrigger>
                )
            },
            flatDisplay: (playlist) => playlist?.headsets?.length
        },
        {
            value: 'videos', label: 'Expériences immersives',
            test: playlistsToDisplay[0].videos !== undefined,
            method: (a, b) => a.videos.length - b.videos.length,
            display: (playlist) => {
                if (playlist.videos.length === 0) {
                    return 0;
                }
                const listOfVideos = allVideos.filter(video => playlist.videos.includes(video.uniqueId)).map((video) =>
                    <ListGroup.Item as={Link} to={videoSlugs.readOne.replace(':uniqueId', video.uniqueId)} 
                        key={'video-' + video.uniqueId}>{video.videoName}</ListGroup.Item>
                );
                return (
                    <OverlayTrigger
                        onClick={(e) => e.stopPropagation()} 
                        trigger={['click']}
                        placement='right'
                        overlay={<Tooltip>
                            <ListGroup className='text-primary text-left'>{listOfVideos}</ListGroup>
                        </Tooltip>}
                        rootClose
                    >
                        <Button onClick={(e) => e.stopPropagation()} >{listOfVideos.length}</Button>
                    </OverlayTrigger>
                )
            },
            flatDisplay: (playlist) => playlist?.videos?.length
        },
        {
            value: 'quizzes', label: 'Quizzes',
            test: playlistsToDisplay[0].quizzes !== undefined,
            method: (a, b) => a.quizzes.length - b.quizzes.length,
            display: (playlist) => {
                if (playlist.quizzes.length === 0) {
                    return 0;
                }
                const listOfQuizzes = allQuizzes.filter(quiz => playlist.quizzes.includes(quiz.uniqueId)).map((quiz) =>
                    <ListGroup.Item as={Link} to={quizSlugs.readOne.replace(':uniqueId', quiz.uniqueId)} key={'quiz-' + quiz.uniqueId}>{quiz.name}</ListGroup.Item>
                );
                return (
                    <OverlayTrigger
                        trigger={['click']}
                        placement='right'
                        overlay={<Tooltip>
                            <ListGroup className='text-primary text-left'>{listOfQuizzes}</ListGroup>
                        </Tooltip>}
                        rootClose
                    >
                        <Button onClick={(e) => e.stopPropagation()}>{playlist.quizzes.length}</Button>
                    </OverlayTrigger>
                )
            },
            flatDisplay: (playlist) => playlist?.quizzes?.length
        },
        {
            value: 'createdAt', label: "Publication",
            test : playlistsToDisplay[0].createdAt !== undefined,
            method: (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
            display: (playlist) => HelperMetiers360.getdisplayDateType(playlist.createdAt, 'day')
        },
        {
            value: 'updatedAt', label: "Mise à jour",
            test : playlistsToDisplay[0].updatedAt !== undefined,
            method: (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
            display: (playlist) => HelperMetiers360.getdisplayDateType(playlist.updatedAt, 'day')
        },

    ];

    const handleClick = (selectedId) => {
        navigate(playlistSlugs?.readOne?.replace(':uniqueId', selectedId), { state: { olderSearchParams: location.search } });
    };

    return (
            <DynamicTable
                contentTable={playlistsToDisplay}
                contentSort={sortType}
                valueInitSort="client"
                index='uniqueId'
            withParams={withParams}
            handleClick={handleClick}
                tableName="playlistsTable"
                filename="playlists"
        />
    );
}
export default PlaylistsTableComponent;