import React, { useState } from 'react';
import { useStoreState } from 'easy-peasy';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import Loader from '../../../widgets/Loader.jsx';
import skill_emerging_icon from '../../../../images/skill_emerging_icon.png';
import { Alert } from 'react-bootstrap';

const RomeSkillsTabComponent = ({ rome }) => {

    const { isFecthingOneRome } = useStoreState(state => state.romes);
    const skillsSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher("skills"));
    const oneSkillSlugs = skillsSlugs?.readOne ?? null;

    const [isDomainSkillsOpen, setIsDomainSkillsOpen] = useState(false);
    const [isKnowledgeCategoriesOpen, setIsKnowledgeCategoriesOpen] = useState(false);
    const [isProfessionalSoftSkillsOpen, setIsProfessionalSoftSkillsOpen] = useState(false);

    const skillsFromFT = rome?.detailsSkillsJson;
    const professionalSoftSkillsWithCode = [
        ...(rome.competencesMobilisees ?? []),
        ...(rome.competencesMobiliseesPrincipales ?? []),
        ...(rome.competencesMobiliseesEmergentes ?? [])
    ]?.filter(skill => skill.typeCompetence === 'MACRO-SAVOIR-ETRE-PROFESSIONNEL');

    const displayItem = (item, isLink = false) => {
        const isMainSkill = rome.competencesMobiliseesPrincipales?.some(skill => skill.code === item.code);
        const isEmergingSkill = rome.competencesMobiliseesEmergentes?.some(skill => skill.code === item.code);

        return <li key={item.code} className={isMainSkill ? "fw-bold" : ''}>
            {isLink && oneSkillSlugs
                ? <Link to={oneSkillSlugs.replace(':uniqueId', item.code)} >{item.label}</Link>
                : item.label
            }
            {isEmergingSkill && <img src={skill_emerging_icon} className="ms-2" style={{ maxHeight: '1.5em' }} />}
        </li>
    };

    const domainsSkills = skillsFromFT?.domainsSkills?.length > 0
        ? <>
            <div className="d-flex justify-content-between clickable mb-2 mt-4"
                onClick={() => setIsDomainSkillsOpen(prev => !prev)}>
                <h4>Savoir-faire</h4>
                {isDomainSkillsOpen
                    ? <i className="fa fa-caret-up ms-2"></i>
                    : <i className="fa fa-caret-down ms-2"></i>
                }
            </div>
            {isDomainSkillsOpen && skillsFromFT.domainsSkills.map(domainSkill =>
                domainSkill?.stakes?.length > 0
                && <div key={domainSkill?.code} className='mt-3 ms-3'>
                    <h5>{domainSkill?.label}</h5>
                    {domainSkill.stakes?.map(stake => stake.hardSkills?.length > 0 &&
                        <div key={stake.code} className='ms-4'>
                            <h6>{stake.label}</h6>
                            <ul>
                                {stake.hardSkills.map(hardSkill => displayItem(hardSkill))}
                            </ul>
                        </div>
                    )}
                </div>
            )}
        </>
        : <></>;

    const professionalSoftSkills = professionalSoftSkillsWithCode?.length > 0
        ? <>
            <div className={"d-flex justify-content-between clickable mb-2" + (isDomainSkillsOpen ? " mt-5" : " mt-3")}
                onClick={() => setIsProfessionalSoftSkillsOpen(prev => !prev)}>
                <h4>Savoir-être professionnels <i className="fa fa-user-cog ms-2" /></h4>
                {isProfessionalSoftSkillsOpen
                    ? <i className="fa fa-caret-up ms-2"></i>
                    : <i className="fa fa-caret-down ms-2"></i>
                }
            </div>
            {isProfessionalSoftSkillsOpen && <ul>
                {professionalSoftSkillsWithCode.map(professionalSoftSkill => displayItem(professionalSoftSkill, true))}
            </ul>}
        </>
        : <></>;

    const knowledgeCategories = skillsFromFT?.knowledgeCategories?.length > 0
        ? <>
            <div className={"d-flex justify-content-between clickable mb-2" + (isProfessionalSoftSkillsOpen ? " mt-5" : " mt-3")}
                onClick={() => setIsKnowledgeCategoriesOpen(prev => !prev)}>
                <h4>Savoirs</h4>
                {isKnowledgeCategoriesOpen
                    ? <i className="fa fa-caret-up ms-2"></i>
                    : <i className="fa fa-caret-down ms-2"></i>
                }
            </div>
            {isKnowledgeCategoriesOpen && skillsFromFT.knowledgeCategories.map(knowledgeCategorie =>
                knowledgeCategorie?.knowledges?.length > 0
                && <div key={knowledgeCategorie?.label} className='mt-3 ms-3'>
                    <h5>{knowledgeCategorie?.label}</h5>
                    <ul>
                            {knowledgeCategorie.knowledges.map(knowledge => displayItem(knowledge))}
                        </ul>
                    </div>
            )}
        </>
        : <></>;

    return isFecthingOneRome
        ? <Loader />
        : skillsFromFT
            ? <>
                {domainsSkills}
                {professionalSoftSkills}
                {knowledgeCategories}
            </>
            : <Alert variant='warning'>Aucune compétence</Alert>;
}

RomeSkillsTabComponent.propTypes = {
    rome: PropTypes.object.isRequired
};

export default RomeSkillsTabComponent