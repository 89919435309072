import PropTypes from 'prop-types';
import { Button, ListGroup, ListGroupItem, Spinner } from 'react-bootstrap';
import { useState } from 'react';
import SelectMultipleClientsComponent from '../../../widgets/selectClient/SelectMultipleClientsComponent';
import { useStoreActions, useStoreState } from 'easy-peasy';
import SelectMultipleItemsWithSearchBar from '../../../widgets/selectMultipleItemsWithSearchBar/SelectMultipleItemsWithSearchBar';

/**
 * @param {array} clientUniqueIdsSelected unique id array of child clients to select by default
 * @param {array} selectedUniqueIdParentClient unique id of parent client to select by default
 * @example <MultipleTagOpesCreationComponent clientUniqueIdsSelected={[]} selectedUniqueIdParentClient={""} />
 */
const MultipleTagOpesCreationComponent = ({ clientUniqueIdsSelected, selectedUniqueIdParentClient }) => {
    const { allTagOperations } = useStoreState(state => state.tagOperations);
    const { allClientsCRM } = useStoreState(state => state.clients);
    const { updateClientCRM } = useStoreActions(actions => actions.clients);

    const [requestAnswersWindow, setRequestAnswersWindow] = useState(false)
    const [clientUniqueIds, setClientUniqueIds] = useState(clientUniqueIdsSelected)
    const [selectedTagOpeIds, setSelectedTagOpeIds] = useState([]);
    const [currentSending, setCurrentSending] = useState(null);
    const [logSent, setLogSent] = useState([]);

    const onValidate = () => {
        setRequestAnswersWindow(true);

        const doNextPromise = (key) => {
            const currentClient = allClientsCRM.find((client) => client.uniqueId === clientUniqueIds[key]);

            if (currentClient) {
                setCurrentSending(currentClient.name);
                const clientToSend = {};

                const currentClientTagsIds = currentClient.tagOperations.map(clientTag =>
                    allTagOperations?.find((tag) => tag.uniqueId === clientTag.uniqueId)?.id);

                const mergedTags = [...currentClientTagsIds, ...selectedTagOpeIds];
                clientToSend["tagOperations"] = [...new Set(mergedTags)];

                const payload = {
                    data: clientToSend,
                    uniqueId: currentClient.uniqueId,
                }

                updateClientCRM(payload)
                    ?.then(() => {
                        logSent.push({
                            key,
                            name: currentClient.name,
                            isOK: true,
                            msg: 'Enregistré sur le serveur'
                        });
                    })
                    ?.catch(err => {
                        logSent.push({
                            key,
                            name: currentClient.name,
                            isFailed: true,
                            msg: err
                        });
                    })
                    ?.finally(() => {
                        setLogSent(logSent);
                        setCurrentSending(null);
                        key++;
                        if (key < clientUniqueIds.length) doNextPromise(key)
                    });
            } else {
                logSent.push({
                    key,
                    name: "Inconnu - " + clientUniqueIds[key],
                    isFailed: true,
                    msg: "Client non trouvé"
                });
                setLogSent(logSent);
            }
        };

        doNextPromise(0);
    }

    const logSentDisplay = logSent.map(log => <ListGroupItem key={log.key}>
        Envoi des informations de {log.name}&nbsp;
        {log.isOK && <span className="text-success icon-checkmark"> &nbsp;{log.msg}</span>}
        {log.isFailed && <span className="text-danger icon-cross"> &nbsp;{log.msg}</span>}
    </ListGroupItem>);
    const currentSendingDisplay = currentSending &&
        <ListGroupItem>Envoi en cours des informations de {currentSending}&nbsp;<Spinner size="sm" animation="border" /></ListGroupItem>;

    return !requestAnswersWindow
        ? <>
            <SelectMultipleClientsComponent
                selectedClients={clientUniqueIds}
                setSelectedClients={setClientUniqueIds}
                selectedUniqueIdParentClientDefault={selectedUniqueIdParentClient}
            />
            <SelectMultipleItemsWithSearchBar idSelector="clientCRM-Tags-Select" label="Tag opération M360"
                allItems={allTagOperations}
                selectedItemKeys={selectedTagOpeIds} setSelectedItemKeys={setSelectedTagOpeIds}
                itemKey="id" itemValue="name"
            />
            <Button variant="success" size="sm" className="align-right mt-4 me-4"
                disabled={selectedTagOpeIds.length < 1}
                onClick={onValidate}>
                <span className="icon-checkmark"></span> C'est parti !
            </Button>
        </>
        : <>
            {logSent.length > 0 && <ListGroup>{logSentDisplay}</ListGroup>}
            {currentSending != null && <ListGroup>{currentSendingDisplay}</ListGroup>}
        </>;
}

MultipleTagOpesCreationComponent.propTypes = {
    clientUniqueIdsSelected: PropTypes.array,
    selectedUniqueIdParentClient: PropTypes.string,
}

export default MultipleTagOpesCreationComponent;