import React from "react";


import { useStoreState } from "easy-peasy";

import { useToast } from "../../../hooks/useToast.jsx";


import UploadFilesComponent from "./UploadFilesComponent.jsx";

import "react-datepicker/dist/react-datepicker.css";


const UploadFilesFormComponent = ({ entityName, entity, postFunction, onSuccess }) => {

    const { apiData: { ACTIONS, BACKOFFICE_DEFAULT_VALUES } } = useStoreState(state => state.actionSlugs);

    const { fileContraints } = BACKOFFICE_DEFAULT_VALUES;
    const { csrfToken } = ACTIONS[entityName+"s"]['editFiles'];

	const toast = useToast();

    const { uploadFilesContainer, arrayOfNewFiles, arrayOfFilesToEdit, arrayOfFilesToDelete, replaceAll } = UploadFilesComponent({
		filesMainEntityName: entityName,
		filesToUpdate: entity?.files ?? null,
	});

    const checkData = () => {
		let errorList = {};
		
		let formData = new FormData ();

        for (const element of arrayOfNewFiles) {
            if (fileContraints.FORBIDDEN_NAMES.video?.includes(element.fileName)) {
				errorList.push('Nom de fichiers interdits : ' + fileContraints.FORBIDDEN_NAMES.video?.join(", "));
				break;
			}
            if (element.file && element.fileName == "") {
				errorList.push('Veuillez nommer vos nouveaux fichiers');
				break;
			}
		}

		if (arrayOfFilesToEdit) {
            for (const element of arrayOfFilesToEdit) {
                if (element?.edit == true) {
                    if (fileContraints.FORBIDDEN_NAMES.video?.includes(element.newFile.fileName)) {
						errorList.push('Nom de fichiers interdits : ' + fileContraints.FORBIDDEN_NAMES.video?.join(", "));
						break;
					}
                    if (element.newFile.fileName == "") {
						errorList.push('Veuillez nommer vos fichiers à éditer');
						break;
					}
				}
			}
		}

        if (Object.keys(errorList).length) {
			toast.open({ message: Object.values(errorList)?.join(', '), variant: "danger" });
		}
        else {
            populateFormData(formData);
            postFunction({formData, uniqueId: entity?.uniqueId})
                .then(() => {
					onSuccess?.();
            })
            .catch((error) => {
                    handleBadRequest(error);
            })
        }
	}


	const handleBadRequest = (error) => {
		const errorList = {};
		if (error?.errors) {
			for (const key of Object.keys(error.errors)) {
				errorList[key] = error.errors[key]
			}
		}
		errorList.global = 'Il y a eu un probleme lors de l\'enregistrement de la video';
	}


	const populateFormData = (formData) => {
		let toDelete = arrayOfFilesToDelete;
		if (replaceAll) {
			toDelete = arrayOfFilesToEdit.map((file) => { return file.uniqueId });
		}
		for (let i = 0; i < arrayOfNewFiles?.length; i++) {

			if (arrayOfNewFiles[i].file) {
				let fileName = arrayOfNewFiles[i].fileName;
				if (!arrayOfNewFiles[i].fileName.includes(".")) {
					fileName = arrayOfNewFiles[i].fileName + "." + arrayOfNewFiles[i].fileExtension
				}
				formData.append("files[]", arrayOfNewFiles[i].file, fileName)
			}
		}

        for (const element of toDelete) {
            formData.append("filesToDelete[]", element)
		}

		let filesToEditNewNames = [];

		for (let i = 0; i < arrayOfFilesToEdit?.length; i++) {
			if (arrayOfFilesToEdit[i].edit) {
				let fileName = arrayOfFilesToEdit[i].newFile.fileName;
				if (!arrayOfFilesToEdit[i].newFile.fileName.includes(".")) {
					fileName = arrayOfFilesToEdit[i].newFile.fileName + "." + arrayOfFilesToEdit[i].newFile.fileExtension
				}
				filesToEditNewNames.push(fileName);
				formData.append("filesToEdit[]", arrayOfFilesToEdit[i].uniqueId);
				formData.append("filesToEditNewData[]", arrayOfFilesToEdit[i].newFile.file);
			}
		}

		if (filesToEditNewNames.length > 0) {
			formData.append('filesToEditNewNames', JSON.stringify(filesToEditNewNames));
		}
        
        formData.append("csrfToken", csrfToken);
	}


	return (
		<div>
	  	 {uploadFilesContainer}
           <button 
            id="upload-files-form-submit" 
                className="d-none"
            type="button" 
            onClick={checkData}
        />
		</div>
	);
};

export default UploadFilesFormComponent;
