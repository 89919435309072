import React, { useState, useEffect } from "react";
import { Form, Row, Container, Button, Col, Alert } from "react-bootstrap";
import Loader from "../../../components/widgets/Loader.jsx";
import { useModal } from "../../../hooks/useModal";
import JSZip from "jszip";
import { useStoreState } from "easy-peasy";
import './UploadFilesComponent.scss';
import { useToast } from "../../../hooks/useToast.jsx";

const UploadFilesComponent = (props) => {

    const { filesMainEntityName, filesToUpdate } = props;
    const toast = useToast(); 
    const { apiData: { BACKOFFICE_DEFAULT_VALUES } } = useStoreState(state => state.actionSlugs);
    const { fileContraints } = BACKOFFICE_DEFAULT_VALUES;
    const [arrayOfFilesToEdit, setArrayOfFilesToEdit] = useState([]);
    const [arrayOfNewFiles, setArrayOfNewFiles] = useState([{ fileName: "", fileExtension: "", file: null }]);
    const [arrayOfFilesToDelete, setArrayOfFilesToDelete] = useState([]);
    const [isLoadingFiles, setIsLoadingFiles] = useState(false);
    const [filesErrors, setFilesErrors] = useState([]);
    const [replaceAll, setReplaceAll] = useState(false);


    const { modalComponent, setModalShow, modalData, setModalData } = useModal();


    const addNewFileRow = (e, file) => {
        let initData = { fileName: "", fileExtension: "", file: null, newFileLocalName: "" };
        if (e) {
            e.preventDefault();
        }
        if (file) {
            const fileInfos = file.fileInfos;
            const fileData = file.data;
            initData = {
                fileName: fileInfos.name.substring(0, fileInfos.name.lastIndexOf('.')),
                fileExtension: fileInfos.name.split('.').pop(),
                file: fileData,
                newFileLocalName: fileInfos.name
            };
        }
        arrayOfNewFiles.unshift(initData);
        setArrayOfNewFiles([...arrayOfNewFiles]);
    }


    const addNewFile = (file, index) => {
        setFilesErrors([]);
        const extension = file.name.split('.').pop();
        if (extension == "zip") {
            const zip = new JSZip();
            setIsLoadingFiles(true);
            zip.loadAsync(file).then((contents) => {
                removeNewFileRow(index);
                const arrayOfNewFilesInitLength = arrayOfNewFiles.length;
                const zippedFilesLength = Object.keys(contents.files).length;
                if (zippedFilesLength == 0) {
                    setIsLoadingFiles(false);
                }
                else {
                    Object.keys(contents.files).forEach((filename) => {
                        contents.files[filename].async("blob").then((fileData) => {
                            addNewFileRow(null, { fileInfos: contents.files[filename], data: fileData });
                        }).catch((error) => {
                            filesErrors.push(filename);
                            setFilesErrors([...filesErrors])
                        }).finally(() => {
                            if (((arrayOfNewFiles.length + filesErrors.length) - arrayOfNewFilesInitLength) == zippedFilesLength) {
                                setIsLoadingFiles(false);
                            }
                        });
                    });
                }
            });
        }
        else {
            arrayOfNewFiles[index].file = file;
            arrayOfNewFiles[index].fileName = file.name.substring(0, file.name.lastIndexOf('.'));
            arrayOfNewFiles[index].fileExtension = extension;
            arrayOfNewFiles[index].newFileLocalName = file.name;
            setArrayOfNewFiles([...arrayOfNewFiles]);
        }
    }


    const removeNewFileRow = (index) => {
        arrayOfNewFiles.splice(index, 1);
        setArrayOfNewFiles([...arrayOfNewFiles]);
    }


    const renameNewFile = (name, index) => {
        arrayOfNewFiles[index].fileName = name;
        setArrayOfNewFiles([...arrayOfNewFiles]);
    }


    const deleteFile = (uniqueId, index) => {
        arrayOfFilesToEdit.splice(index, 1);
        arrayOfFilesToDelete.push(uniqueId);
        setArrayOfFilesToEdit([...arrayOfFilesToEdit]);
        setArrayOfFilesToDelete([...arrayOfFilesToDelete]);
    }


    const deleteAllFiles = () => {
        for (let i = 0; i < arrayOfFilesToEdit.length; i++) {
            if (!arrayOfFilesToDelete.includes(arrayOfFilesToEdit[i].uniqueId)) {
                arrayOfFilesToDelete.push(arrayOfFilesToEdit[i].uniqueId);
            }
        }
        setArrayOfFilesToDelete([...arrayOfFilesToDelete]);
        setArrayOfFilesToEdit([]);
    }


    const deleteAllNewFiles = () => {
        setArrayOfNewFiles([{ fileName: "", fileExtension: "", file: null, newFileLocalName: "" }]);
    }

    const shouldEditFile = (e, index, toEdit) => {
        e.preventDefault();
        arrayOfFilesToEdit[index].edit = toEdit;
        setArrayOfFilesToEdit([...arrayOfFilesToEdit]);
    }


    const updateFile = (file, index) => {
        const extension = file.name.split('.').pop();
        arrayOfFilesToEdit[index].newFile.file = file;
        arrayOfFilesToEdit[index].newFile.fileName = file.name.substring(0, file.name.lastIndexOf('.'));
        arrayOfFilesToEdit[index].newFile.fileExtension = extension;
        arrayOfFilesToEdit[index].newFile.newFileLocalName = file.name;
        setArrayOfFilesToEdit([...arrayOfFilesToEdit]);
    }


    const renameUpdatedFile = (name, index) => {
        arrayOfFilesToEdit[index].newFile.fileName = name;
        setArrayOfFilesToEdit([...arrayOfFilesToEdit]);
    }


    const confirmDeleteModal = (uniqueId, name, index) => {
        setModalData({
            ...modalData,
            header: <>Suppression des fichiers</>,
            content: <h5>Êtes-vous sûr⋅e de vouloir supprimer {uniqueId ? "le fichier " + name : "tous les fichiers "} ?</h5>,
            resetButton: "Supprimer",
            cancelButton: 'Annuler',
            onReset:
                () => {
                    if (uniqueId) {
                        deleteFile(uniqueId, index);
                    }
                    else {
                        deleteAllFiles();
                    }
                    setModalShow(false);
                }
        });
        setModalShow(true);
    }


    const confirmReplaceAll = (e) => {
        e.preventDefault();
        setModalData({
            ...modalData,
            header: <>Suppression des fichiers</>,
            content: <h5>Êtes-vous sûr⋅e de vouloir remplacer tous les fichiers ?</h5>,
            resetButton: "Valider",
            cancelButton: 'Annuler',
            onReset:
                () => {
                    setReplaceAll(!replaceAll);
                    setModalShow(false);
                },
            onCancel:
                () => {
                    setModalShow(false);
                }
        });
        setModalShow(true);
    }



    const confirmDeleteNewModal = () => {
        setModalData({
            ...modalData,
            header: <>Suppression des fichiers</>,
            content: <h5>Êtes-vous sûr⋅e de vouloir supprimer tous les fichiers ?</h5>,
            resetButton: "Supprimer",
            cancelButton: 'Annuler',
            onReset: () => {
                deleteAllNewFiles();
                setModalShow(false);
            },
            onCancel: () => setModalShow(false)
        });
        setModalShow(true);
    }


    const onDownloadFile = (url) => {
        window.open(url);
    }

    useEffect(() => {
        if (filesToUpdate) {
            const arrayOfFiles = filesToUpdate
                .map((fileToUpdate) => {
                    return {
                        ...fileToUpdate,
                        edit: false,
                        newFile:
                        {
                            fileName: fileToUpdate.localName.substring(0, fileToUpdate.localName.lastIndexOf('.')),
                            fileExtension: fileToUpdate.localName.split('.').pop(),
                            newFileLocalName: "",
                            file: null
                        }
                    }
                }
                )
            setArrayOfFilesToEdit(arrayOfFiles);
        }
    }, [filesToUpdate])




    const getWarningMessageSpecificWords = () => {
        switch (filesMainEntityName) {
            case 'group':
                return 'du groupe';
            case 'video':
                return "de la video";
        }
    }


    const newFilesRow = arrayOfNewFiles?.map((file, index) => {
        return (
            <Row className="mb-5 text-center" key={`filerow${index}`}>
                <Row >
                    <Col sm={12} md={11}>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id={"inputGroupMultiFileAddon" + index + 1}>
                                    Choisir un fichier
                                </span>
                            </div>
                            <div className="custom-file">
                                <input
                                    type="file"
                                    className={"custom-file-input"}
                                    id={"inputGroupFileAddon" + index + 1}
                                    aria-describedby={"inputGroupFileAddon" + index + 1}
                                    onChange={(e) => addNewFile(e?.target?.files[0], index)}
                                />
                                <label className="custom-file-label" htmlFor={"inputGroupFileAddon" + index + 1} data-browse="Parcourir">
                                    {arrayOfNewFiles[index]?.file ? arrayOfNewFiles[index]?.newFileLocalName : "Choisissez un fichier"}
                                </label>
                            </div>

                        </div>
                        <Row>{arrayOfNewFiles[index].file &&

                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" >
                                        Modifier le nom
                                    </span>
                                </div>
                                <div className="custom-file">
                                    <Form.Control
                                        isInvalid={fileContraints.FORBIDDEN_NAMES[filesMainEntityName]?.includes(arrayOfNewFiles[index]?.fileName)}
                                        onChange={(e) => renameNewFile(e.target.value, index)}
                                        value={arrayOfNewFiles[index]?.fileName}
                                    />
                                    <Form.Control.Feedback type='invalid'>Nom du fichier interdit</Form.Control.Feedback>
                                </div>
                            </div>
                        }
                        </Row>
                    </Col>
                    <Col sm={12} md={1}>
                        <i className="fas fa-trash-alt delete-file cursor-pointer" onClick={(e) => removeNewFileRow(index)}></i>
                    </Col>
                </Row>
            </Row>
        )
    });


    const filesErrorsContent = filesErrors?.map((fileName, i) => {
        return (
            <span key={`${fileName}${i}`}>{fileName}</span>
        )
    });



    const filesToUpdateRow = arrayOfFilesToEdit?.map((file, index) => {
        return (
            <Row className="file-rows-container w-100" key={`filemajrow${index}`}>
                <Row >
                    <Col className="mb-5 text-center" sm={12} md={10}>
                        <Row className="file-rows-container w-100" >
                            <Button variant="primary" onClick={() => { onDownloadFile(file.url) }}>
                                <i className="fas fa-download"></i> &nbsp;{file.localName}
                            </Button>
                        </Row>
                        {file.edit &&
                            <Row className="mt-3 w-100" >
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id={"inputGroupMultiFileEditon" + index + 1}>
                                            Modifier le fichier
                                        </span>
                                    </div>
                                    <div className="custom-file">
                                        <input
                                            type="file"
                                            className={"custom-file-input"}
                                            id={"inputGroupMultiFileEditon" + index + 1}
                                            aria-describedby={"inputGroupMultiFileEditon" + index + 1}
                                            onChange={(e) => updateFile(e?.target?.files[0], index)}
                                        />
                                        <label className="custom-file-label" htmlFor={"inputGroupMultiFileEditon" + index + 1} data-browse="Parcourir">
                                            {file.newFile.file ? file.newFile.newFileLocalName : "Choisissez un fichier"}
                                        </label>
                                    </div>

                                </div>

                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" >
                                            Modifier le nom
                                        </span>
                                    </div>
                                    <div className="custom-file">
                                        <Form.Control
                                            isInvalid={fileContraints.FORBIDDEN_NAMES[filesMainEntityName]?.includes(file.newFile?.fileName)}
                                            onChange={(e) => renameUpdatedFile(e.target.value, index)}
                                            value={file.newFile?.fileName}
                                        />
                                        <Form.Control.Feedback type='invalid'>Nom du fichier interdit</Form.Control.Feedback>
                                    </div>
                                </div>
                                <Row>
                                    <a href="#" className="text-decoration-underline" onClick={(e) => shouldEditFile(e, index, false)}>Annuler la modification</a>
                                </Row>
                            </Row>
                        }
                    </Col>
                    <Col sm={12} md={1} >
                        <i className="fas fa-edit edit-file cursor-pointer" onClick={(e) => shouldEditFile(e, index, true)}></i>
                    </Col>
                    <Col sm={12} md={1} >
                        <i className="fas fa-trash-alt delete-file cursor-pointer" onClick={(e) => confirmDeleteModal(file.uniqueId, file.localName, index)}></i>
                    </Col>
                </Row>
            </Row>
        )
    });


    const uploadFilesContainer =
        <Container fluid >
            {!isLoadingFiles ?
                <>

                    <Row >
                        {arrayOfFilesToEdit?.length > 0 &&
                            <Row className="text-center w-100" >
                                <h3 className="text-center">Nouveaux fichiers</h3>
                            </Row>
                        }
                        <Row className="mb-5 d-flex flex-row justify-content-end">
                            {arrayOfNewFiles?.length > 1 &&
                                <Button variant="danger" className="w-auto me-2" onClick={(e) => confirmDeleteNewModal()}>&nbsp;Tout supprimer</Button>
                            }
                            <Button variant="success" className="w-auto" onClick={(e) => addNewFileRow(e, null)}><i className="fas fa-plus"></i> &nbsp;Ajouter</Button>
                        </Row>
                        <Row className="file-rows-container w-100" >
                            {newFilesRow}
                            {arrayOfFilesToEdit?.length > 0 && arrayOfNewFiles.find((file) => { return file.file !== null }) !== undefined &&
                                <Row className="mb-5">
                                    <Form.Check
                                        type="checkbox"
                                        className="type-checkbox"
                                        label="Remplacer tous les fichiers actuels par les nouveaux"
                                        id={`newfile-replace-all`}
                                        checked={replaceAll}
                                        onChange={(e) => e.target.checked ? confirmReplaceAll(e) : setReplaceAll(false)}
                                    />
                                </Row>
                            }
                            {filesErrors?.length > 0 &&
                                <Row>
                                    <Alert variant="danger">Un problème est survenu avec certains fichiers : {filesErrorsContent}</Alert>
                                </Row>
                            }
                        </Row>
                    </Row>





                    {arrayOfFilesToEdit?.length > 0 &&
                        <Row className="file-rows-container w-100">
                            <hr />
                            <Row className="text-center w-100" >
                                <h3 className="text-center">Fichiers actuels</h3>
                            </Row>
                            <Row className="mb-5 mt-3 w-100" >
                                <Col className="d-flex justify-content-end" >
                                    <Button variant="danger" onClick={(e) => confirmDeleteModal()}>&nbsp;Tout supprimer</Button>
                                </Col>
                            </Row>
                            <Row className="file-rows-container w-100" >
                                <Row className="w-100">
                                    {filesToUpdateRow}
                                </Row>

                            </Row>
                        </Row>
                    }

                    {modalComponent}
                </>
                : <Loader />
            }

        </Container>;

    return { uploadFilesContainer, arrayOfNewFiles, arrayOfFilesToEdit, arrayOfFilesToDelete, replaceAll, setFilesErrors }
}

export default UploadFilesComponent;