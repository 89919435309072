import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useStoreState } from 'easy-peasy';

import { OverlayTrigger, Tooltip, Image } from "react-bootstrap";

import DynamicTableInfinite from "../../../widgets/dynamicTableInfinite/DynamicTableInfinite.jsx";
import HelperMetiers360 from "../../../../services/HelpersMetiers360.js";

import TooltipListComponent from "../../../widgets/tooltipList/TooltipListComponent.jsx";
import useInfiniteLoadingLogic from "../../../../hooks/useInfiniteLogic.jsx";
import useCustomGetStoreState from "../../../../hooks/useCustomGetStoreState.jsx";
import './StoryTableComponent.scss';


const StoryTableComponent = ({stories, withParams}) => {

    const navigate = useNavigate();
    const location = useLocation();

    const [sortedStories, setSortedStories] = useState([]);
    const [storiesToDisplay, setStoriesToDisplay] = useState([]);
    const [shouldSort, setShouldSort] = useState(false);

    useEffect(() => {
        setShouldSort(true);
        setSortedStories([...stories]);

    }, [stories]);

    const {hasMore, fetchNext: fetchNextStories} = useInfiniteLoadingLogic(sortedStories, storiesToDisplay, setStoriesToDisplay, 100);
    
    const storySlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('stories'));
    const { allSequences, isFetchingSequences } = useCustomGetStoreState("sequences");

    const producersName = (producers) => producers.map((producer) => producer.clientName ).map(String)?.join(', ');

    const codeRome = (romes) => romes?.join(', ');


    const labelVR='VR360';

    const getListSequences = (story) => {
        return allSequences.filter(sequence => {
            return sequence.stories.includes(story.uniqueId)
        })
    }

    if (stories.length === 0 )
        {return <></>;}

    const sortType = [
        {
            value: 'thumbnail', label: 'Vignette',
            test: stories[0].links.thumbnail !== undefined,
            method: null,
            display: (story) => (
                <OverlayTrigger
                    placement="right"
                    overlay={
                        <Tooltip>
                            <Image src={story.links.thumbnail[128]}/>
                        </Tooltip>
                    }    
                >
                    <Image src={story.links.thumbnail[512]} className="img-overlay" thumbnail />
                </OverlayTrigger>
            ),
            flatDisplay: (story) => story.links.thumbnail[512]
        },
        {
            value: 'title', label: "Nom",
            test : stories[0].name !== undefined,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.name, b.name),
            display: (story) => story.name
        },
        {
            value: 'sequences', label: 'Parcours',
            test: stories[0].isUsedInSequence !== 'undefined',
            method: (a, b) => getListSequences(b).length - getListSequences(a).length,
            display: (story) => {
                if (!story.isUsedInSequence) {
                    return 0
                }
                
                const listOfSequences = getListSequences(story);

                const generateIdCallback = (uniqueId) => {
                    return `sequence-${uniqueId}${story.uniqueId}`;
                }

                return <TooltipListComponent
                    inputList={listOfSequences}
                    keyToDisplay='name'
                    generateIdCallback={generateIdCallback}
                    isFetching={isFetchingSequences}
                 />
            },
            flatDisplay: (story) => getListSequences(story).length
        },
        {
            value: 'position', label: "Position",
            test : stories[0].position !== undefined,
            method: (a, b) => b.position - a.position,
            display: (story) => story.position
        },
        {
            value: 'type', label: "Media",
            test : stories[0].type !== undefined,
            method: (a, b) => {const aType = a.is360 ? labelVR:a.type; return aType.localeCompare(b.is360 ? labelVR:b.type);},
            display: (story) => story.is360 ? labelVR:story.type
        },
        {
            value: 'fromVideo', label: "Extrait de",
            test : stories[0].fromVideo !== undefined,
            method: (a, b) => {const aFromVideo = a.fromVideo ? a.fromVideo:'-'; return aFromVideo.localeCompare(b.fromVideo ? b.fromVideo:'-');},
            display: (story) => story.fromVideo ? story.fromVideo:'-'
        },
        {
            value: 'date', label: "Publication",
            test : stories[0].releaseDate !== undefined,
            method: (a, b) => new Date(b.releaseDate) - new Date(a.releaseDate),
            display: (story) => HelperMetiers360.getdisplayDateType(story.releaseDate, 'day')
        },
        {
            value: 'lastUpdated', label: "Mise à jour",
            test : stories[0].lastUpdated !== undefined,
            method: (a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated),
            display: (story) => HelperMetiers360.getdisplayDateType(story.lastUpdated, 'day')
        },
        {
            value: 'producers', label: "Producteur",
            test : stories[0].producers !== undefined,
            method: (a, b) => {const aProducers = a.producers.length ? producersName(a.producers):'-'; return aProducers.localeCompare(b.producers.length ? producersName(b.producers):'-');},
            display: (story) => story.producers.length ? producersName(story.producers) : '-'
        },
        {
            value: 'view', label: "Vues",
            test : stories[0].viewCount !== undefined,
            method: (a, b) => b.viewCount - a.viewCount,
            display: (story) => story.viewCount ? story.viewCount : 0
        },
        {
            value: 'like', label: <i className="far fa-thumbs-up" />,
            test : stories[0].isLiked !== undefined,
            method: (a, b) => b.isLiked - a.isLiked,
            display: (story) => story.isLiked ? story.isLiked : 0
        },
        {
            value: 'dislike', label: <i className="far fa-thumbs-down" />,
            test : stories[0].isUnliked !== undefined,
            method: (a, b) => b.isUnliked - a.isUnliked,
            display: (story) => story.isUnliked ? story.isUnliked : 0
        },
        {
            value: 'romes', label: "Codes Rome",
            test : stories[0].romes !== undefined,
            method: (a, b) => codeRome(a.romes).localeCompare(codeRome(b.romes)),
            display: (story) => story.romes.length ? codeRome(story.romes) : '-'
        },
        {
            value: 'greenfact', label: "Greenfact",
            test : stories[0].greenfact !== undefined,
            method: (a, b) => {const aGreenfact = a.greenfact ? 'X':'-'; return aGreenfact.localeCompare(b.greenfact ? 'X':'-');},
            display: (story) => story.greenfact ? 'X' : '-'
        },
        {
            value: 'featured', label: "Mise en avant",
            test : stories[0].featured !== undefined,
            method: (a, b) => {const aFeatured = a.featured ? 'X':'-'; return aFeatured.localeCompare(b.featured ? 'X':'-');},
            display: (story) => story.featured ? 'X' : '-'
        },
        {
            value: 'sequenceOnly', label: "Uniquement parcours",
            test: stories[0].sequenceOnly !== undefined,
            method: (a, b) => {const aSequenceOnly = a.sequenceOnly ? 'X':'-'; return aSequenceOnly.localeCompare(b.sequenceOnly ? 'X':'-');},
            display: (story) => story.sequenceOnly ? 'X' : '-'
        },
        {
            value: 'private', label: "Privée",
            test : stories[0].private !== undefined,
            method: (a, b) => {const aPrivate = a.private ? 'X':'-'; return aPrivate.localeCompare(b.private ? 'X':'-');},
            display: (story) => story.private ? 'X' : '-'
        },
        {
            value: 'freemium', label: "Free",
            test : stories[0].freemium !== undefined,
            method: (a, b) => {const aFreemium = a.freemium ? 'X':'-'; return aFreemium.localeCompare(b.freemium ? 'X':'-');},
            display: (story) => story.freemium ? 'X' : '-'
        },
        {
            value: 'typeOfStory', label: "Type",
            test : stories[0].typeOfStory !== undefined,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.typeOfStory?.label, b.typeOfStory?.label),
            display: (story) => story.typeOfStory ? story.typeOfStory.label:'-'
        },
    ];

    const onSelectStory = (uniqueID) => {
         // eslint-disable-next-line no-unused-expressions
         storySlugs.readOne
             ? navigate(storySlugs.readOne.replace(':uniqueId', uniqueID), { state: { olderSearchParams: location.search } })
            : null;
    }

    return storiesToDisplay.length > 0 
        && <DynamicTableInfinite
            contentTable = {storiesToDisplay}
            contentSort = {sortType}
        valueInitSort="date"
            index = 'uniqueId'
            handleClick = {onSelectStory}
            fetchData={fetchNextStories}
            hasMore={hasMore}
            setSortedContent={setSortedStories}
            sortedContent={sortedStories}
            sortState={[shouldSort, setShouldSort]}
        withParams={withParams}
            tableName="storiesTable"
            filename="stories" />;
        
}

export default StoryTableComponent