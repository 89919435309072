import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Row, Col, Card, Container, Alert, Button, Image, OverlayTrigger, Tooltip, Tabs, Tab } from 'react-bootstrap';
import { useStoreState, useStoreActions } from 'easy-peasy';

import HelperMetiers360 from '../../../services/HelpersMetiers360.js';

import AuthorizationChecker from "../../../services/AuthorizationChecker";

import CardGroupMediaComponent from '../../widgets/CardGroupMedia/CardGroupMediaComponent.jsx';

import RomeComponent from '../../romes/RomeComponent.jsx';

import VideoPlayerComponent from './videoPlayer/index.jsx';
import { useModal } from '../../../hooks/useModal';
import { useToast } from '../../../hooks/useToast.jsx';

import './VideoDetailsComponent.scss';
import Loader from '../../widgets/Loader.jsx';
import FileModalComponent from '../../widgets/FileModal/FileModalComponent.jsx';

import VideoCommentM360Component, { submitCommentM360VideoForm } from '../VideoCommentM360Component.jsx';
import ProfessionalComponent from '../../professionals/ProfessionalComponent.jsx';
import useBackButton from '../../../hooks/useBackButton.jsx';
import VideoDetailsAudience from './VideoDetailsAudience.jsx';
import FormAssignExistingProsComponent, { submitAssignProsVideoForm } from '../../professionals/formProfessional/FormAssignExistingProsComponent.jsx';
import FormOneProComponent, { submitProVideoForm } from '../../professionals/formProfessional/FormOneProComponent.jsx';
import FormRomesComponent, { submitRomesVideoForm } from './FormRomesComponent.jsx';
import UploadFilesFormComponent from '../../widgets/uploadFiles/UploadFilesFormComponent.jsx';

/**
 * 
 * @param {Object} video object with all the video data to be displayed on the page
 * @param {String} readAll  readAll slug to access the video list page
 * @param {String} readOne  readOne slug to access a video details page - replace the :uniqueId part of string with actual uniqueId
 * @param {String} create  create slug to access the video creation page - optional, need according rights
 * @param {String} update  update slug to access the video update page - replace the :uniqueId part of string with actual uniqueId  - optionnal, need according rights 
 * @example
 * 
 * <VideoDetailsComponent video={video} update={updateSlug} {...}>
 */

const VideoDetailsComponent = (props) => {
    const { video, videoStories, isFetchingStories, videoPedagogicModules, isFetchingAllPedagogicModules, returnLink } = props;

    const videoSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('videos'));
    const { deleteVideo, editVideoRomes, addVideoPro, editVideoPro, editVideoCommentM360, editVideoAssignPro, updateVideoFiles } = useStoreActions(actions => actions.videos);

    const { modalComponent, setModalShow, modalData, setModalData, setIsSending } = useModal();
    const toast = useToast();

    const { readOne: readOneGroup } = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('groups'));
    const { readOne: readOneStory } = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('stories'));
    const { readOne: readOnePedagogicModule } = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('pedagogicModules'));
    const clientsSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('clients'));

    const [searchParam, setSearchParam] = useSearchParams();
    const [key, setKey] = useState(searchParam?.get('tab') ?? 'videoDetails');
    const [isGroupsOpen, setIsGroupsOpen] = useState(true);
    const [isPedagogicModulesOpen, setIsPedagogicModulesOpen] = useState(true);
    const [isStoriesOpen, setIsStoriesOpen] = useState(true);

    const navigate = useNavigate();

    const editorContentRef = useRef(video?.description);

    const goToClient = (uniqueIdClient) => {
        navigate(clientsSlugs.readOne.replace(':uniqueId', uniqueIdClient))
    }
    const { backButtonComponent } = useBackButton();
    const { isMobile, isTablet } = useStoreState(state => state.responsive);

    useEffect(() => {
        setSearchParam({ ...searchParam, ...{ "tab": key } });
    }, [key])

    useEffect(() => {
        searchParam?.get('tab') && setKey(searchParam?.get('tab'))
    }, [searchParam])


    const validateEditRomes = () => {
        submitRomesVideoForm({ videoUniqueId: video.uniqueId, action: editVideoRomes })
            .then(() => {
                toast.success();
                setModalShow(false);
            })
    }
    const onEditVideoRomes = () => {
        setModalData({
            ...modalData,
            header: <>Édition des codes ROME en lien avec l'expérience immersive</>,
            content: <FormRomesComponent video={video} />,
            cancelButton: 'Annuler',
            onValidate: () => validateEditRomes(),
            size: 'xl'
        });
        setModalShow(true);
    }

    const romeList = (displayLabel, dissociateRomeFromVideoOrPros) => dissociateRomeFromVideoOrPros
        ? <>
            <h6>Issus des professionnel⋅les</h6>
            {video.romesFromPro.length > 0
                ? <RomeComponent
                    romeList={video.romesFromPro}
                    isClickable={AuthorizationChecker.hasReadOneRights('romes')}
                    displayLabel={displayLabel}
                />
                : <>Pas de codes ROME</>}
            <div className='d-flex justify-content-between mt-3'>
                <h6>En lien avec l'expérience immersive</h6>
                <Button variant="secondary" onClick={onEditVideoRomes} className='me-4'>
                    <i className="fas fa-edit"></i> &nbsp;Editer les codes ROME de l'expérience
                </Button>
            </div>
            {video.romesFromVideo.length > 0
                ? <RomeComponent
                    romeList={video.romesFromVideo}
                    isClickable={AuthorizationChecker.hasReadOneRights('romes')}
                    displayLabel={displayLabel}
                />
                : <>Pas de codes ROME</>}
        </>
        : video.romes?.length > 0
            ? <RomeComponent
                romeList={video.romes}
                isClickable={AuthorizationChecker.hasReadOneRights('romes')}
                displayLabel={displayLabel}
            />
            : <>Pas de métier enregistré</>;

    const playerPreference = useStoreState(state => state.preferences.videoPlayerType);
    const setPlayerPreference = useStoreActions(actions => actions.preferences.setVideoPlayerType);

    const subtitleItems = video.subtitles?.length
        ? video.subtitles.map((subtitle) => (
            <Button variant='light' className="ms-2" key={subtitle.url} href={subtitle.url}>
                <i className="mr-2 fa fa-download" />{subtitle.lang == 'en' ? "Anglais" : "Français"}
            </Button>
        ))
        : <span>Pas de sous-titres enregistrés</span>

    const producersList = video?.producers?.length > 0
        ? video.producers?.map((p, i) =>
            <span key={`link${p.id}`}>
                <a href="#"
                    onClick={(e) => { e.preventDefault(); goToClient(p.id); }}>
                    {p.clientName}
                </a>
                {(i === video.producers.length - 1) ? null : <span>,&nbsp;&nbsp;</span>}
            </span>)
        : null;

    const clientsList = video?.clients?.length > 0
        ? video.clients?.map((p, i) =>
            <span key={`link${p.uniqueId}`}>
                <a href="#"
                    onClick={(e) => { e.preventDefault(); goToClient(p.uniqueId); }}>
                    {p.name}
                </a>
                {(i === video.clients.length - 1) ? null : <span>,&nbsp;&nbsp;</span>}
            </span>)
        : null;

    const isUsedInGroup = video.groups !== null;
    const isUsedInStory = !isFetchingStories && videoStories.find(story => story.fromVideoUniqueId === video.uniqueId) !== undefined;
    const isUsedInPedagogicModule = videoPedagogicModules?.length > 0;
    const canBeDeleted = !isUsedInGroup && !isUsedInPedagogicModule;

    const createDeleteModal = () => {
        setModalData({
            ...modalData,
            header: <>Suppression de l'expérience immersive</>,
            content: <>
                {isUsedInGroup &&
                    <Alert variant="danger">
                        <h5>Cette expérience immersive est utilisée dans <b>{video.groups.length}</b>
                            &nbsp;{video.groups.length > 1 ? 'espaces immersifs' : 'espace immersif'}</h5>
                    </Alert>}
                {isUsedInPedagogicModule &&
                    <Alert variant="danger">
                        <h5>Cette expérience immersive est utilisée dans <b>{videoPedagogicModules.length}</b>
                            &nbsp;{videoPedagogicModules.length > 1 ? 'modules pédagogiques' : 'module pédagogique'}</h5>
                    </Alert>}
                {canBeDeleted ?
                    <h5>Êtes-vous sûr⋅e de vouloir supprimer l'expérience immersive <b>{video.videoName}</b>&nbsp;?</h5>
                    : <h5>Veuillez supprimer l'expérience immersive de ce(s) containers pour pouvoir continuer</h5>}
            </>,
            resetButton: canBeDeleted && 'Supprimer',
            onReset: canBeDeleted ?
                () => {
                    deleteVideo({ uniqueId: video.uniqueId })
                        .then(() => {
                            navigate(returnLink)
                        });
                }
                : null,
        });
        setModalShow(true);
    }

    const validatePro = (pro) => {
        setIsSending(true);
        submitProVideoForm({
            uniqueId: pro ? pro.uniqueId : video.uniqueId,
            action: pro ? editVideoPro : addVideoPro
        }).then(() => {
            toast.success();
            setModalShow(false);
        })
            .finally(() => setIsSending(false))
    }
    const addOrEditProsModal = (pro = null) => {
        setModalData({
            ...modalData,
            header: pro
                ? <>Édition d'un⋅e professionnel⋅le : {pro.name}</>
                : <>Ajout d'un⋅e professionnel⋅le</>,
            content: <FormOneProComponent pro={pro} setLoadingValidation={setIsSending} />,
            cancelButton: 'Annuler',
            onValidate: () => validatePro(pro),
            size: 'xl'
        });
        setModalShow(true);
    }

    const validateAssignExistingPros = () => {
        submitAssignProsVideoForm({ videoUniqueId: video.uniqueId, action: editVideoAssignPro })
            .forEach(actionAssignPro =>
                actionAssignPro
                    .then(() => {
                        toast.success();
                    })
            )
        setModalShow(false);
    }
    const assignExistingProsModal = () => {
        setModalData({
            ...modalData,
            header: <>Affectation de professionnel⋅les existant⋅es sur d'autres expériences immersives</>,
            content: <FormAssignExistingProsComponent />,
            cancelButton: 'Annuler',
            onValidate: () => validateAssignExistingPros(),
            size: 'xl'
        });
        setModalShow(true);
    }

    const validateCommentM360 = () => {
        submitCommentM360VideoForm({ uniqueId: video.uniqueId, action: editVideoCommentM360, editorContentRef })
            .then(() => {
                toast.success();
                setModalShow(false);
            })
    }
    const editCommentM360Modal = () => {
        setModalData({
            ...modalData,
            header: <>Édition commentaire M360 associé à l'expérience immersive</>,
            content: <VideoCommentM360Component video={video} editorContentRef={editorContentRef} />,
            cancelButton: 'Annuler',
            onValidate: validateCommentM360,
            size: 'xl'
        });
        setModalShow(true);
    }

    const editFilesModal = () => {
        setModalData({
            ...modalData,
            header: <>Édition fichiers associés à l'expérience immersive</>,
            content: <UploadFilesFormComponent entityName="video" entity={video} postFunction={updateVideoFiles} onSuccess={() => setModalShow(false)} />,
            cancelButton: 'Annuler',
            onValidate: () => {
                document.getElementById("upload-files-form-submit")?.click();
            },
            size: 'xl'
        });
        setModalShow(true);
    }


    const prosList = video.pros?.length > 0
        ? <Row>
            {video?.pros?.map(pro =>
                <Col key={pro.uniqueId} className="my-4" xs={12} md={6} xl={4}>
                    <ProfessionalComponent
                        pro={pro}
                        fromVideoUniqueId={video.uniqueId}
                        showEditButtons={true}
                        key={'card_' + pro.uniqueId}
                        onEdit={addOrEditProsModal} />
                </Col>)
            }
        </Row>
        : <span className='ms-1 mt-3'>Pas de professionnel⋅le enregistré⋅e</span>;

    const isAttributeToDisplay = video.producers?.length > 0
        || (AuthorizationChecker.isAdmin() && (video.clients?.length > 0 || video.fileSize || video.position));



    const videoDetails = <Row>
        <Col md={12} lg={6}>
            <div className="video_details_player">
                <VideoPlayerComponent
                    videoById={video}
                    playerTypePreferenceTuple={[playerPreference, setPlayerPreference]}
                    viewCount={video.viewCount}
                    headsetsCount={video.headsetsCount}
                />
            </div>
            {video.subtitles != null && <div className='my-4'>
                <strong>Sous-titres : </strong>
                {subtitleItems}
            </div>}
            {video.files && <FileModalComponent files={video.files} editFilesModal={editFilesModal} />}
            {AuthorizationChecker.isAdmin()
                && (video.commentM360
                    ? <div className="rome-div">
                        <div className='d-flex justify-content-between mb-3'>
                        <strong>Commentaire :</strong>
                        <Button variant="secondary" onClick={editCommentM360Modal}><i className="fas fa-edit"></i> &nbsp;Éditer</Button>
                        </div>

                        <span className="comment_m360 text-muted" dangerouslySetInnerHTML={{ __html: video.commentM360 }}></span>
                    </div>
                : <>
                    <Row className="d-flex align-items-center justify-content-start">
                        <Col xs="auto">
                            <Button className="d-flex align-items-center justify-content-center" variant="success" onClick={editCommentM360Modal}>
                                <i className="fas fa-plus"></i> &nbsp;Ajouter un commentaire
                            </Button>
                        </Col>
                    </Row>
                </>
            )
            }
        </Col>
        <Col md={12} lg={6}>
            <Row>
                {video.producers?.length > 0
                    && <div className='mb-2'>
                        <strong>Producteur{video.producers?.length > 1 ? "s" : ""} : </strong>
                        {producersList}
                    </div>}
            </Row>
            <Row>
                {(AuthorizationChecker.isAdmin() && video.clients?.length)
                    && <div className='mb-2'>
                        <strong>Client{video.clients?.length > 1 && 's'} ayant accès: </strong>
                        {clientsList}
                    </div>}
            </Row>
            <Row className={isAttributeToDisplay ? 'mb-2' : 'mb-2 mt-5'}>
                <Col>
                    {AuthorizationChecker.isAdmin() && video.fileSize != null
                        && <div>
                            <strong>Taille : </strong> {HelperMetiers360.formatBytes(video.fileSize)}
                        </div>}
                </Col>
                <Col>
                    {AuthorizationChecker.isAdmin() && video.position != null
                        && <div>
                            <strong>Position : </strong>{video.position}
                        </div>}
                </Col>
            </Row>
            {isAttributeToDisplay
                && <div className='d-flex justify-content-center mb-3'>
                    <hr width="90%" />
                </div>
            }

            <Row>
                <span dangerouslySetInnerHTML={{ __html: video.description }}></span>
            </Row>
            {video.romes?.length > 0
                && <>
                    <div className='d-flex justify-content-center mb-3'>
                        <hr width="90%" />
                    </div>
                <Row className='align-items-center'>
                    <strong className='w-auto'>Code{video.romes?.length > 1 && 's'} ROME de l'expérience : </strong>
                    {romeList(false, false)}
                </Row>
                </>
            }
        </Col>
    </Row>;

    const videoJobs = <Row>
        <div className='mb-5'>
            <h5>Code{video.romes?.length > 1 && 's'} ROME</h5>
            <div className="mt-3">
                {romeList(true, AuthorizationChecker.hasUpdateRights('videos'))}
            </div>
        </div>
        <div className='d-flex justify-content-between'>
            <h5>Professionnel⋅le{video.romes?.length > 1 && 's'} rencontré⋅e{video.romes?.length > 1 && 's'}</h5>
            {AuthorizationChecker.hasUpdateRights('videos') &&
                <div className='d-flex align-items-end'>
                    <Button variant="info" onClick={assignExistingProsModal} className='me-4'>Affecter des professionnel⋅les existant⋅es</Button>
                    <Button variant="success" onClick={() => addOrEditProsModal()} className='me-4'><i className="fas fa-plus"></i> &nbsp;Ajouter un⋅e professionnel⋅le</Button>
                </div>
            }
        </div>
        {prosList}
    </Row>;

    const videoAccessContent = <Row>
        <div className="associated_card">
            {isUsedInGroup &&
                <div className="associated_card_item">
                    <Card.Title
                        className="associated_card_title d-flex justify-content-between"
                        onClick={() => setIsGroupsOpen(!isGroupsOpen)}>
                        {video.groups?.length} {video.groups?.length > 1 ? "espaces immersifs" : "espace immersif"}

                        {isGroupsOpen
                            ? <i className="fa fa-angle-up fa-lg"></i>
                            : <i className="fa fa-angle-down fa-lg"></i>
                        }
                    </Card.Title>
                    {isGroupsOpen
                        && <CardGroupMediaComponent mediaType='groups' mediaList={video.groups} readOne={readOneGroup} />
                    }
                </div>
            }
            {(isUsedInPedagogicModule && !isFetchingAllPedagogicModules) &&
                <div className="associated_card_item">
                    <Card.Title className="associated_card_title d-flex justify-content-between" onClick={() => setIsPedagogicModulesOpen(!isPedagogicModulesOpen)}>
                        {videoPedagogicModules?.length} modules pédagogiques

                        {isPedagogicModulesOpen
                            ? <i className="fa fa-angle-up fa-lg"></i>
                            : <i className="fa fa-angle-down fa-lg"></i>
                        }
                    </Card.Title>
                    {isPedagogicModulesOpen
                        && <CardGroupMediaComponent
                            mediaType='pedagogicModules'
                            mediaList={videoPedagogicModules}
                            readOne={readOnePedagogicModule} />
                    }
                </div>
            }
            {(isUsedInStory && !isFetchingStories) &&
                <div className="associated_card_item">
                    <Card.Title className="associated_card_title d-flex justify-content-between" onClick={() => setIsStoriesOpen(!isStoriesOpen)}>
                        {videoStories?.length} stories

                        {isStoriesOpen
                            ? <i className="fa fa-angle-up fa-lg"></i>
                            : <i className="fa fa-angle-down fa-lg"></i>
                        }
                    </Card.Title>
                    {isStoriesOpen
                        && <CardGroupMediaComponent mediaType='stories' mediaList={videoStories} readOne={readOneStory} />
                    }
                </div>
            }
            {(isFetchingStories || isFetchingAllPedagogicModules) && <Loader />}
        </div>
    </Row>;

    return (
        <Container>
            <Row className='mb-5' id='header-video'>
                <Col className='col-auto'>
                    <Row className={"video-col " + (isMobile ? "my-3" : "")}>
                        <Col className={"col-md-auto " + (isMobile ? "d-flex justify-content-center mb-3" : "")}>
                            {video?.links?.thumbnail
                                && <Image className="item_details-thumbnail" src={video.links.thumbnail} />}
                        </Col>
                        <Col>
                            <div className='d-flex'>
                                <h3 className='fw-bold w-auto mr-2'>{video?.videoName ?? "Détails de l'expérience immersive"}</h3>
                                {AuthorizationChecker.isAdmin() && video.private
                                    && <OverlayTrigger
                                        placement="right"
                                        overlay={<Tooltip>Expérience immersive privée</Tooltip>}>
                                        <span className="groupIcon"><i className="fas fa-lock"></i></span>
                                    </OverlayTrigger>}
                                {AuthorizationChecker.isAdmin() && video?.isAlwaysVisible
                                    && <OverlayTrigger
                                        placement="right"
                                        overlay={<Tooltip>Expérience immersive freemium (accessible sans abonnement)</Tooltip>}>
                                        <span className="groupIcon ms-3"><i className="fab fa-creative-commons-nc-eu" /></span>
                                    </OverlayTrigger>}
                            </div>
                            <h5>{video?.subHeading ?? ""}</h5>
                            {video.directAccessCode != null && (video.isDirectlyAccessible)
                                && <h6 className='mt-5'>Code d'accès direct : <span className='badge-code'>{video.directAccessCode}</span></h6>}
                        </Col>
                    </Row>
                </Col>
                <Col className={'d-flex flex-column justify-content-end align-top ' + (isTablet ? 'mb-3' : '')}>
                    <Row className='d-flex justify-content-end pe-3 h-50 align-items-start'>
                        {backButtonComponent}
                    </Row>
                    <Row className='d-flex justify-content-end pb-3'>
                        <p className='w-auto m-0 text-right'>
                            {video.releaseDate != null
                                && "Publication : " + HelperMetiers360.getdisplayDateType(video.releaseDate, 'day')}
                            {video.createdAt != null
                                && " - Création : " + HelperMetiers360.getdisplayDateType(video.createdAt, 'day')}
                            {video.updatedAt != null
                                && " - MAJ : " + HelperMetiers360.getdisplayDateType(video.updatedAt, 'day')}
                        </p>
                    </Row>
                    <Row className='p-0'>
                        {
                            (AuthorizationChecker.hasUpdateRights('videos') || AuthorizationChecker.hasDeleteRights('videos'))
                            && <div className="item_details_btn_actions d-flex justify-content-end">
                                {
                                    AuthorizationChecker.hasUpdateRights('videos') ?
                                        <Button variant="secondary" as={Link} to={videoSlugs.update.replace(':uniqueId', video && video.uniqueId)}><i className="fas fa-edit"></i> &nbsp;Éditer</Button>
                                        : ''
                                }
                                {AuthorizationChecker.hasDeleteRights('videos') ?
                                    <Button variant="danger" onClick={createDeleteModal}><i className="fas fa-trash-alt"></i> &nbsp;Supprimer</Button>
                                    : null}
                            </div>
                        }
                    </Row>
                </Col>
            </Row>

            <Tabs defaultActiveKey="videoDetails" id="video-details-tab" activeKey={key}
                onSelect={(k) => setKey(k)} className="d-flex mb-4" style={{ flexWrap: "wrap" }}>
                <Tab eventKey="videoDetails" title="Détails de l'expérience immersive" unmountOnExit={false}>
                    {videoDetails}
                </Tab>
                <Tab eventKey="jobsVideo" title="Métiers et professionnel⋅les" unmountOnExit={false}>
                    {videoJobs}
                </Tab>
                <Tab eventKey="accessVideo" title="Accès" unmountOnExit={false}>
                    {videoAccessContent}
                </Tab>
                {(AuthorizationChecker.isAdmin() || video?.isProducedByClient) &&
                    <Tab eventKey="audience" title="Mesure d'impact" unmountOnExit={false} mountOnEnter={true}>
                        <VideoDetailsAudience videoUniqueId={video?.uniqueId} />
                    </Tab>}
            </Tabs>

            {modalComponent}
        </Container>
    );
}

export default VideoDetailsComponent;
