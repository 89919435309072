import React, { useEffect, useMemo, useRef, useState } from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import MultipleClientManagerEdition from '../MultipleClientManagerEdition/MultipleClientManagerEdition';
import "./ClientParenthoodTabComponent.scss"
import { useStoreActions } from 'easy-peasy';
import AuthorizationChecker from '../../../../services/AuthorizationChecker';
import ButtonLoaderComponent from '../../../widgets/ButtonLoaderComponent';
import CreateMultiUsersOrContactsComponent from '../../multiUsersOrContacts/CreateMultiUsersOrContactsComponent';
import MultipleSubscriptionEndDateEdition from '../MultipleSubscriptionEndDateEdition/MultipleSubscriptionEndDateEdition';
import MultipleTagOpesCreationComponent from '../multipleTagOpesCreation/MultipleTagOpesCreationComponent';
import MultipleSubscriptionCreationComponent from '../multipleSubscriptionCreation/MultipleSubscriptionCreationComponent';

const ClientParenthoodTabComponent = ({ clientById, allClients = [], setModalData, modalData, setModalShow, childrenListComponent }) => {
    const { fetchAllPropsForAllClients } = useStoreActions(actions => actions.clients);

    const [isClientsParenthoodInitialised, setIsClientsParenthoodCRMInitialised] = useState(false);

    const clientManagerRef = useRef(null);
    const subscriptionEndDateRef = useRef("");

    useEffect(() => {
        fetchAllPropsForAllClients({
            hasAccessToAllPropsClientsCRM: AuthorizationChecker.hasGetPropsForAllRights('clientsCRM')})
    }, []);

    const clientsParentHood = useMemo(() => {
        if(allClients[0]?.contactsM360 !== undefined) {
            setIsClientsParenthoodCRMInitialised(true);
            return allClients
                .filter(client => 
                    client.uniqueId === clientById.uniqueId
                    || clientById.clientChildren.includes(client.uniqueId))
                .map(client => {
                    return {
                        uniqueId: client.uniqueId,
                        name: client.clientName,
                        tagOperations: client.tagOperationsName,
                        contactsM360: client.contactsM360?.filter(contact => contact.relationValue === "sav")
                    }
                })
        } else {
            return [];
        }
    }, [allClients])

    const createMultiContactsModal = () => {
        setModalData({
            ...modalData,
            header: <>Ajout de plusieurs contacts</>,
            content: <CreateMultiUsersOrContactsComponent entityType="contact"
                clientUniqueIdsSelected={clientsParentHood?.map(client => client.uniqueId)}
                selectedUniqueIdParentClientDefault={clientById.uniqueId} />,
            size: 'lg',
            id: 'client-create-multi-user-modal'
        });
        setModalShow(true);
    }

    const createMultiTagOpesModal = () => {
        setModalData({
            ...modalData,
            header: <>Affecter des tags opération</>,
            content: <MultipleTagOpesCreationComponent
                clientUniqueIdsSelected={clientsParentHood?.map(client => client.uniqueId)}
                selectedUniqueIdParentClient={clientById.uniqueId}
            />,
            size: 'lg',
            id: 'client-create-multi-tag-ope-modal'
        });
        setModalShow(true);
    }

    const createMultiSubscriptionModal = () => {
        setModalData({
            ...modalData,
            header: <>Ajouter un abonnement</>,
            content: <MultipleSubscriptionCreationComponent
                clientUniqueIdsSelected={clientsParentHood?.map(client => client.uniqueId)}
                selectedUniqueIdParentClient={clientById.uniqueId}
            />,
            size: 'lg',
            id: 'client-create-multi-tag-ope-modal'
        });
        setModalShow(true);
    }

    const editClientManagerModal = () => {
        setModalData({
            ...modalData,
            header: <>Affectaction d'un⋅e nouvelle⋅eau chargé⋅e de clientèle</>,
            content: <MultipleClientManagerEdition clients={clientsParentHood} clientManagerRef={clientManagerRef} />,
            size: 'lg',
        });
        setModalShow(true);
    }

    const editSubscriptionEndDateModal = () => {
        setModalData({
            ...modalData,
            header: <>Edition des dates de fin d’abonnement</>,
            content: <MultipleSubscriptionEndDateEdition client={clientById} 
                clients={clientsParentHood}
                subscriptionEndDateRef={subscriptionEndDateRef} />,
            size: 'lg',
            id: 'edit-multiple-sub-end-date-modal'
        });
        setModalShow(true);
    }

    return (
        <Container>
            <Row className="tab-info-container">
                <Col md={4}>
                    { childrenListComponent }
                </Col>
                <Col className="tab-info-card" id="tab-actions">
                    <h4>Actions sur <b>{ clientById.clientName }</b> et ses <b>enfants</b></h4>
                    <Row className="tab-btn-row">
                        <ButtonLoaderComponent
                            variant="success"
                            className="tab-info-card-btn w-auto"
                            onClick={createMultiContactsModal}
                            isSending={!isClientsParenthoodInitialised}
                            disabled={!isClientsParenthoodInitialised}
                            iconClassName="fas fa-plus"
                            msg="Créer des contacts"
                        />
                        <ButtonLoaderComponent
                            variant="success"
                            className="tab-info-card-btn w-auto"
                            onClick={createMultiSubscriptionModal}
                            isSending={!isClientsParenthoodInitialised}
                            disabled={!isClientsParenthoodInitialised}
                            iconClassName="fas fa-plus"
                            msg="Ajouter un abonnement"
                        />
                        <ButtonLoaderComponent
                            variant="success"
                            className="tab-info-card-btn w-auto"
                            onClick={createMultiTagOpesModal}
                            isSending={!isClientsParenthoodInitialised}
                            disabled={!isClientsParenthoodInitialised}
                            msg="Affecter des tags opération"
                        />
                    </Row>
                    <Row className="tab-btn-row" id='row-edit-btns'>
                        <ButtonLoaderComponent
                            variant="secondary"
                            className="tab-info-card-btn w-auto"
                            onClick={editClientManagerModal}
                            isSending={!isClientsParenthoodInitialised}
                            disabled={!isClientsParenthoodInitialised}
                            iconClassName="fas fa-edit"
                            msg="Affecter chargé⋅e de clientèle"
                        />
                        <ButtonLoaderComponent
                            variant="secondary"
                            className="tab-info-card-btn w-auto"
                            onClick={editSubscriptionEndDateModal}
                            isSending={!isClientsParenthoodInitialised}
                            disabled={!isClientsParenthoodInitialised}
                            iconClassName="fas fa-edit"
                            msg="Éditer les dates de fin d’abonnement"
                        />
                    </Row>
                </Col>
            </Row>
        </Container>
    );

}

export default ClientParenthoodTabComponent