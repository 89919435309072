import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, usePDF } from '@react-pdf/renderer';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ButtonLoaderComponent from '../ButtonLoaderComponent';

const dateString = new Date().toLocaleDateString('fr-FR');

const GroupsVideosDocument = ({ title, fileName, videos, groups }) => {
  const styles = StyleSheet.create({
    page: {
      fontFamily: 'Helvetica',
      fontWeight: 'light',
      fontSize: 7,
      padding: 40,
    },
    table: {
      marginTop: 10,
      marginBottom: 10,
      width: '100%',
    },
    row: {
      display: 'flex',
      flexDirection: 'row'
    },
    title: {
        fontSize: 14,
        fontFamily: 'Helvetica-Bold',
        textAlign: 'center',
        width: '100%',
        color: '#021f3f'
    },
    tableRow: {
      display: 'flex',
      flexDirection: 'row',
      margin: 0,
      borderBottom: '1px solid black',
    },
    greyRow: {
      backgroundColor: '#e9ecef',
    },
    headerCol: {
      borderTop: '1px solid black',
      padding: 2,
    },
    col: {
      borderRight: '1px solid black',
      margin: 0,
      padding: 5,
      textAlign: 'center',
      borderLeft: '1px solid black',
    },
    col1: {
        width: '20%',
      padding: 2,
    },
    col2: {
      width: '10%',
        padding: 2
    },
    groupCol: {
      width: `${80 / groups.length+1}%`,
    },
    groupColContent: {
      fontFamily: 'Helvetica-Bold',
    },
    pageCounter: {
      textAlign: 'right',
    },
    rowsTitle: {
      width: '20%',
      backgroundColor: '#eca2ff',
      fontFamily: 'Helvetica-Bold',
      alignSelf: 'flex-end',
    },
    lastCol: {
      width: '8%',
      },
      BigText: {
          fontSize: 9
      },
      codeAccess: {
          color: '#5b5b5b',
    }
  });

  return <Document title={fileName} author='Métiers360'>
    <Page size="A4" orientation="landscape" style={styles.page}>
      <View style={styles.row}>
        <Text>A jour du {dateString}</Text>
        <Text style={styles.title}>{title}</Text>
      </View>
      <View style={styles.table}>
              <View style={styles.tableRow} fixed>
                  <View style={[styles.col, styles.headerCol, styles.rowsTitle]}>
                      <Text style={styles.BigText}>Expérience immersive</Text>
                      <Text style={styles.codeAccess}>Code d'accès direct</Text>
                  </View>
                  {groups?.map((g) =>
                      <Text key={g.uniqueId} style={[styles.col, styles.groupCol, styles.headerCol]}>
                          {g.name}
                      </Text>
                  )}
        </View>

        { videos
          ?.filter(video => video.groups?.some(group => groups.some(g => g.uniqueId === group.uniqueId)))
          ?.map((v, index) =>
            <View key={v.uniqueId} style={[styles.tableRow, (index % 2 !== 0) ? styles.greyRow : null]} wrap={false}>
                  <View style={[styles.col, styles.col1]}>
                      <Text style={styles.BigText}>{v.videoName}</Text>
                      <Text>{v.subHeading}</Text>
                      {v.isDirectlyAccessible && <Text style={styles.codeAccess}>{v.directAccessCode}</Text>}
                  </View>

              { groups?.map((g) => 
                  <Text style={[styles.col, styles.groupCol, styles.groupColContent]} key={`${v.uniqueId}-${g.uniqueId}`}>
                    { v.groups?.some(gr => gr.uniqueId === g.uniqueId) ? "X" : "" }
                  </Text>
              )}
            </View>)
        }
      </View>

      <Text style={styles.pageCounter} fixed
        render={({ pageNumber, totalPages }) => 
        `${pageNumber} / ${totalPages}`} />
    </Page>
  </Document>;
};

const DownloadGroupsVideosPDF = (props) => {

  const { groups, videos, title, buttonText, fileName } = props;

  const [downloadDone, setDownloadDone] = useState(false);

  const [instance] = usePDF({ document: <GroupsVideosDocument title={title} fileName={fileName} groups={groups} videos={videos} /> });

  useEffect(() => {
    if (!instance.loading && videos?.length && groups?.length && !downloadDone) {
      setDownloadDone(true);
      const link = document.createElement('a');
      link.href = instance.url;
      link.download = `${fileName}-${dateString}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [instance.loading, videos, groups, downloadDone]);

  const isLoading = (instance.loading && !instance.url) || !videos?.length;

  return instance.error
    ? <Button variant="danger" ><i className="fas fa-exclamation-triangle mr-1" />{buttonText}</Button>
    : <ButtonLoaderComponent variant="primary" isSending={isLoading} disabled={isLoading} id="btn-pdf-videos-by-group"
      href={instance.url} download={`${fileName}-${dateString}.pdf`}
      iconClassName="fas fa-download" msg={buttonText} />;
};

GroupsVideosDocument.propTypes = {
  videos: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired
};
DownloadGroupsVideosPDF.propTypes = {
  videos: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired
};

export default DownloadGroupsVideosPDF;
