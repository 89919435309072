import { action, actionOn, thunk } from "easy-peasy";
import axios from '../services/axios';

const romesInitialState = {
    allRomes: [],
    isFetchingAllRomes: false,
    isUpdatingTransversalPropThumbnail: false,
    isUpdateNecessary: false,
    initialFetchDone: false,
    romeById: null,
    isFecthingOneRome: false,
    isFetchingOneTransversalProp:false,
    oneTransversalProp: null,
    allEntitiesRomeCount: {},
    allAreasOfInterest: [],
    allWorkingConditions: [],
    allSkills: [],
    allJobAppellations: [],
    allProfessionals: [],
    allLargeProfessionalFields: [],
    isFetchingAllEntitiesRomeCount: false,
    isFetchingAllAreasOfInterest: false,
    isFetchingAllWorkingConditions: false,
    isFetchingAllSkills: false,
    isFetchingAllJobAppellations: false,
    isFetchingAllProfessionals: false,
    isFetchingAllLargeProfessionalFields: false,
    homeRomesAlreadySeen: false
}

const romes = {
    ...romesInitialState,

    onGlobalResetCalled: actionOn(
        // target resolver: we store in an array a list of action we will be listening for
        (actions, storeActions) => [
            storeActions.resetStore.performReset,
        ],
        // handler: if we are listening for multiple type of actions, we can execute logic depending on the type
        (state, target) => {
            const [performReset] = target.resolvedTargets;

            if (target.type === performReset) {
                ({
                    allRomes: state.allRomes,
                    isFetchingAllRomes: state.isFetchingAllRomes,
                    isUpdatingTransversalPropThumbnail: state.isUpdatingTransversalPropThumbnail,
                    isUpdateNecessary: state.isUpdateNecessary,
                    initialFetchDone: state.initialFetchDone,
                    romeById: state.romeById,
                    isFecthingOneRome: state.isFecthingOneRome,
                    isFetchingOneTransversalProp: state.isFetchingOneTransversalProp,
                    oneTransversalProp: state.oneTransversalProp,
                    allEntitiesRomeCount: state.allEntitiesRomeCount,
                    allAreasOfInterest: state.allAreasOfInterest,
                    allWorkingConditions: state.allWorkingConditions,
                    allSkills: state.allSkills,
                    allJobAppellations: state.allJobAppellations,
                    allProfessionals: state.allProfessionals,
                    allLargeProfessionalFields: state.allLargeProfessionalFields,
                    isFetchingAllEntitiesRomeCount: state.isFetchingAllEntitiesRomeCount,
                    isFetchingAllAreaOfInterest: state.isFetchingAllAreaOfInterest,
                    isFetchingAllWorkingCondition: state.isFetchingAllWorkingCondition,
                    isFetchingAllSkill: state.isFetchingAllSkill,
                    isFetchingAllJobAppellations: state.isFetchingAllJobAppellations,
                    isFetchingAllProfessionals: state.isFetchingAllProfessionals,
                    isFetchingAllLargeProfessionalFields: state.isFetchingAllLargeProfessionalFields,
                    homeRomesAlreadySeen: state.homeRomesAlreadySeen
                } = romesInitialState);
            }
        }
    ),

    setInitialFetchDone: action((state, payload) => {
        state.initialFetchDone = payload;
    }),

    setAllRomes: action((state, payload) => {
        state.allRomes = payload;
    }),

    setIsFetchingAllRomes: action((state, payload) => {
        state.isFetchingAllRomes = payload;
    }),
    setIsUpdateNecessary: action((state, payload) => {
        state.isUpdateNecessary = payload;
    }),


    fetchAllRomes: thunk((actions, payload, helpers) => {
        const { isFetchingAllRomes, isUpdateNecessary } = helpers.getState();
        const { apiData } = helpers.getStoreState().actionSlugs;

        const getAllUrl = apiData.ACTIONS?.romes?.readAll?.url;

        if (!isFetchingAllRomes && isUpdateNecessary) {

            actions.setIsFetchingAllRomes(true);
            return axios.get(getAllUrl)
                .then((data => {
                    actions.setAllRomes(data);
                    actions.setIsFetchingAllRomes(false);
                    actions.setIsUpdateNecessary(romes.isUpdateNecessary);
                    actions.fetchAllRomesProps();
                    return data
                }))
                .catch((error) => Promise.reject(error))
        }
        else {
            Promise.resolve();
        }
    }),




    fetchAllRomesProps: thunk(async (actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const propsArray = Object.values(ACTIONS.romes.getAllProps)
            .map(prop => prop.url);

        for (const url of propsArray) {
            axios.get(url)
                .then((data => {
                    actions.addAllRomesProp(data);
                }))
                .catch((error) => Promise.reject(error))
        }
    }),

    updateAllRomes: action((state, payload) => {
        const index = state.allRomes.findIndex((rome) => rome.codeRome === payload.code);

        state.allRomes[index] = {
            ...state.allRomes[index],
            riasecMajeur: payload.riasecMajeur,
            riasecMineur: payload.riasecMineur,
        };
    }),

    setRomeById: action((state, payload) => {
        state.romeById = payload;
    }),

    addPropRomeById: action((state, payload) => {
        state.romeById = { ...state.romeById, ...payload };
    }),

    setIsFetchingOneRome: action((state, payload) => {
        state.isFecthingOneRome = payload;
    }),

    fetchRomeById: thunk((actions, payload, helpers) => {
        const { isFecthingOneRome } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getOneUrl = ACTIONS?.romes?.readOne?.url?.replace('romeCode', payload);
        if (!isFecthingOneRome) {
            actions.setIsFetchingOneRome(true);
            actions.setRomeById(romesInitialState.romeById);
            return axios.get(getOneUrl)
                .then((data => {
                    actions.setRomeById(data);
                    actions.setIsFetchingOneRome(romesInitialState.isFecthingOneRome);
                    actions.updateAllRomes(data);
                }))
                .catch((error) =>  Promise.reject(error))
        }
        else {
            return new Promise.resolve();
        }
    }),


    fetchRomeByIdProps: thunk(async (actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const propsArray = Object.values(ACTIONS.romes.getProps)
            .map(prop => prop.url?.replace('romeCode', payload));


        for (const url of propsArray) {
            axios.get(url)
                .then((data => {
                    actions.addPropRomeById(data);
                }))
                .catch((error) => Promise.reject(error))
        }
    }),


    addAllRomesProp: action((state, payload) => {
        const newAllRomes = state.allRomes.map(rome => {
            const entry = payload.find(prop => prop.code === rome.codeRome);
            if (entry) {
                return { ...rome, ...entry };
            }
            return rome;
        });
        state.allRomes = newAllRomes;
    }),



    setIsFetchingOneTransversalProp: action((state, payload) => {
        state.isFetchingOneTransversalProp = payload;
    }),

    setOneTransversalProp: action((state, payload) => {
        state.oneTransversalProp = payload;
    }),

    fetchOneTransversalProp: thunk((actions, payload, helpers) => {
        const { isFetchingOneTransversalProp } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        if (payload?.transversalProp && ACTIONS?.hasOwnProperty(payload.transversalProp) && !isFetchingOneTransversalProp) {
            actions.setOneTransversalProp(null);
            const getOneUrl = ACTIONS[payload.transversalProp].readOne?.url?.replace('code', payload.code);

            actions.setIsFetchingOneTransversalProp(true);
            return axios.get(getOneUrl)
                .then((data => {
                    actions.setOneTransversalProp(data);
                    actions.setIsFetchingOneTransversalProp(false);
                    return data;
                }))
                .catch((error) => Promise.reject(error))
                .finally(() => actions.setIsFetchingOneTransversalProp(false));
        }
        else {
            return new Promise.resolve();
        }

    }),

    setIsFetchingAllEntitiesRomeCount: action((state, payload) => {
        state.isFetchingAllEntitiesRomeCount = payload;
    }),
    setIsFetchingAllAreasOfInterest: action((state, payload) => {
        state.isFetchingAllAreasOfInterest = payload;
    }),
    setIsFetchingAllWorkingConditions: action((state, payload) => {
        state.isFetchingAllWorkingConditions = payload;
    }),
    setIsFetchingAllSkills: action((state, payload) => {
        state.isFetchingAllSkills = payload;
    }),
    setIsFetchingAllJobAppellations: action((state, payload) => {
        state.isFetchingAllJobAppellations = payload;
    }),
    setIsFetchingAllProfessionals: action((state, payload) => {
        state.isFetchingAllProfessionals = payload;
    }),
    setIsFetchingAllLargeProfessionalFields: action((state, payload) => {
        state.isFetchingAllLargeProfessionalFields = payload;
    }),
    setHomeRomesAlreadySeen: action((state, payload) => {
        state.homeRomesAlreadySeen = payload;
    }),


    setAllEntitiesRomeCount: action((state, payload) => {
        state.allEntitiesRomeCount = payload;
    }),
    setAllAreasOfInterest: action((state, payload) => {
        state.allAreasOfInterest = payload;
    }),
    setAllWorkingConditions: action((state, payload) => {
        state.allWorkingConditions = payload;
    }),
    setAllSkills: action((state, payload) => {
        state.allSkills = payload;
    }),
    setAllJobAppellations: action((state, payload) => {
        state.allJobAppellations = payload;
    }),
    setAllProfessionals: action((state, payload) => {
        state.allProfessionals = payload;
    }),
    setAllLargeProfessionalFields: action((state, payload) => {
        state.allLargeProfessionalFields = payload;
    }),

    updateAllTransversalProps: action((state, payload) => {
        const allTransversalPropsName = payload?.allTransversalPropsName;
        const allTransversalProps = payload?.allTransversalProps;

        if (allTransversalPropsName && allTransversalProps) {
            const index = state[allTransversalPropsName].findIndex((transversalProp) => transversalProp.code === allTransversalProps.code);
            const oldTransversalProp = state[allTransversalPropsName][index];
            state[allTransversalPropsName][index] = { ...oldTransversalProp, ...allTransversalProps };
        }
    }),

    fetchAllAreasOfInterest: thunk((actions, payload, helpers) => {
        const { isFetchingAllAreasOfInterest } = helpers.getState();
        const { apiData } = helpers.getStoreState().actionSlugs;

        const getAllUrl = apiData.ACTIONS?.areasOfInterest?.readAll?.url;

        if (!isFetchingAllAreasOfInterest) {
            actions.setIsFetchingAllAreasOfInterest(true);
            return axios.get(getAllUrl)
                .then((data => {
                    actions.setAllAreasOfInterest(data);
                    actions.setIsFetchingAllAreasOfInterest(false);
                    return data
                }))
                .catch((error) => Promise.reject(error))
        }
        else {
            return Promise.resolve();
        }
    }),
   
    fetchAllWorkingConditions: thunk((actions, payload, helpers) => {
        const { isFetchingAllWorkingConditions } = helpers.getState();
        const { apiData } = helpers.getStoreState().actionSlugs;

        const getAllUrl = apiData.ACTIONS?.workingConditions?.readAll?.url;

        if (!isFetchingAllWorkingConditions) {
            actions.setIsFetchingAllWorkingConditions(true);
            return axios.get(getAllUrl)
                .then((data => {
                    actions.setAllWorkingConditions(data);
                    actions.setIsFetchingAllWorkingConditions(false);
                    return data
                }))
                .catch((error) => {
                    return Promise.reject(error);
                })
        }
        else {
            return Promise.resolve();
        }
    }),

    fetchAllSkills: thunk((actions, payload, helpers) => {
        const { isFetchingAllSkills } = helpers.getState();
        const { apiData } = helpers.getStoreState().actionSlugs;

        const getAllUrl = apiData.ACTIONS?.skills?.readAll?.url;

        if (!isFetchingAllSkills) {
            actions.setIsFetchingAllSkills(true);
            return axios.get(getAllUrl)
                .then((data => {
                    actions.setAllSkills(data);
                    actions.setIsFetchingAllSkills(false);
                    return data
                }))
                .catch((error) => {
                    return Promise.reject(error);
                })
        }
        else {
            return Promise.resolve();
        }
    }),

    fetchAllJobAppellations: thunk((actions, payload, helpers) => {
        const { isFetchingAllJobAppellations } = helpers.getState();
        const { apiData } = helpers.getStoreState().actionSlugs;

        const getAllUrl = apiData.ACTIONS?.jobAppellations?.readAll?.url;

        if (!isFetchingAllJobAppellations) {
            actions.setIsFetchingAllJobAppellations(true);
            return axios.get(getAllUrl)
                .then((data => {
                    actions.setAllJobAppellations(data);
                    actions.setIsFetchingAllJobAppellations(false);
                    actions.fetchAllJobAppellationsProps();
                    return data
                }))
                .catch((error) => {
                    return Promise.reject(new Error(error));
                })
        }
        else {
            return Promise.resolve();
        }
    }),

    addJobAppellationsProp: action((state, payload) => {
        const newAllJobAppellations = state.allJobAppellations.map(appellation => {
            const entry = payload.find(prop => prop.code === appellation.code);
            if (entry) {
                return { ...appellation, ...entry };
            }
            return appellation;
        });
        state.allJobAppellations = newAllJobAppellations;
    }),


    fetchAllJobAppellationsProps: thunk(async (actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const propsArray = Object.values(ACTIONS.jobAppellations.getAllProps)
            .map(prop => prop.url);

        for (const url of propsArray) {
            axios.get(url)
                .then((data => {
                    actions.addJobAppellationsProp(data);
                }))
                .catch((error) => Promise.reject(error))
        }
    }),


    addProfessionalsProp: action((state, payload) => {
        if (!payload || payload.length === 0) return;
        const newAllProfessionals = state.allProfessionals.map(pro => {
            const entry = payload.find(prop => prop.uniqueId === pro.uniqueId);
            if (entry) {
                return { ...pro, ...entry };
            }
            return pro;
        });
        state.allProfessionals = newAllProfessionals;
    }),


    fetchAllProfessionals: thunk((actions, payload, helpers) => {
        const { isFetchingAllProfessionals } = helpers.getState();
        const { apiData } = helpers.getStoreState().actionSlugs;

        const getAllUrl = apiData.ACTIONS?.professionals?.readAll?.url;

        if (!isFetchingAllProfessionals) {
            actions.setIsFetchingAllProfessionals(true);
            return axios.get(getAllUrl)
                .then((data => {
                    actions.setAllProfessionals(data);
                    actions.fetchAllProfessionalsProps();
                    return data
                }))
                .catch((error) => {
                    return Promise.reject(error);
                })
                .finally(() => actions.setIsFetchingAllProfessionals(false))
        }
        else {
            return Promise.resolve();
        }
    }),




    fetchAllProfessionalsProps: thunk(async (actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const propsArray = Object.values(ACTIONS.professionals.getAllProps)
            .map(prop => prop.url);

        for (const url of propsArray) {
            axios.get(url)
                .then((data => {
                    actions.addProfessionalsProp(data);
                }))
                .catch((error) => Promise.reject(error))
        }
    }),



    setIsUpdatingTransversalPropThumbnail: action((state, payload) => {
        state.isUpdatingAreaOfInterestThumbnail = payload;
    }),

    updateOneTransversalPropThumbnail: thunk((actions, payload, helpers) => {
        const { isUpdatingTransversalPropThumbnail, allAreasOfInterest, allSkills, allLargeProfessionalFields } = helpers.getState();
        const { uniqueId, formData, entityName } = payload;
        const { apiData } = helpers.getStoreState().actionSlugs;
        const updateUrl = apiData.ACTIONS[entityName]?.updateThumbnail?.url.replace('code', uniqueId);

        if (!isUpdatingTransversalPropThumbnail) {
            actions.setIsUpdatingTransversalPropThumbnail(true);
            return axios.post(updateUrl, formData)
                .then((data => {
                    actions.setOneTransversalProp(data.oneROMEPropFull);
                    let allTransversalPropsName;

                    switch(entityName) {
                        case 'areasOfInterest':
                            allTransversalPropsName = "allAreasOfInterest";
                            break;
                        case 'skills':
                            allTransversalPropsName = "allSkills";
                            break;
                        case 'largeProfessionalFields':
                            allTransversalPropsName = "allLargeProfessionalFields";
                            break;
                        default:
                            break;
                    }
                    const payload = { allTransversalPropsName, allTransversalProps: data.oneROMEPropSimple }
                    actions.updateAllTransversalProps(payload);
                    actions.setIsUpdatingTransversalPropThumbnail(false);
                    return data
                }))
                .catch((error) => Promise.reject(new Error(error)))
        }
        else {
            return Promise.resolve();
        }
    }),

    fetchAllLargeProfessionalFields: thunk((actions, payload, helpers) => {
        const { isFetchingAllLargeProfessionalFields } = helpers.getState();
        const { apiData } = helpers.getStoreState().actionSlugs;

        const getAllUrl = apiData.ACTIONS?.largeProfessionalFields?.readAll?.url;

        if (!isFetchingAllLargeProfessionalFields) {
            actions.setIsFetchingAllLargeProfessionalFields(true);
            return axios.get(getAllUrl)
                .then((data => {
                    actions.setAllLargeProfessionalFields(data);
                    actions.setIsFetchingAllLargeProfessionalFields(false);
                    return data
                }))
                .catch((error) => {
                    return Promise.reject(new Error(error));
                })
        }
        else {
            return Promise.resolve();
        }
    }),


    fetchAllEntitiesRomeCount: thunk((actions, payload, helpers) => {
        const { isFetchingAllEntitiesRomeCount } = helpers.getState();
        const { apiData } = helpers.getStoreState().actionSlugs;

        const getUrl = apiData.ACTIONS?.romes?.count?.url;

        if (!isFetchingAllEntitiesRomeCount) {
            actions.setIsFetchingAllEntitiesRomeCount(true);
            actions.setHomeRomesAlreadySeen(false);
            return axios.get(getUrl)
                .then((data => {
                    actions.setAllEntitiesRomeCount(data);
                    return data
                }))
                .catch((error) => Promise.reject(new Error(error)))
                .finally(() => actions.setIsFetchingAllEntitiesRomeCount(false))
        }
        else {
            return Promise.resolve();
        }
    }),
}

export default romes;