import React, {useMemo, useState} from "react";
import { Link } from 'react-router-dom';
import { useStoreState, useStoreActions } from "easy-peasy";
import Loader from "../../../widgets/Loader";
import { OverlayTrigger, Tooltip, Button, Alert } from 'react-bootstrap';
import AuthorizationChecker from "../../../../services/AuthorizationChecker";
import { useModal } from "../../../../hooks/useModal";

import CreateUpdateUserComponent, {submitUserForm} from "../../../users/CreateUpdateUserComponent";
import CreateUserFromContactComponent, {submitContactToUserForm} from "../../../users/CreateUserFromContactComponent";

import DynamicTable from "../../../widgets/dynamicTable/DynamicTable";
import HelperMetiers360 from "../../../../services/HelpersMetiers360.js";
import AxonautLogoBubbleComponent from "../../../widgets/axonautLogoBubble/AxonautLogoBubbleComponent";
import { useToast } from "../../../../hooks/useToast.jsx";
import TransferUserComponent, {submitTransferUserForm} from "../../../users/TransferUserComponent.jsx";

const ClientContactsComponent = ({
    onSelect,
    client,
    updatable=true,
    downloadable,
    editable=false,
    allUsersEmails,
    clientUsersEmail,
    isNoContactSelected,
    selectedContact = null,
    filteredContacts = null,
    withParams
}) => {

    const { modalComponent, setModalShow, modalData, setModalData, setIsSending } = useModal();
    const [activeRowId, setActiveRowId] = useState(selectedContact);

    const { updateUser, createUser, setCreateFormErrors, deleteContact, deleteUser, transferUser, setSuperUserRole } = useStoreActions(actions => actions.users);
    const {apiData: {ACTIONS}} = useStoreState(state => state.actionSlugs);
    const clientActionSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('clients'));
    const oneClientSlug = clientActionSlugs && clientActionSlugs['readOne'] ? clientActionSlugs['readOne']:null;
    const toast = useToast();

    const allOtherUserEmails = useMemo(() => {
        return allUsersEmails
            ? allUsersEmails.map(u => u.email)
            : [];
    }, [allUsersEmails]);

    if(typeof client?.contacts != 'object') {return <Loader />}

    const contactToDisplay = filteredContacts !== null ? filteredContacts : client.contacts;

    const TooltipButton = ({ tooltipMessage, iconClass }) => (
        <OverlayTrigger
            placement="right"
            overlay={<Tooltip>{tooltipMessage}</Tooltip>}
        >
            <span>
                <Button variant="light" className="p-1 ml-2" size="sm" disabled>
                    <i className={iconClass}></i>
                </Button>
            </span>
        </OverlayTrigger>
    );

    const userStatus = (contact) => {
        if (contact.isRegisteredUser && clientUsersEmail?.includes(contact.email?.toLowerCase())) { // contact is user and part of the client's ones
            return (
                <div className="statusdiv">
                    <i className="fas fa-solid fa-circle fa-xs text-success" />
                    Accès donné
                    {contact?.canChangeClient ? ( // the contact can be transfered (has not playlist)
                        <Button 
                            variant="light" 
                            className="p-1 ml-2" 
                            size="sm" 
                            onClick={() => {transferModal(contact)}}
                        >
                            <i className="fas fa-angle-double-right m-0"></i>
                        </Button>
                    ) : (
                        <TooltipButton 
                            tooltipMessage="Cet accès n'est pas transférable car le contact est associé à une/des playlist(s)." 
                            iconClass="fas fa-angle-double-right m-0"
                        />
                    )}
                    {contact?.canChangeClient && !contact?.isAdmin ? ( //  the contact can be deleted (no playlist and no admin)
                        <Button 
                            variant="light" 
                            className="p-1 ml-2" 
                            size="sm" 
                            onClick={()=>createDeleteModalBOAccess(contact)}
                        >
                            <i className="fas fa-user-minus m-0"></i>
                        </Button>
                    ) : (
                        <TooltipButton 
                            tooltipMessage={
                                <div>
                                    L'accès ne peut pas être retiré car:
                                    <ul>
                                        {!contact.canChangeClient && <li>le contact a des playlists</li>}
                                        {contact.isAdmin && <li>le contact a un accès admin</li>}
                                    </ul>
                                </div>
                            } 
                            iconClass="fas fa-user-minus m-0"
                        />
                    )}
                    <Button variant="light" className="p-1 ml-2" size="sm" onClick={() => openSuperUserRoleModal(contact)}>
                        {contact?.isSuperUser
                            ? <i className="fas fa-solid fa-star m-0"></i>
                            : <i className="fas fa-regular fa-star m-0" style={{ fontWeight: "400" }}></i>}
                    </Button>
                </div>
            );
        } else if (clientUsersEmail?.includes(contact.email?.toLowerCase())) { // the contact is already a user of the client
                return (
                    <div className="statusdiv">
                        <i className="fas fa-solid fa-circle fa-xs text-warning"/>
                        Accès déjà donné chez ce client
                    </div>
                );
        } else if (allUsersEmails.some(u=> u.email === contact.email?.toLowerCase())) { // the contact is a user but not part of the client's ones
            const otherClient = allUsersEmails.find(u=> u.email === contact.email?.toLowerCase());
            return ( 
                    <OverlayTrigger
                    placement="right"
                    overlay={
                        <Tooltip>
                            {otherClient.clientName}
                        </Tooltip>
                    }    
                    >
                        <div className="statusdiv"><i className="fas fa-solid fa-circle fa-xs text-danger"/>
                            <Link to={oneClientSlug.replace(':uniqueId', otherClient.clientUniqueId)}>Accès donné chez un autre client</Link>
                        </div>
                    </OverlayTrigger>   
            );
        } else { // if none of these conditions are met the contact is not a user: an access can be created
            return (
            <div className="statusdiv">
                <Button variant="lightdanger" size="sm" onClick={() => {AddUserModal(contact)}}>
                    <i className="fas fa-plus mr-1"></i>&nbsp;Donner accès au back-office
                </Button>
            </div>
            );
        } 
    };


    const flatUserStatus = (contact) => {
        if (contact.isRegisteredUser && clientUsersEmail?.includes(contact.email?.toLowerCase())) { // contact is user and part of the client's ones
            return "Oui";
        } else if (clientUsersEmail?.includes(contact.email?.toLowerCase())) { // the contact is already a user of the client
            return "Oui";
        } else if (allUsersEmails.some(u => u.email === contact.email?.toLowerCase())) { // the contact is a user but not part of the client's ones
            return "Oui, chez un autre client";
        } else {
            return "Non";
        }
    };

    const sortType = [
        {
            value : 'email', label : 'E-mail',
            test : client?.contacts[0]?.email !== undefined,
            method: (a,b) =>  HelperMetiers360.localeCompareWithNullable(a.email, b.email),
            display: (contact) => contact.isAxonaut 
                ? <div className="d-flex align-items-center word-break-all">
                    <AxonautLogoBubbleComponent />
                    <span className="break-word">{contact.email ? contact.email : '-'}</span>
                </div>
                : (contact.email ? contact.email : '-'),
            flatDisplay: (contact) => contact.email ? contact.email + (contact.isSuperUser ? "★ (super utilisateur)" : "") : '-'
        },
        {
            value: 'lastName', label: "Nom",
            test : true,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.lastName, b.lastName),
            display: (contact) => contact.lastName ? contact.lastName : '-'
        },
        {
            value: 'firstName', label: "Prénom",
            test : true,
            method: (a, b) =>  HelperMetiers360.localeCompareWithNullable(a.firstName, b.firstName),
            display: (contact) => contact.firstName ? contact.firstName : '-'
        },
        {
            value: 'job', label: "Fonction",
            test : true,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.job, b.job),
            display: (contact) => contact.job ? contact.job : '-'
        },
        {
            value: 'phoneNumber', label: "Tel",
            test : true,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.phoneNumber, b.phoneNumber),
            display: (contact) => contact.phoneNumber ? contact.phoneNumber : '-'
        },
        {
            value: 'cellphoneNumber', label: "Mobile",
            test : true,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.cellphoneNumber, b.cellphoneNumber),
            display: (contact) => contact.cellphoneNumber ? contact.cellphoneNumber : '-'
        },
        {
            value: 'update', label: "Éditer",
            test : AuthorizationChecker.hasUpdateRights('users') && (updatable || editable),
            display: (contact) => <Button variant="secondary"  className="p-1 m-1" size="sm" onClick={() => {updateModal(contact)}}><i className="fas fa-edit"></i></Button>,
            flatDisplay: () => ''
        },
        {
            value: 'isRegisteredUser', label: "Accès back-office",
            test : client.isSubscriber && updatable && HelperMetiers360.isArrayContainsValue(contactToDisplay, "isRegisteredUser"),
            method: (a, b) =>  b?.isRegisteredUser - a?.isRegisteredUser,
            display: (contact) => userStatus(contact),
            flatDisplay: (contact) => flatUserStatus(contact)
        },
    ];
    
    if(AuthorizationChecker.hasDeleteRights('users')) {
        sortType.push({
            value: 'delete', label: "Supprimer",
            test : true,
            display: (contact) => {
                if(contact.isRegisteredUser || contact.isAxonaut) return <OverlayTrigger
                placement="left"
                overlay={
                    <Tooltip>
                        <ul>
                            {contact.isAxonaut && <li>Ce contact est issu d'Axonaut et ne peut pas être supprimé.</li>}
                            {contact.isRegisteredUser && <li>Ce contact dispose d'un accès BO.</li>}
                        </ul>
                    </Tooltip>
                }    
                >
                    <span><Button disabled variant="danger" size="sm" className="m-0 p-2"><i className="fas fa-trash-alt"></i></Button></span>
                </OverlayTrigger>;
                return <Button variant="danger" size="sm" className="m-0 p-2" onClick={() =>  deleteContactModal(contact)}><i className="fas fa-trash-alt"></i></Button>;
            },
            flatDisplay: (contact) => (contact.isAxonaut || contact.isRegisteredUser) ? '-' : 'X'
        });
    }

    const updateModal = (user) => {
        if(AuthorizationChecker.hasUpdateRights('users')) {
            const validate = () => {
                setIsSending(true);
                const {formName} = ACTIONS['users']['update'];
                return submitUserForm(
                    updateUser,
                    formName, 
                    user.uniqueId,
                    contactToDisplay?.filter(contact => contact.uniqueId !== user.uniqueId)?.map(c => c.email), 
                    user?.isRegisteredUser ? allOtherUserEmails.filter(email => email !== user.email) : null,
                    user?.isRegisteredUser,
                    true
                ).then(() => {
                    toast.success();
                    setModalShow(false);
                })
                .catch((error) => {
                    if (error?.formError) {
                        setCreateFormErrors(error.formError);
                    }
                })
                .finally(() => setIsSending(false));
            }
            setModalData({
                ...modalData,
                header: <>Édition d'un contact : {user.email}</>,
                content: <CreateUpdateUserComponent user={user} action="update" />,
                cancelButton: 'Annuler',
                validateButton: "Valider",
                onValidate: validate,
                size: 'lg'
            });
            setModalShow(true);
        }
    }



    const transferModal = (user) => {
        if(AuthorizationChecker.hasUpdateRights('users')) {
            const validate = () => {
                setIsSending(true);
                const {formName} = ACTIONS['users']['transfer'];
                return submitTransferUserForm(transferUser, formName ,user.uniqueId)
                    .then(() => {
                        toast.success();
                        setModalShow(false);
                    })
                    .finally(() => setIsSending(false));
            }
            setModalData({
                ...modalData,
                header: <>Transfert de l'utilisateur⋅rice {user.email}</>,
                content: <TransferUserComponent user={user}/>,
                cancelButton: 'Annuler',
                validateButton: "Valider",
                onValidate: validate,
                size: 'lg'
            });
            setModalShow(true);
        }
    }


    const AddUserModal = (contact) => {
        if(AuthorizationChecker.hasCreateRights('users')) {
            const validate = () => {
                setIsSending(true);
                const {formName} = ACTIONS['users']['createFromContact'];
                return submitContactToUserForm(createUser, formName ,contact.uniqueId)
                .then(() => {
                    toast.success();
                    setModalShow(false);
                })
                .finally(() => setIsSending(false));
            }
            setModalData({
                ...modalData,
                header: <>Donner un accès au back-office à {contact.email} </>, 
                content: <>
                    {contact.email
                        ? <>
                            <Alert variant="danger">En cliquant sur "Valider", {contact.email}&nbsp;
                            recevra un email lui permettant de s'inscrire au back-office et d'y avoir accès</Alert>
                            <CreateUserFromContactComponent />
                        </>
                        : <Alert variant="danger">
                            Veuillez donner une adresse email à votre contact pour l'ajouter aux utilisateur⋅rices
                        </Alert>
                    }
                </>,
                cancelButton: 'Annuler',
                onValidate: contact.email && validate,
                size: 'lg'
            });
            setModalShow(true);
        }
    }

    const createDeleteModalBOAccess = (contact) => {
        if(AuthorizationChecker.hasDeleteRights('users')) {
            setModalData({
                ...modalData,
                header: <>Retrait de l'accès BO</>,
                content: <h5>Êtes-vous sûr⋅e de vouloir retirer l'accès BO de <b>{contact.email}</b>&nbsp;?</h5>,
                resetButton: 'Retirer',
                cancelButton: 'Annuler',
                onReset: () => {
                    setIsSending(true);
                    deleteUser({ uniqueId: contact.uniqueId })
                        .then(() => {
                            toast.success();
                            setModalShow(false);
                        })
                        .finally(() => setIsSending(false));
                },
            });
            setModalShow(true);
        } 
    }

    const deleteContactModal = (contact) => {
        if(AuthorizationChecker.hasDeleteRights('users')) {
            setModalData({
                ...modalData,
                header: <>Suppression du contact</>,
                content: <h5>Êtes-vous sûr⋅e de vouloir supprimer ce contact : <b>{contact.email}</b>&nbsp;?</h5>,
                resetButton: 'Supprimer',
                cancelButton: 'Annuler',
                onReset: () => {
                    setIsSending(true);
                    deleteContact({ uniqueId: contact.uniqueId })
                        .then(() => {
                            toast.success();
                            setModalShow(false);
                        })
                        .finally(() => setIsSending(false));
                },
            });
            setModalShow(true);
        }
    }
    

    const openSuperUserRoleModal = (contact) => {
        if (AuthorizationChecker.isAdmin()) {
            setModalData({
                ...modalData,
                header: <>{contact.isSuperUser ? "Retirer" : "Accorder"} un accès en tant que super user.</>,
                content: <h5>Êtes-vous sûr de vouloir changer les droits de <b>{contact.email}</b>&nbsp;?</h5>,
                resetButton: contact.isSuperUser ? "Retirer" : "Accorder",
                cancelButton: 'Annuler',
                onReset: () => {
                    setIsSending(true);
                    setSuperUserRole({ uniqueId: contact.uniqueId, setToSuperUser: !contact.isSuperUser })
                        .then(() => {
                            toast.success();
                            setModalShow(false);
                        })
                        .finally(() => setIsSending(false));
                },
            });
            setModalShow(true);
        }
    }

    const handleClick = onSelect ? 
        (selectedId) => {
            onSelect(client.contacts.find( contact => contact.uniqueId == selectedId ));
            setActiveRowId(selectedId);
        }
        :null;

    return <>
        {isNoContactSelected && <Alert variant="danger">Sélectionnez un contact</Alert>}
        {client?.contacts?.length > 0
            ? <>
                <DynamicTable
                    contentTable={contactToDisplay}
                    contentSort={sortType}
                    valueInitSort="email"
                    index='uniqueId'
                    downloadable={downloadable}
                    handleClick = {handleClick}
                    activeRowId={activeRowId}
                    withParams={withParams}
                    filename="contacts"
                />
                {modalComponent}
            </>
            : <Alert variant="danger" className="mt-2">Ce client n'a pas de contacts</Alert>}
    </>
}

export default ClientContactsComponent