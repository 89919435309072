import React from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { Image, OverlayTrigger, Tooltip, Button } from "react-bootstrap";

import HelperMetiers360 from "../../../../services/HelpersMetiers360.js";

import DynamicTable from "../../../widgets/dynamicTable/DynamicTable.jsx";
import TooltipListComponent from "../../../widgets/tooltipList/TooltipListComponent.jsx";
import AuthorizationChecker from "../../../../services/AuthorizationChecker.js";

/**
 * 
 * @param {Array} pedagogicModules array of all pedagogicModules to be rendered
 * @param {String} readAll  readAll slug to access the pedagogic module list page
 * @param {String} readOne  readOne slug to access a pedagogic module details page - replace the :uniqueId part of string with actual uniqueId
 * @param {String} create  create slug to access the pedagogic module creation page - optional, need according rights
 * @param {String} update  update slug to access the pedagogic module update page - replace the :uniqueId part of string with actual uniqueId  - optionnal, need according rights
 
 * @example
 *  <PedagogicModuleTableComponent pedagogicModules={pedagogicModules} readAll={readAllSlug} create={createSlug} {...}/>
 * 
 */

const PedagogicModuleTableComponent = ({ pedagogicModules, withParams}) => {

    const pedagogicModulesSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('pedagogicModules'));
    const {pingDownloadEvent} = useStoreActions(actions => actions.pedagogicModules);

    const navigate = useNavigate();
    const location = useLocation();

    const pedagogicModulesToDisplay = pedagogicModules ? pedagogicModules : [];

    

    const getListVideos = (pedagogicModule) => {
        return pedagogicModule.videos ? pedagogicModule.videos.length : 0;
    };

    const getListQuizzes = (pedagogicModule) => {
        return pedagogicModule.quizzes ? pedagogicModule.quizzes.length : 0;
    };

    const getListTag = (pedagogicModule) => {
        return pedagogicModule.tags ? pedagogicModule.tags.length : 0;
    };

    const getListGroupSize = (pedagogicModule) => {
        return pedagogicModule.groups ? pedagogicModule.groups.length : 0;
    };

    if (pedagogicModules.length === 0) { return <></>; }

    const sortType = [
        {
            value: 'image', label: 'Vignette',
            test: pedagogicModulesToDisplay[0].links.image !== undefined,
            method: null,
            display: (pedagogicModule) => (
                <OverlayTrigger
                    placement='right'
                    overlay={
                        <Tooltip>
                            <Image src={pedagogicModule.links.image} />
                        </Tooltip>
                    }
                >
                    <Image src={pedagogicModule.links.image} className="img-overlay" thumbnail />
                </OverlayTrigger>
            ),
            flatDisplay : (pedagogicModule) => pedagogicModule.links.image
        },
        {
            value: 'name', label: 'Nom',
            test: pedagogicModulesToDisplay[0].name !== undefined,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.name, b.name),
            display: (pedagogicModule) => pedagogicModule.name
        },
        {
            value: 'duration', label: 'Durée',
            test: pedagogicModulesToDisplay[0].duration !== undefined,
            method: (a, b) => a.duration - b.duration,
            display: (pedagogicModule) => HelperMetiers360.formatDuration(pedagogicModule.duration)
        },
        {
            value: 'groups', label: 'Groupes',
            test: AuthorizationChecker.isAdmin() && pedagogicModulesToDisplay[0].groups !== 'undefined',
            method: (a, b) => getListGroupSize(a) - getListGroupSize(b),
            display: (pedagogicModule) => {
                if (!pedagogicModule.groups.length) {
                    return 0
                }
                
                const generateIdCallback = (uniqueId) => {
                    return `pedagogicModule-${uniqueId}`;
                }

                return <TooltipListComponent
                    inputList={pedagogicModule.groups}
                    keyToDisplay='name'
                    generateIdCallback={generateIdCallback}
                 />
            },
            flatDisplay: (pedagogicModule) => pedagogicModule.groups?.length
        },
        {
            value: 'videos', label: 'Videos',
            test: AuthorizationChecker.isAdmin() && pedagogicModulesToDisplay[0].videos !== undefined,
            method: (a, b) => getListVideos(a) - getListVideos(b),
            display: (pedagogicModule) => {
                if (pedagogicModule.videos.length === 0) {
                    return 0
                }

                const generateIdCallback = (uniqueId) => {
                    return `video-${uniqueId}`;
                }

                return <TooltipListComponent
                    inputList={pedagogicModule.videos}
                    keyToDisplay='videoName'
                    generateIdCallback={generateIdCallback}
                 />
            },
            flatDisplay: (pedagogicModule) => pedagogicModule.videos?.length
        },
        {
            value: 'quizzes', label: 'Quiz',
            test: AuthorizationChecker.isAdmin() && pedagogicModulesToDisplay[0].quizzes !== undefined,
            method: (a, b) => getListQuizzes(a) - getListQuizzes(b),
            display: (pedagogicModule) => {
                if (pedagogicModule.quizzes.length === 0) {
                    return 0
                }

                const generateIdCallback = (uniqueId) => {
                    return `quiz-${uniqueId}`;
                }

                return <TooltipListComponent
                    inputList={pedagogicModule.quizzes}
                    keyToDisplay='name'
                    generateIdCallback={generateIdCallback}
                 />
            },
            flatDisplay: (pedagogicModule) => pedagogicModule.quizzes?.length
        },
        {
            value: 'tags', label: 'Tags',
            test: AuthorizationChecker.isAdmin() && pedagogicModulesToDisplay[0].tags !== undefined,
            method: (a, b) => getListTag(a) - getListTag(b),
            display:  (pedagogicModule) => {
                if (pedagogicModule.tags.length === 0) {
                    return 0
                }

                const generateIdCallback = (uniqueId) => {
                    return `tag-${uniqueId}`;
                }

                return <TooltipListComponent
                    inputList={pedagogicModule.tags}
                    keyToDisplay='name'
                    generateIdCallback={generateIdCallback}
                 />
            },
            flatDisplay: (pedagogicModule) => pedagogicModule.tags?.length
        },
        {
            value: 'pdfFile', label: 'PDF',
            test: pedagogicModulesToDisplay[0].pdfFile !== undefined,
            method: (a, b) => a.pdfFile - b.pdfFile,
            display: (pedagogicModule) => pedagogicModule.pdfFile == null ? 
                '-' 
                : <Button as="a" bsPrefix={'no-btn'} onClick={() => {onDownloadPdf(pedagogicModule.uniqueId,pedagogicModule.pdfFile)}}>Voir le fichier PDF</Button>,
            flatDisplay: (pedagogicModule) =>  pedagogicModule.pdfFile ? pedagogicModule.pdfFile:'-'
            
        },
        {
            value: 'downloadCount', label: <i className="fas fa-download"/>,
            test: pedagogicModulesToDisplay[0].downloadCount !== undefined,
            method: (a, b) => a.downloadCount - b.downloadCount,
            display: (pedagogicModule) => pedagogicModule.downloadCount ? pedagogicModule.downloadCount:0
        },
        {
            value: 'createdAt', label: 'Publication',
            test: pedagogicModulesToDisplay[0].createdAt !== undefined,
            method: (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
            display: (pedagogicModule) => HelperMetiers360.getdisplayDateType(pedagogicModule.createdAt, 'day')
        },
        {
            value: 'updatedAt', label: 'Mise à jour',
            test: pedagogicModulesToDisplay[0].updatedAt !== undefined,
            method: (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
            display: (pedagogicModule) => HelperMetiers360.getdisplayDateType(pedagogicModule.updatedAt, 'day')
        },
    ];

    const onDownloadPdf = (uniqueId, url) => {
        pingDownloadEvent(uniqueId)
            .then(() => window.open(url));
    }

    const handleClick = (uniqueId) => {
        if(pedagogicModulesSlugs.readOne) {
            navigate(pedagogicModulesSlugs.readOne.replace(':uniqueId', uniqueId), { state: { olderSearchParams: location.search } });
        }
    }

    return <DynamicTable
        contentTable={pedagogicModulesToDisplay}
        contentSort={sortType}
        valueInitSort="createdAt"
        index='uniqueId'
        handleClick={handleClick}
        withParams={withParams}
        tableName="pedagogicModulesTable"
        filename="modules_pedagogiques"
    />;
}
export default PedagogicModuleTableComponent;