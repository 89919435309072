import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import jobsToolImage from '../../images/lab_jobs_tool.png';
import labImage from '../../images/lab_tester.jpg';
import './TesterComponent.scss'

const TesterComponent = () => {
    return <>
        <Row>
            <Col>
                <h4>Bienvenue au Lab !</h4>
                <h5 className="mt-4">Vous faites partie du Lab, notre communauté d’utilisateur·ices qui ont envie de partager leurs avis et bonnes idées.</h5>
                <div className="text-lab">
                    <p className="fw-bold mb-1 mt-4">En tant que bêta-testeur ou bêta-testeuse, vous pouvez avoir accès à :</p>
                    <ul>
                        <li>de nouvelles fonctionnalités ;</li>
                        <li>de nouveaux formats d'expériences immersives ;</li>
                        <li>de nouvelles modalités d'accompagnement ;</li>
                        <li>et bien d’autres choses encore au fil des sujets dont nous voulons discuter avec vous !</li>
                    </ul>
                    <p className="mt-4">Prenons le temps d’échanger et réfléchissons ensemble aux outils et ressources les plus adaptés aux besoins de vos publics. Peut-être que certaines pistes que vous aurez proposées verront le jour !</p>
                </div>
            </Col>

            <Col xl={12} xxl={6} className="col-img-lab">
                <img src={labImage} className="lab-image" />
            </Col>
        </Row>
        <Row>
            <div className="text-lab">
                <h4>Les tests en cours :</h4>
                <ul>
                    <li><b>Onglet “Exploration métiers” sur le Backoffice</b></li>
                </ul>
                <p>Découvrez <b>"Exploration métiers"</b> en avant-première !<br />
                    Indexée sur la classification Rome 4.0, cette boîte à outils vous permet de trier nos
                    expériences immersives selon différents critères et de faire des liens entre elles.</p>
                <u>Les questions que l'on se pose :</u>
                <ul>
                    <li>Quelles ont été vos impressions en découvrant cet outil ?</li>
                    <li>Comment envisagez-vous de l’utiliser avec vos publics ?</li>
                    <li>Est-ce que vous y avez trouvé tout ce que vous cherchez ?</li>
                </ul>
                <img src={jobsToolImage} className="lab-image content-img" />
                <br />
                <br />
                <ul>
                    <li><b>Ressources PDF auxquelles vous avez accès</b></li>
                </ul>
                <p>Parcourez les différentes ressources imprimables présentes sur le Backoffice et dites-nous ce que vous en pensez !</p>
                <u>Où les trouver ?</u>
                <ul>
                    <li>Vue casque {'>'} choix d’un groupe {'>'} “contenu de l’espace immersif” et “vignettes de l’espace immersif”</li>
                    <li>Expériences immersives {'>'} “catalogue”, “par métier”, “par code ROME”</li>
                    <li>Statistiques {'>'} “activité casques”, “activités webapp”</li>
                    <li>Modules pédagogiques {'>'} choix d’un module {'>'} “télécharger le pdf”</li>
                </ul>
                <u>Les questions que l'on se pose :</u>
                <ul>
                    <li>Est-ce que vous les connaissez toutes </li>
                    <li>Dans quels contextes ces ressources vous sont-elles utiles ?</li>
                    <li>Que voudriez-vous changer ?</li>
                </ul>

                <div className="lab-end-card">
                    <b><u>Pour nous faire part de vos retours :</u></b>
                    <p>Envoyez-nous un mail, participez aux rendez-vous du Lab qui ont lieu une fois par mois
                        (invitations envoyées par nos soins) et/ou réservez un créneau dans notre agenda ! </p>
                    <Button
                        variant="primary"
                        href="https://calendar.app.google/4ckGNCRacwFVUPuKA"
                        target="blank">
                        J'accède à l'agenda
                    </Button>
                </div>

                <h4 className="mt-4">Merci, nous avons hâte de voir ce que vous en pensez !</h4>
            </div>
        </Row>
    </>
}
export default TesterComponent;