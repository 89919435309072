import React, { useRef, useState, useEffect } from "react";
import { Container, Row, Col, Form, InputGroup, Button, Alert, Card } from "react-bootstrap";
import Loader from "../../components/widgets/Loader";
import { useStoreActions, useStoreState } from 'easy-peasy';
import axios from "../../services/axios";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState.jsx";
import RomesPageContent from "../rome/RomesPageContent.jsx";
import ProfessionalsPageContent from "../rome/ProfessionalsPageContent.jsx";
import TooltipInfo from "../../components/widgets/tooltipInfo/TooltipInfo.jsx";
import { useToast } from "../../hooks/useToast.jsx";

const RomeoTestPage = () => {
    const { allRomes, isFetchingAllRomes } = useCustomGetStoreState("romes");
    const { allProfessionals, isFetchingAllProfessionals } = useStoreState(state => state.romes);
    const { fetchAllProfessionals } = useStoreActions(actions => actions.romes);
    const { apiData: { ACTIONS } } = useStoreState(state =>state.actionSlugs);

    const [isFetching, setIsFetching] = useState(false);

    const [filteredProfessionals, setFilteredProfessionals] = useState();
    const [filteredRomes, setFilteredRomes] = useState();

    const searchMain = useRef('');
    const searchContext = useRef('');

    const [searchType, setSearchType] = useState('both');
    const [isRomesOpen, setIsRomesOpen] = useState(false);
    const [isProsOpen, setIsProsOpen] = useState(false);
    const [errors, setErrors] = useState([]);
    const [feedbackSent, setFeedbackSent] = useState(false);
    const toast = useToast();

    useEffect(() => {
        if (!allProfessionals?.length && !isFetchingAllProfessionals) {
            fetchAllProfessionals();
        }
    }, [allProfessionals, isFetchingAllProfessionals])

    const resetResults = () => {
        setFilteredRomes();
        setFilteredProfessionals();
    }

    const searchRomeo = () => {

        if (!searchMain.current?.value || searchMain.current?.value == '') {
            setErrors(['Indiquez le champs métiers']);
            return;
        }

        setIsFetching(true);
        resetResults();
    
        const getUrlJob = ACTIONS?.romes?.askRomeo?.job?.url;
        const formDataJob = new FormData();
        formDataJob.set('token',ACTIONS?.romes?.askRomeo?.job?.csrfToken);
        formDataJob.set('jobTitle', searchMain.current.value);
        formDataJob.set('context', searchType !=='skill' && searchType !=='jobAppellation' ? searchContext.current.value:'');
        const jobRequest = axios.post(getUrlJob, formDataJob);

        const getUrlSkill = ACTIONS?.romes?.askRomeo?.skill?.url;
        const formDataSkill = new FormData();
        formDataSkill.set('token',ACTIONS?.romes?.askRomeo?.skill?.csrfToken);
        formDataSkill.set('skill', `${searchMain.current.value}. ${searchContext.current?.value ?? ""}`);
        const skillRequest = axios.post(getUrlSkill, formDataSkill);

        const getUrlJobAppellation = ACTIONS?.jobAppellations?.search?.url;
        const formDataJobAppellation = new FormData();
        formDataJobAppellation.set('token',ACTIONS?.jobAppellations?.search?.csrfToken);
        formDataJobAppellation.set('appellationQuery', searchMain.current.value);
        const jobAppellationRequest = axios.post(getUrlJobAppellation, formDataJobAppellation);

        const requests = {
            'job':[jobRequest],
            'both':[jobRequest, skillRequest],
            'skill':[skillRequest],
            'jobAppellation':[jobAppellationRequest],
            'all':[jobRequest, skillRequest,jobAppellationRequest]
        }[searchType];

        Promise.all(requests)
            .then((responses) => {
                const jobResponse = {
                    'job':responses[0],
                    'both':responses[0],
                    'skill':null,
                    'jobAppellation':null,
                    'all':responses[0]
                }[searchType];
                const skillResponse = {
                    'job':null,
                    'both':responses[1],
                    'skill':responses[0],
                    'jobAppellation':null,
                    'all':responses[1]
                }[searchType];
                const jobAppellationResponse = {
                    'job':null,
                    'both':null,
                    'skill':null,
                    'jobAppellation':responses[0],
                    'all':responses[2]
                }[searchType];

                const romeFromJobs = new Set(jobResponse?.map(job => job.codeRome));
                const romeFromSkills = new Set(skillResponse?.map(romeCode => romeCode));
                const romeFromJobAppellations = new Set(jobAppellationResponse?.map(romeCode => romeCode));

                const romesResult = allRomes?.reduce((acc, r) => {
                    const from = [];

                    if (romeFromJobs.has(r.codeRome)) {
                        from.push('métier');
                    }
                    if (romeFromSkills.has(r.codeRome)) {
                        from.push('compétence');
                    }
                    if (romeFromJobAppellations.has(r.codeRome)) {
                        from.push('appellation');
                    }

                    if (from.length) {
                        acc.push({ ...r, from });
                    }

                    return acc;
                }, []);
                setFilteredRomes(romesResult);

                const professionalResult = allProfessionals?.reduce((acc, pro) => {
                    const from = [];

                    if (romeFromJobs.has(pro.codeRome)) {
                        from.push('métier');
                    }
                    if (romeFromSkills.has(pro.codeRome)) {
                        from.push('compétence');
                    }
                    if (romeFromJobAppellations.has(pro.codeRome)) {
                        from.push('appellation');
                    }

                    if (from.length) {
                        acc.push({ ...pro, from });
                    }

                    return acc;
                }, []);
                setFilteredProfessionals(professionalResult);
            })
            .finally(() => {
                setIsFetching(false);
                setFeedbackSent(false)
            });
    }

    const sendFeedback = (feedback) => {
        if (!feedbackSent) {
            const postFeedbackUrl = ACTIONS?.romes?.askRomeo?.shareFeedback?.url?.replace('opinion', !!feedback ? 'positive' : 'negative');
            const formDataJob = new FormData();
            formDataJob.set('token', ACTIONS?.romes?.askRomeo?.shareFeedback?.csrfToken);
            formDataJob.set('jobTitle', searchMain.current.value);
            formDataJob.set('context', searchType !=='skill' ? searchContext.current.value:'');
            formDataJob.set('prosList', JSON.stringify(filteredProfessionals));
            formDataJob.set('romesList', JSON.stringify(filteredRomes));
            formDataJob.set('searchOn', searchType);
            axios.post(postFeedbackUrl, formDataJob)
                .then(() => {
                    setFeedbackSent(true);
                    toast.open({ message: "Merci d'avoir transmis votre avis", variant: "success" });
                })
                .catch(() => toast.open({ message: "Erreur: avis non transmis", variant: "danger" }));
        }
    }

    const switchSearchType = (type) => {
        resetResults();
        setSearchType(type);
    }

    return <Container fluid>
        <Row>
            <Col>
                <h2 className="d-flex">
                    Recherche
                    <TooltipInfo description="Recherchez des codes ROME / pros avec l'IA et France Travail" />
                </h2>
            </Col>
        </Row>
        <Row>
            <Col className="m360-col3-center" md={12}>
                <div className="d-flex align-items-center">
                    <label className="label-select">Type de recherche&nbsp;: </label>
                    <div className='choices-selector-inline'>
                        <div className={searchType === 'job' ? 'selected' : null}
                            onClick={() => switchSearchType('job')} onKeyDown={() => switchSearchType('job')}>
                            Métiers</div>
                        <div className={searchType === 'both' ? 'selected' : null}
                            onClick={() => switchSearchType('both')} onKeyDown={() => switchSearchType('both')}>
                            Métiers + compétences</div>
                        <div className={searchType === 'skill' ? 'selected' : null}
                            onClick={() => switchSearchType('skill')} onKeyDown={() => switchSearchType('skill')}>
                            Compétences</div>
                        <div className={searchType === 'jobAppellation' ? 'selected' : null}
                            onClick={() => switchSearchType('jobAppellation')} onKeyDown={() => switchSearchType('jobAppellation')}>
                            Appellations</div>
                        <div className={searchType === 'all' ? 'selected' : null}
                            onClick={() => switchSearchType('all')} onKeyDown={() => switchSearchType('all')}>
                            Combo ultime</div>
                    </div>
                </div>
            </Col>
        </Row>
        <Row className="m-2 mb-4">
            <Col md={12} lg={6}>
                <InputGroup.Text id="mainContent" className="w-100">
                    {
                        {
                            'job':<>Métier</>,
                            'both':<>Métier, tâches, activités...</>,
                            'skill':<>tâches, activités...</>,
                            'jobAppellation':<>Appellation métier</>,
                            'all':<>Métier, tâches, activités...</>
                        }[searchType]
                    }*
                <Form.Control
                        id="mainBar"
                        type='text'
                        placeholder={"Ce que j'aimerais faire"}
                        ref={searchMain}
                        aria-describedby="mainBarPreprend"
                        onChange={() => setErrors([])}
                 />
                </InputGroup.Text>
                <p><b>Ex : </b><em>Récolter des fruits, travailler le bois, garagiste...</em></p>
            </Col>
            <Col md={12} lg={6}>
                {(searchType === 'job' || searchType ==='both' || searchType === 'all') && <><InputGroup.Text id="ContextGroupPrepend" className="w-100">
                Contexte
                <Form.Control
                    id="ContextBar"
                    type='text'
                        placeholder={"Lieu, environnement de travail, public..."}
                    ref={searchContext}
                    aria-describedby="ContextGroupPrepend"
                 />
                </InputGroup.Text>
                <p><b>Ex : </b><em>Dans la nature, sur des chantiers, en milieu scolaire...</em></p></>}
            </Col>
        </Row>
        {errors?.length > 0 && <Row className="w-100 d-flex justify-content-center text-danger mb-4">
            {errors?.join()}
        </Row>}
        <Row className="w-100">
            <Col className="d-flex justify-content-center">
                <Button onClick={searchRomeo}>Rechercher</Button>
            </Col>
        </Row>
        {isFetching ? 
            <Loader />
            : <>
                <h2 className="d-flex align-items-center justify-content-between w-100">Résultats
                    {
                        (!!filteredProfessionals && !!filteredRomes) && <span className="d-flex align-items-center justify-content-between" >
                            <p className="m-0 p-0">Je suis satisfait de ces résultats</p>
                            <Button size="sm" variant="success" disabled={feedbackSent} className="m-1 p-2" onClick={() => sendFeedback(true)}><i className="fas fa-thumbs-up" /></Button>
                            <Button size='sm' variant="danger" disabled={feedbackSent} className="m-1 p-2" onClick={() => sendFeedback(false)}><i className="fas fa-thumbs-down" /></Button>
                        </span>
                    }
                </h2>
                {!filteredProfessionals && !filteredRomes ?
                    <Alert variant="warning">Veuillez lancer une recherche</Alert>
                    : <>
                        <Row className="m-2">
                            <div>
                                <Card.Title
                                    className="associated_card_title d-flex justify-content-between cursor-pointer"
                                    onClick={() => setIsRomesOpen(!isRomesOpen)}>
                                    <h3>Codes ROME ({filteredRomes?.length})</h3>

                                    {isRomesOpen
                                        ? <i className="fa fa-angle-up fa-lg"></i>
                                        : <i className="fa fa-angle-down fa-lg"></i>
                                    }
                                </Card.Title>
                                {isRomesOpen
                                    && (filteredRomes?.length > 0 ?
                                        <RomesPageContent romes={filteredRomes} isFetching={isFetchingAllRomes} />
                                        : <Alert variant="warning">Pas de codes ROME correspondant</Alert>)
                                }
                            </div>
                        </Row>
                        <Row className="m-2">
                            <div>
                                <Card.Title
                                    className="associated_card_title d-flex justify-content-between cursor-pointer"
                                    onClick={() => setIsProsOpen(!isProsOpen)}>
                                    <h3>Pros  ({filteredProfessionals?.length})</h3>

                                    {isProsOpen
                                        ? <i className="fa fa-angle-up fa-lg"></i>
                                        : <i className="fa fa-angle-down fa-lg"></i>
                                    }
                                </Card.Title>
                                {isProsOpen
                                    && (filteredProfessionals?.length > 0 ?
                                        <ProfessionalsPageContent professionals={filteredProfessionals} isFetching={isFetchingAllProfessionals} />
                                        : <Alert variant="warning">Pas de Pros correspondant</Alert>)
                                }
                            </div>

                        </Row>
                    </>
                }
            </>
        }
        </Container>;

}

export default RomeoTestPage;