import React, { useState } from "react";
import { Form } from 'react-bootstrap';
import useCustomGetStoreState from "../../../hooks/useCustomGetStoreState";
import SelectMultipleItemsWithSearchBar from "../../widgets/selectMultipleItemsWithSearchBar/SelectMultipleItemsWithSearchBar";
import { useStoreState } from "easy-peasy";
import PropTypes from 'prop-types';

const FormRomesComponent = ({ video }) => {
    const { allRomes } = useCustomGetStoreState("romes");
    const { apiData: { ACTIONS } } = useStoreState(state => state.actionSlugs);
    const token = ACTIONS?.videos?.editRome?.csrfToken;

    const [selectedRomes, setSelectedRomes] = useState(video.romesFromVideo ?? []);

    return <Form id="video_rome_codes_form">
        <Form.Control name="csrf_token" defaultValue={token} type="hidden" />
        <Form.Group className="formSection d-flex flex-column">
            <Form.Label>Codes ROME</Form.Label>
            <SelectMultipleItemsWithSearchBar
                idSelector='video_romes[]'
                allItems={allRomes}
                selectedItemKeys={selectedRomes}
                setSelectedItemKeys={setSelectedRomes}
                itemKey="codeRome" itemValue="codeRome"
                itemValueDescription="label"
            />
        </Form.Group>
    </Form>;
}

export const submitRomesVideoForm = ({ videoUniqueId, action }) => {
    const formData = new FormData(document.getElementById('video_rome_codes_form'));
    const payload = { uniqueId: videoUniqueId, formData: formData };
    return action(payload);
}

FormRomesComponent.propTypes = {
    video: PropTypes.object.isRequired,
};

export default FormRomesComponent;