import { action, actionOn, thunk } from "easy-peasy";
import axios from '../services/axios';

const videosInitialState = {
    propsFetched: {},
    allVideos: [],
    videoById: null,
    isFetchingAllVideos : false,
    isGradualFetchingAllVideos : false,
    isGradualFetchVideosNecessary: true,
    isUpdateNecessary: false,
    initialFetchDone: false,
    isFetchingOneVideo: false,
}

const videos = {
    ...videosInitialState, 

    onGlobalResetCalled: actionOn(
        (actions, storeActions) => [
            storeActions.resetStore.performReset,
        ], 
        (state, target) => {
            const [performReset] = target.resolvedTargets;
            
            if (target.type === performReset) {
                ({
                    propsFetched: state.propsFetched,
                    allVideos: state.allVideos,
                    videoById: state.videoById,
                    isFetchingAllVideos: state.isFetchingAllVideos,
                    isGradualFetchVideosNecessary: state.isGradualFetchVideosNecessary,
                    isGradualFetchingAllVideos: state.isGradualFetchingAllVideos,
                    isUpdateNecessary: state.isUpdateNecessary,
                    initialFetchDone: state.initialFetchDone,
                    isFetchingOneVideo: state.isFetchingOneVideo,
                } = videosInitialState);
            }
        }
        ),
        
        
    // handling all videos
    setInitialFetchDone: action((state, payload) => {
       state.initialFetchDone = payload; 
    }),
        
    setIsUpdateNecessary: action((state, payload) => {
        state.isUpdateNecessary = payload;
    }),
    setIsGradualFetchVideosNecessary: action((state, payload) => {
        state.isGradualFetchVideosNecessary = payload;
    }),
    setPropsFetched: action((state, payload) => {
        state.propsFetched[payload] = true;
    }),
    setAllVideos: action((state, payload) => {
        state.allVideos = payload;
    }),
    setIsFetchingAllVideos: action((state, payload) => {
        state.isFetchingAllVideos = payload;
    }),
    setIsGradualFetchingAllVideos: action((state, payload) => {
        state.isGradualFetchingAllVideos = payload;
    }),

    fetchVideos: thunk((actions, payload, helpers) => {
        const { isFetchingAllVideos, isUpdateNecessary } = helpers.getState();
        const {apiData} = helpers.getStoreState().actionSlugs;

        const getAllUrl = apiData.ACTIONS?.videos?.readAll?.url;
        if( !isFetchingAllVideos && isUpdateNecessary) {
           actions.setIsFetchingAllVideos(true);
            return axios.get(getAllUrl)
                .then((data) => {
                    actions.setAllVideos(data);
                    actions.setIsFetchingAllVideos(videosInitialState.isFetchingAllVideos);
                    actions.setIsUpdateNecessary(videosInitialState.isUpdateNecessary);
                    return data;
                })
                .catch(error => {
                    return Promise.reject(error)
                });
        }
        else {
            return Promise.resolve();
        }
    }),

    updateVideosProps: thunk((actions, payload, helpers) => {
        const allVideos = helpers.getState().allVideos;
      
        const newAllVideos = allVideos.map(oldVideo => {
            const newVideo = payload.find(video => video.uniqueId === oldVideo.uniqueId);
            return { ...oldVideo, ...newVideo }
        });
        actions.setAllVideos(newAllVideos);
    }),
    fetchVideosProps: thunk((actions, payload) => {
        const prop = payload;

        if (prop?.url) {
            return axios.get(prop?.url)
                .then(data => {
                    actions.updateVideosProps(data);
                    actions.setPropsFetched(prop?.name);
                    return data;
                })
                .catch((error) => {
                    return Promise.reject(error);
                })
        }
    }),
    fetchAllPropsForAllVideos: thunk((actions, payload, helpers) => {
        return actions.fetchVideos()
            .then((res) => {
                if(res) {
                    actions.setIsGradualFetchingAllVideos(true);
                    actions.gradualFetchVideosData()
                        .then(()=>{
                            actions.setIsGradualFetchingAllVideos(false);
                        });
                }
            })
            .catch(error => Promise.reject(error));
    }),
    gradualFetchVideosData: thunk((actions, payload, helpers) => {
        const { apiData } = helpers.getStoreState().actionSlugs;
        const { propsFetched, isGradualFetchVideosNecessary } = helpers.getState();

        if(isGradualFetchVideosNecessary && apiData.ACTIONS.videos) {
            const propsForAll = apiData.ACTIONS.videos.getPropsForAll ;
            const gradualAccessRoutes = propsForAll 
                ? Object.keys(propsForAll).map(keyProp => {
                        return {
                            name: keyProp,
                            url: propsForAll[keyProp].url,
                        };
                    })
                : [];
            if(gradualAccessRoutes.length > 0) {
                let gradualFetchToBeDone = [];
                gradualAccessRoutes.forEach(prop => {
                    if(propsFetched[prop?.name] === undefined) {
                        gradualFetchToBeDone.push(actions.fetchVideosProps(prop));
                    }
                });
                return Promise.all(gradualFetchToBeDone).then(function(results) {
                    actions.setIsGradualFetchVideosNecessary(false);
               })
            } else {
                return Promise.resolve();
            }
        } else {
            return Promise.resolve();
        }
    }),

    // ACTIONS TO HYDRATE STORE INSTEAD OF REFETCHING LIST OF VIDEOS

    addVideo: action((state, payload) => {
        state.allVideos.push(payload);
    }),

    updateAllVideos: action((state, payload) => {
        const index = state.allVideos.findIndex((video) => video.uniqueId === payload.uniqueId);

        state.allVideos[index] = {
            ...state.allVideos[index],
            ...payload,
        };

    }),

    removeFromAllVideos: action((state, payload) => {
        const indexToRemove = state.allVideos.findIndex(video => video.uniqueId === payload);
        state.allVideos.splice(indexToRemove, 1);
    }),

    // handling one video
    setVideoById: action((state, payload) => {
        state.videoById = payload;
    }),
    setIsFetchingOneVideo: action((state, payload) => {
        state.isFetchingOneVideo = payload;
    }),

    fetchVideoById: thunk((actions, payload, helpers) => {
        const { isFetchingOneVideo} = helpers.getState();
        const apiData = helpers.getStoreState().actionSlugs.apiData;


        const getOneUrl = apiData.ACTIONS['videos']['readOne'].url.replace('uniqueId', payload);

        if (!isFetchingOneVideo) {
            actions.setIsFetchingOneVideo(true);
            actions.setVideoById(null);

            return axios.get(getOneUrl)
                .then((data) => {
                    actions.setIsFetchingOneVideo(videosInitialState.isFetchingOneVideo);
                    actions.setVideoById(data);
                })
                .catch(error => Promise.reject(error));
        } else {
            return Promise.resolve();
        }
    }),


    // posting one video
    postVideo: thunk((actions, payload, helpers) => { 
        const apiData = helpers.getStoreState().actionSlugs.apiData;


        const createVideoUrl = apiData.ACTIONS?.videos?.create?.url;
        const { formData } = payload;

        return axios.post(createVideoUrl, formData)
        .then((result) => {
            actions.addVideo(result);
            actions.setVideoById(videosInitialState.videoById);
            return result;
        }).catch(e => {
            return Promise.reject(e);
        });
    }),

    // update one video
    updateVideo: thunk((actions, payload, helpers) => {
        const { formData, uniqueId } = payload;
        const apiData = helpers.getStoreState().actionSlugs.apiData;
        
        const updateVideoUrl = apiData.ACTIONS['videos']['update'].url.replace('uniqueId', uniqueId);

        return axios.post(updateVideoUrl, formData )
            .then((result) => {
                actions.updateAllVideos(result);
                actions.setVideoById(videosInitialState.videoById)
                return result;
            })
            .catch(error => {
                return Promise.reject(error);
            })
    }),

    // add video's length information
    addVideoLength: thunk((actions, payload, helpers) => {
        const { uniqueId, videoLength } = payload;
        const apiData = helpers.getStoreState().actionSlugs.apiData;
        
        const addVideoLengthUrl = apiData.ACTIONS['videos']['addLength'].url.replace('uniqueId', uniqueId);

        return axios.post(addVideoLengthUrl, { videoLength: videoLength })
        .then((result) => {
            actions.updateAllVideos(result);
            return result;
        })
        .catch(error => {
            return Promise.reject(error);
        })
    }),

    editVideoRomes: thunk((actions, payload, helpers) => {
        const { formData, uniqueId } = payload;
        const apiData = helpers.getStoreState().actionSlugs.apiData;

        const editVideoROMEUrl = apiData.ACTIONS['videos']['editRome'].url.replace('uniqueId', uniqueId);
        return axios.post(editVideoROMEUrl, formData)
            .then((result) => {
                actions.updateAllVideos(result);
                actions.setVideoById(result);
                return result;
            })
            .catch(error => {
                return Promise.reject(error);
            })
    }),

    addVideoPro: thunk((actions, payload, helpers) => {
        const { formData, uniqueId } = payload;
        const apiData = helpers.getStoreState().actionSlugs.apiData;

        const addVideoProsUrl = apiData.ACTIONS['videos']['addPro'].url.replace('uniqueId', uniqueId);
        return axios.post(addVideoProsUrl, formData)
            .then((result) => {
                actions.updateAllVideos(result);
                actions.setVideoById(result);
                return result;
            })
            .catch(error => {
                return Promise.reject(error);
            })
    }),

    editVideoPro: thunk((actions, payload, helpers) => {
        const { formData, uniqueId } = payload;
        const apiData = helpers.getStoreState().actionSlugs.apiData;
        const { videoById } = helpers.getState();

        const editVideoProsUrl = apiData.ACTIONS['professionals']['update'].url.replace('uniqueId', uniqueId);
        return axios.post(editVideoProsUrl, formData)
            .then((result) => {
                const updatedPros = videoById.pros.map(pro => 
                    pro.uniqueId === result.uniqueId ? result : pro
                );
                // Remove rome if not used anymore
                const updatedRomesFromPro = videoById.romesFromPro?.filter(rome =>
                    updatedPros.some(pro => pro.mainJob?.rome && pro.mainJob?.rome === rome)
                );
                // Add rome if not already exist
                if (result.mainJob?.rome &&
                    !updatedRomesFromPro.some(romeFromPro => romeFromPro === result.mainJob?.rome)) {
                    updatedRomesFromPro.push(result.mainJob.rome);
                }
                const updatedVideoById = {
                    ...videoById,
                    romesFromPro: updatedRomesFromPro,
                    pros: updatedPros
                };
                actions.setVideoById(updatedVideoById);
                return result;
            })
            .catch(error => {
                return Promise.reject(error);
            })
    }),

    editVideoAssignPro: thunk((actions, payload, helpers) => {
        const { formData, uniqueId } = payload;
        const apiData = helpers.getStoreState().actionSlugs.apiData;

        const editVideoAssignProUrl = apiData.ACTIONS['videos']['assignPro'].url.replace('uniqueId', uniqueId);
        return axios.post(editVideoAssignProUrl, formData)
            .then((result) => {
                actions.updateAllVideos(result);
                actions.setVideoById(result);
                return result;
            })
            .catch(error => {
                return Promise.reject(error);
            })
    }),

    editVideoCommentM360: thunk((actions, payload, helpers) => {
        const { formData, uniqueId } = payload;
        const apiData = helpers.getStoreState().actionSlugs.apiData;

        const editVideoCommentM360Url = apiData.ACTIONS['videos']['editCommentM360'].url.replace('uniqueId', uniqueId);
        return axios.post(editVideoCommentM360Url, formData)
            .then((result) => {
                actions.updateAllVideos(result);
                actions.setVideoById(result);
                return result;
            })
            .catch(error => {
                return Promise.reject(error);
            })
    }),

    // deleting one video
    deleteVideo: thunk((actions, payload, helpers) => {
        const { uniqueId } = payload;
        const apiData = helpers.getStoreState().actionSlugs.apiData;
        
        const formData = new FormData();
        formData.set(`${apiData.ACTIONS['videos']['delete'].formName}[_token]`, apiData.ACTIONS['videos']['delete'].csrfToken)
        const deleteVideoUrl = apiData.ACTIONS['videos']['delete'].url.replace('uniqueId', uniqueId);


        return axios.post(deleteVideoUrl, formData)
        .then(result => {
            actions.setVideoById(videosInitialState.videoById);
            actions.removeFromAllVideos(uniqueId);
            const { setIsUpdateNecessary:setPlaylistsIsUpdateNecessary } = helpers.getStoreActions().playlists;
            setPlaylistsIsUpdateNecessary(true);
        })
        .catch(error => {
            return Promise.reject(error)
        });
    }),

    // delete pro from video
    deleteProFromVideo: thunk((actions, payload, helpers) => {
        const { videoUniqueId, proUniqueId } = payload;
        const apiData = helpers.getStoreState().actionSlugs.apiData;
        const formData = new FormData();
        formData.set(`${apiData.ACTIONS['videos']['deletePro'].formName}[_token]`, apiData.ACTIONS['videos']['deletePro'].csrfToken)
        const deleteProFromVideoUrl = apiData.ACTIONS['videos']['deletePro'].url
            .replace('videoUniqueId', videoUniqueId)
            .replace('proUniqueId', proUniqueId);


        return axios.post(deleteProFromVideoUrl, formData)
            .then(result => {
                actions.updateAllVideos(result);
                actions.setVideoById(result);
                return Promise.resolve(result);
            })
            .catch(error => {
                return Promise.reject(error)
            });
    }),


    updateVideoFiles: thunk((actions, payload, helpers) => {
        const { formData, uniqueId } = payload;
        const apiData = helpers.getStoreState().actionSlugs.apiData;

        const editVideoFilesUrl = apiData.ACTIONS['videos']['editFiles'].url.replace('uniqueId', uniqueId);
        return axios.post(editVideoFilesUrl, formData)
            .then((result) => {
                actions.updateAllVideos(result);
                actions.setVideoById(result);
                return result;
            })
            .catch(error => {
                return Promise.reject(error);
            })
    }),
}

export default videos;