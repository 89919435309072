import React from "react";
import { Row, Alert } from "react-bootstrap";
import Loader from "../../components/widgets/Loader.jsx";
import PropTypes from 'prop-types';

const RomesView = ({ romes, isFetching, filteredRomes, view }) => {
    return <Row>
        {isFetching
            ? <Loader />
            : romes?.length > 0
                ? filteredRomes?.length > 0
                    ? view
                    : <Alert className="mt-3" variant="warning">Aucun code ROME ne correspond à votre recherche</Alert>
                : <Alert className="mt-3" variant="warning">Vous n'avez accès à aucun code ROME</Alert>
        }
    </Row>;
}

RomesView.propTypes = {
    romes: PropTypes.array.isRequired,
    isFetching: PropTypes.bool.isRequired,
    filteredRomes: PropTypes.array.isRequired,
    view: PropTypes.any.isRequired
};

export default RomesView;