import React, { useEffect, useState } from "react";
import DynamicTableInfinite from "../widgets/dynamicTableInfinite/DynamicTableInfinite";
import useInfiniteLoadingLogic from "../../hooks/useInfiniteLogic";
import { useStoreState } from 'easy-peasy';
import { useLocation, useNavigate } from 'react-router-dom';
import HelperMetiers360 from "../../services/HelpersMetiers360";
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';

/**
 * 
 * @param {Array} workingConditions array of all workingConditions to be rendered
 
 * @example
 *  <WorkingConditionsTableComponent workingConditions={workingConditions} />
 */
const WorkingConditionsTableComponent = ({ workingConditions = [] }) => {
    const [workingConditionsToDisplay, setWorkingConditionsToDisplay] = useState([]);
    const [sortedWorkingConditions, setSortedWorkingConditions] = useState([]);
    const [shouldSort, setShouldSort] = useState(false);

    const workingConditionsSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('workingConditions'));
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setShouldSort(true);
        setSortedWorkingConditions([...workingConditions]);
    }, [workingConditions]);

    const { hasMore, fetchNext: fetchData } = useInfiniteLoadingLogic(sortedWorkingConditions, workingConditionsToDisplay, setWorkingConditionsToDisplay, 100);

    const sortType = [
        {
            value: 'label', label: "Contexte de travail",
            test: HelperMetiers360.isArrayContainsValue(workingConditions, 'label'),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.label, b.label),
            display: (workingCondition) => workingCondition.label
        },
        {
            value: 'category', label: 'Catégorie',
            test: HelperMetiers360.isArrayContainsValue(workingConditions, 'category'),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.category, b.category),
            display: (workingCondition) => HelperMetiers360.categoryWorkingConditionsLabel(workingCondition.category)
        },
        {
            value: 'usagePros', label: 'Professionnel⋅les',
            test: HelperMetiers360.isArrayContainsValue(workingConditions, 'usagePros'),
            method: (a, b) => b.usagePros - a.usagePros,
            display: (workingCondition) => workingCondition.usagePros
        },
        {
            value: 'usageVideosByPro', label: 'Expériences immersives',
            test: HelperMetiers360.isArrayContainsValue(workingConditions, 'usageVideosByPro'),
            method: (a, b) => b.usageVideosByPro - a.usageVideosByPro,
            display: (workingCondition) => workingCondition.usageVideosByPro
        }
    ];

    const handleClick = (code) => {
        if (workingConditionsSlugs.readOne) {
            navigate(workingConditionsSlugs.readOne.replace(':uniqueId', code), { state: { olderSearchParams: location.search } });
        }
    }

    return workingConditionsToDisplay?.length > 0
        ? <DynamicTableInfinite
            contentTable={workingConditionsToDisplay.map(workingCondition => {
                return {
                    ...workingCondition,
                    background: workingCondition?.usagePros === 0 && workingCondition?.usageVideosByPro === 0
                        ? 'repeating-linear-gradient(-45deg, #e9ecef, #e9ecef 8px, white 8px, white 20px)'
                        : null
                }
            })}
            contentSort={sortType}
            valueInitSort="label"
            index='code'
            fetchData={fetchData}
            hasMore={hasMore}
            setSortedContent={setSortedWorkingConditions}
            sortedContent={sortedWorkingConditions}
            sortState={[shouldSort, setShouldSort]}
            handleClick={handleClick}
            filename="Contextes_de_travail_M360"
        />
        : <Alert className="mt-3" variant="warning"> Aucun contexte de travail ne correspond à votre recherche</Alert>;
}

WorkingConditionsTableComponent.propTypes = {
    workingConditions: PropTypes.array.isRequired,
};

export default WorkingConditionsTableComponent;